
/**
 * PIN management for unlocking purchase order stock notification items.
 * @module comp/pin 
 * @author Dominik Pantůček <dominik.pantucek@trustica.cz>
 */


import React from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import { useTranslation } from 'react-i18next';

//seen all - OK

/**
 * Generates secret PIN for given notification number of given item.
 * @param {number} IDPolozky - unique through all NOs, therefore it
 * serves as single number in place of [KodDokladu/IDDokladu,
 * IDPolozky] pair
 * @param {number} notification_number - the current ordinal number of the
 * notification being created (starts at one)
 * @return {string} - 4-digit PIN as string 
 */
export function NO_PIN_generate(IDPolozky, notification_number) {
    if (isNaN(IDPolozky) || isNaN(notification_number)) {
        throw new Error('NO_PIN_generate: both arguments must be numbers!');
    }
    return ('' + (IDPolozky * notification_number * 0xDEADBEEF +
        5417 * notification_number +
        31337 * IDPolozky))
        .split('')
        .reverse()
        .slice(0, 4)
        .join('');
}

/**
 * Validates secret PIN for given notification number of given item.
 * @param {number} IDPolozky - unique through all NOs, therefore it
 * serves as single number in place of [KodDokladu/IDDokladu,
 * IDPolozky] pair
 * @param {number} notification_number - the current ordinal number of the
 * notification being created (starts at one)
 * @param {string} pin - 4-digit PIN as string 
 * @return {boolean} - true if the PIN matches
 */
export function NO_PIN_validate(IDPolozky, notification_number, pin) {
    if (isNaN(IDPolozky) || isNaN(notification_number)) {
        throw new Error('NO_PIN_validate: first two arguments must be numbers!');
    }
    if (typeof pin !== 'string') {
        throw new Error('NO_PIN_validate: third argument must be a string');
    }
    return NO_PIN_generate(IDPolozky, notification_number) === pin;
}

/**
 * Special component for PIN input used at stockin process - step 2 - PIN to required to se batch as unknown
 * 
 * @param {number} nop_IDPolozky - id of item that PIN belongs to
 * @param {boolean} disabledForms - true if PIN should be disabled
 * @param {function} setNotifyItemBatches - updates batch information after successful PIN entry
 * @param {string} unknown_batch_string - text of unlocked
 * @param {function} setUnlocking - function to set unclocked value
 * @param {boolean} unlocked - true if correct PIN was entered
 * @param {boolean} exceptionCase - true if PIN is not required for unlocking
 * @returns {component}
 */
export function PIN({
    nop_IDPolozky, disabledForms,
    unlocked, exceptionCase, unlockedItems, setUnlockedItems, updateUnknownBatch, updateKnownBatch
}) {
    const { t } = useTranslation();

    function setUnlocking(nop_IDPolozky, newState, unlockedItems, setUnlockedItems) {
        var item = {};
        item[nop_IDPolozky] = newState;
        setUnlockedItems({ ...unlockedItems, ...item });
    }

    // 0 - not set
    // 1 - entering pin
    // 2 - unlocked
    function changePIN(val) {
        const PIN_ok = NO_PIN_validate(nop_IDPolozky, 1, val);
        if (PIN_ok === true) {
            setUnlocking(nop_IDPolozky, 2, unlockedItems, setUnlockedItems);
            //setNotifyItemBatches(nop_IDPolozky, unknown_batch_string);
            updateUnknownBatch(nop_IDPolozky);
        }
    }

    function setKnown() {
        setUnlocking(nop_IDPolozky, 0, unlockedItems, setUnlockedItems);
        //setNotifyItemBatches(nop_IDPolozky, "");
        updateKnownBatch(nop_IDPolozky);
    }

    const status = unlocked === 3 ? exceptionCase ? 2 : 0 : unlocked;

    function handleUnknownBatch(exceptionCase) {
        if (exceptionCase) {
            setUnlocking(nop_IDPolozky, 3, unlockedItems, setUnlockedItems); //nastavuju stav 3 v případě exception case
            //setNotifyItemBatches(nop_IDPolozky, unknown_batch_string);
            updateUnknownBatch(nop_IDPolozky);
        } else {
            setUnlocking(nop_IDPolozky, 1, unlockedItems, setUnlockedItems);
        }
    }

    switch (status) {
        case 1:
            return (
                <div className="d-inline-block mb-0 ms-1">
                    <InputGroup style={{ maxWidth: "100px" }} className="">
                        <Form.Control type="pin" placeholder="PIN" onChange={(ev) => changePIN(ev.target.value)} />
                        <InputGroup.Text style={{ cursor: "pointer" }} onClick={() => setUnlocking(nop_IDPolozky, 0, unlockedItems, setUnlockedItems)}>X</InputGroup.Text>
                    </InputGroup>
                </div>
            );
        case 2:
            return (<Button width="20px" variant="dark" size="sm" className="w-100 my-2 mb-1" onClick={() => setKnown()}>{t('ord-known_batch')}</Button>);
        case 0:
            return (<Button variant="dark" size="sm" className="w-100 my-2 mb-1" disabled={disabledForms} onClick={() => handleUnknownBatch(exceptionCase)}>{t('ord-unknown_batch')}</Button>);
        default:
            return (<p>{t('error')}&nbsp; {status}</p>);
    }
}
