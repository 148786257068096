/**
 * List of tracked delivery notes - new version with progress-path
 * @module delivery-notes/delivery-note-sales-tracking-new
 * @author Lucie Zdeňková <lucie.zdenek@trustica.cz>
 */
import React, { useState, useEffect } from 'react';
import { his_fetch, his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import Table from 'react-bootstrap/Table';
import { date_time_format, date_formatCZ, date_isCzech, date_parseCzech } from '../lib/date-utils';
import { LinkContainer } from 'react-router-bootstrap';
import { filter_rule } from '../lib/utils';
import Form from 'react-bootstrap/Form';
import { warehouses_names_ids_separe, warehouses_for_select } from '../lists/warehouses-defs';
import { Row, Col, Card, Image, Button } from 'react-bootstrap';
import { MultipleSelect } from '../comp/multiple-select';
import { SortIcons } from '../comp/sort';
import { ErrorWrap } from '../comp/errorwrap';
import { LoadingDataInfo } from '../comp/dynamic-load';
import { delivery_note_progress_specification, final_states } from './progress-delivery-note-def';
import { Pager } from '../comp/pager';
import { ItemsAndFiltered } from '../comp/dynamic-load';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import { ProgressPathDeliveryNote } from './delivery-notes-progress-path';
import { Loading } from '../comp/loading';
import { useTranslation } from 'react-i18next';


//toto je get_id_boolean - pak zkusit nahradit jinde
function get_id_with_boolean_in_dict(dictionary, key_name, value) {
    return dictionary.map((item) => item[key_name]) //pole všech hodnot z dle key [1, 1, 2, 3, 4, 4]
        .filter((v, i, a) => a.indexOf(v) === i) // pole unikátních hodnot dle key [1, 2, 3, 4]
        .reduce((acc, key_name) => ({ ...acc, [key_name]: value }), {}); // tvorba slovníku {1: false, 2: false, 3: false}
}

//goes through historylog and gives the number of warehouse, if there is any
function identify_warehouse(history) {
    const unique_wh = history.map((wh) => wh.fwr_warehouse_id).filter((wh) => wh !== null).filter((v, i, a) => a.indexOf(v) === i); //odeberu null a udělám unique
    //console.log(unique_wh);
    if (unique_wh.length === 1) {
        return unique_wh[0];
    } else {
        return null;
    }
}

export function DeliveryNotesSalesTrackingNew({ userlogin, defaultUser = null }) {
    const { t } = useTranslation();
    //zero number in specification is for states, which doesnt have backend bumber assigned yet
    const statesWithoutZeroAndFinalNumbers = delivery_note_progress_specification.filter((item) => item.number !== 0).filter((item) => !final_states.includes(item.number));
    const dictionary_of_statuses = Object.assign({}, ...statesWithoutZeroAndFinalNumbers.map((x) => ({ [x.number]: x.name })));
    const dictionaryOfStatusValues = Object.keys(dictionary_of_statuses).reduce((acc, v) => ({ ...acc, [v]: true }), {});
    const dictionaryOfStatusValuesFalse = Object.keys(dictionary_of_statuses).reduce((acc, v) => ({ ...acc, [v]: false }), {});

    const warehousesWithNull = { ...warehouses_for_select, null: t('unknown2') };
    const dictionaryOfWHValues = Object.keys(warehousesWithNull).reduce((acc, v) => ({ ...acc, [v]: true }), {});
    const dictionaryOfWHValuesFalse = Object.keys(warehousesWithNull).reduce((acc, v) => ({ ...acc, [v]: false }), {});

    const [newData, setNewData] = useState(null);
    const [oldData, setOldData] = useState(null);
    const [reloadingPeriod, setReloadingPeriod] = useState(0);
    const [loadedStatus, setLoadedStatus] = useState(0);
    const [offset, setOffset] = useState(0);
    const [filterCode, setFilterCode] = useState("");
    const [filterCreator, setFilterCreator] = useState(defaultUser !== null ? defaultUser : "");
    const [checkedStatePD, setCheckedStatePD] = useState(dictionaryOfStatusValues);
    const [checkedWH, setCheckedWH] = useState(dictionaryOfWHValues);
    const [sortValue, setSortValue] = useState("");
    const [direction, setDirection] = useState("down");
    const [type, setType] = useState("");
    const [detailShown, setDetailShown] = useState({});

    const reloadIt = () => {
        setNewData(null);
        setLoadedStatus(0);
    }

    useEffect(() => {
        if (newData === null) {
            const running_fetch = his_fetch(
                userlogin,
                [
                    {
                        uri: "/api/delivery-notes/new-tracking",
                        json: true,
                        status: setLoadedStatus,
                        ok: function (resource, result) {
                            // console.log(result);
                            setNewData(result.logs);
                            setOldData(result.logs);
                        },
                        error: function (resource, reason) {
                            console.log('err: ' + reason);
                            setNewData("error");
                        }
                    }
                ]
            )
            return () => {
                running_fetch();
            }
        } else {
            if (reloadingPeriod > 0) {
                const timeout_id = setTimeout(() => {
                    setNewData(null);
                }, reloadingPeriod);
                return () => {
                    clearTimeout(timeout_id);
                };
            }
        }
    }, [userlogin, newData, reloadingPeriod]);

    const cleanFilters = () => {
        setFilterCode("");
        setFilterCreator("");
        setCheckedStatePD(dictionaryOfStatusValues);
        setCheckedWH(dictionaryOfWHValues);
        setSortValue("");
        setDirection("");
        setType("");
    }

    const handleChangeCode = function (event) {
        setFilterCode(event.target.value);
        setOffset(0);
    }

    const handleChangeCreator = function (event) {
        setFilterCreator(event.target.value);
        setOffset(0);
    }

    const historyLog = (newData !== null && newData !== "error") ? newData : oldData !== null ? oldData : [];

    //functions for handling diy accordion
    const allHidden = Object.keys(detailShown).length === Object.values(detailShown).filter((v) => v === false).length;

    function expandAll() {
        const value = allHidden ? true : false;
        setDetailShown(get_id_with_boolean_in_dict(historyLog, "pdd_KodDokladu", value));
    }

    function toggleDetailShown(id) {
        setDetailShown({ ...detailShown, [id]: !detailShown[id] }); //dej tam opačnou hodnotu, než jakou má aktuální na sn_id
    }

    function format_delivery_date(date) {
        if (date_isCzech(date)) {
            return date_parseCzech(date);
        } else {
            return new Date(date);
        }
    }

    //historylog preprocessing, unique codes of PD KodDokladu and asign logs
    const unique_PD_codes = historyLog.map((log) => log.pdd_KodDokladu).filter((v, i, a) => a.indexOf(v) === i);
    const history_logs_by_KodDokladu = unique_PD_codes.reduce((acc, kodDokladu) => ({
        ...acc,
        [kodDokladu]: historyLog.filter((rec) => rec.pdd_KodDokladu === kodDokladu)
    }), {});

    // preprocessing 2: builds 2 level dictionary with PD information and its historylogs
    const tracking_transformed = unique_PD_codes.map(function (PD) {
        const one_history_sorted = history_logs_by_KodDokladu[PD].sort((a, b) => new Date(a.recorded) - new Date(b.recorded));
        const one_history_reversed = [...one_history_sorted].reverse();
        //console.log(one_history_sorted);
        // console.log(format_delivery_date(one_history_reversed[0].DatumDodani));
        return ({
            KodDokladu: PD,
            history: one_history_sorted,
            warehouse_id: identify_warehouse(one_history_sorted),
            creator: one_history_sorted[0].mp_Jmeno,
            username: one_history_sorted[0].u_username,
            last_modified: one_history_reversed[0].recorded, //musí být z posledního
            status: one_history_reversed[0].status, //musí být z posledního
            delivery_date: format_delivery_date(one_history_reversed[0].DatumDodani)
        });
    })

    //removing PDs in final states
    const non_final_PDs = tracking_transformed.filter((tr) => !final_states.includes(tr.status));


    //standard filtering based on user input
    const tracking_filtered = non_final_PDs.filter(
        function (tra) {
            return (
                filter_rule(filterCode, tra.KodDokladu, true) &&
                (filter_rule(filterCreator, tra.creator, true) || filter_rule(filterCreator, tra.username, true)) &&
                (checkedStatePD[tra.status]) &&
                (checkedWH[tra.warehouse_id])
            );
        }
    )

    const tracking_sorted = tracking_filtered.sort(function (a, b) {
        if (type === "num") {
            if (direction === "up") {
                return new Date(a[sortValue]) - new Date(b[sortValue]);
            }
            if (direction === "down")
                return new Date(b[sortValue]) - new Date(a[sortValue]);
        }
        return 0;
    });
    const tracking_shown = tracking_sorted.slice(offset, offset + 20);
    const username = userlogin.userinfo.username;
    return (
        <ErrorWrap>
            <Row>
                <Col>
                    <Button disabled={loadedStatus === 0 || loadedStatus === 1 || loadedStatus === 4} size="sm" className="me-2 d-inline" onClick={reloadIt}><Image src="/img/reload.svg" height="19" /></Button>
                </Col>
                <Col className='text-center'>
                    <ItemsAndFiltered filtered_data={tracking_filtered} data={non_final_PDs} cleanFilters={cleanFilters} />
                    {!his_fetch_success(loadedStatus) ?
                        <span> <Loading size="small" message="Načítání dat" margin="0" /></span> : ""}
                </Col>
                <Col className='text-end'>
                    <h6 className='d-inline'>

                        {t('background_loading')}:&nbsp;</h6>
                    <Form.Group controlId="display" className='mb-0 ms-3 d-inline'>
                        <Form.Check inline name="reloadingTime" type="radio" value={0} id='1' label={t('never')} onClick={() => setReloadingPeriod(0)} defaultChecked={reloadingPeriod === 0} />
                        <Form.Check inline name="reloadingTime" type="radio" value={15 * 1000} id='2' label="15s" onClick={() => setReloadingPeriod(15 * 1000)} defaultChecked={reloadingPeriod === 15} />
                        <Form.Check inline name="reloadingTime" type="radio" value={1 * 60 * 1000} id='3' label="1min" onClick={() => setReloadingPeriod(60 * 1000)} defaultChecked={reloadingPeriod === 60} />
                        <Form.Check inline name="reloadingTime" type="radio" value={5 * 60 * 1000} id='4' label="5min" onClick={() => setReloadingPeriod(5 * 60 * 1000)} defaultChecked={reloadingPeriod === 5 * 60} />
                    </Form.Group>
                    <br />
                    <br />
                    <Pager offset={offset} pagesize={20} total={tracking_filtered.length} callback={setOffset} />
                    <Button size="sm" className='float-end me-3 mb-2' onClick={() => expandAll()}>
                        {allHidden ? <>{t('expand')} <MdExpandMore /></> : <>{t('hide')} <MdExpandLess /></>}
                    </Button>
                </Col>
            </Row>

            <TrackingTable tracking={tracking_shown} filterCode={filterCode} handleChangeCode={handleChangeCode} checkedStatePD={checkedStatePD} setCheckedStatePD={setCheckedStatePD}
                dictionaryOfStatusValues={dictionaryOfStatusValues} dictionaryOfStatusValuesFalse={dictionaryOfStatusValuesFalse} setOffset={setOffset}
                checkedWH={checkedWH} setCheckedWH={setCheckedWH} dictionaryOfWHValues={dictionaryOfWHValues} dictionaryOfWHValuesFalse={dictionaryOfWHValuesFalse}
                sortValue={sortValue} setSortValue={setSortValue} direction={direction} setDirection={setDirection} setType={setType}
                filterCreator={filterCreator} handleChangeCreator={handleChangeCreator} history_logs_by_KodDokladu={history_logs_by_KodDokladu}
                toggleDetailShown={toggleDetailShown} detailShown={detailShown} dictionary_of_statuses={dictionary_of_statuses}
                warehousesWithNull={warehousesWithNull} userlogin={userlogin} setReloadingPeriod={setReloadingPeriod} username={username} setFilterCreator={setFilterCreator}
            />

            <TrackingTableApp tracking={tracking_shown} filterCode={filterCode} handleChangeCode={handleChangeCode} checkedStatePD={checkedStatePD} setCheckedStatePD={setCheckedStatePD}
                dictionaryOfStatusValues={dictionaryOfStatusValues} dictionaryOfStatusValuesFalse={dictionaryOfStatusValuesFalse} setOffset={setOffset}
                checkedWH={checkedWH} setCheckedWH={setCheckedWH} dictionaryOfWHValues={dictionaryOfWHValues} dictionaryOfWHValuesFalse={dictionaryOfWHValuesFalse}
                sortValue={sortValue} setSortValue={setSortValue} direction={direction} setDirection={setDirection} setType={setType}
                filterCreator={filterCreator} handleChangeCreator={handleChangeCreator} dictionary_of_statuses={dictionary_of_statuses}
                toggleDetailShown={toggleDetailShown} detailShown={detailShown} warehousesWithNull={warehousesWithNull}
                userlogin={userlogin} setReloadingPeriod={setReloadingPeriod} username={username} setFilterCreator={setFilterCreator}
            />

            <LoadingDataInfo loadedStatus={loadedStatus} data={non_final_PDs} withoutLoading />

            {!his_fetch_success(loadedStatus) ?
                <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} /> : <></>}

        </ErrorWrap>
    );
}

function TrackingTable({ tracking, filterCode, filterCreator, handleChangeCode, handleChangeCreator, checkedStatePD, setCheckedStatePD, setOffset,
    dictionaryOfStatusValues, dictionaryOfStatusValuesFalse, checkedWH, setCheckedWH, dictionaryOfWHValues, dictionaryOfWHValuesFalse,
    sortValue, setSortValue, setDirection, direction, setType, history_logs_by_KodDokladu, toggleDetailShown, detailShown,
    dictionary_of_statuses, warehousesWithNull, userlogin, setReloadingPeriod, username, setFilterCreator }) {
    const { t } = useTranslation();
    //console.log(tracking);
    return (
        <Table size="sm" striped className="d-none d-md-table" >
            <thead className="beGray">
                <tr>
                    <th width="15%">
                        <Form.Group className="m-1" controlId="filterCode">
                            <Form.Label>{t('doc_code')}</Form.Label>
                            <Form.Control type="text"
                                placeholder="&#128269; "
                                value={filterCode}
                                onChange={handleChangeCode} />
                        </Form.Group>
                    </th>
                    <th width="15%">
                        <Row>
                            <Col xs="auto"><p className="mb-1">{t('ord-created_by')}</p></Col>
                            <Col className='text-end'>
                                <div className='float-end'>
                                    <Form.Check type="checkbox" id="filter_my_PD"
                                        onChange={filterCreator !== username ? () => setFilterCreator(username) : () => setFilterCreator("")}
                                        checked={filterCreator === username}
                                        label={t('my_delivery_notes')} />
                                </div>
                            </Col>
                        </Row>
                        <Form.Group className="m-1" controlId="filterCreator">
                            <Form.Control type="text"
                                placeholder="&#128269; "
                                value={filterCreator}
                                onChange={handleChangeCreator} />
                        </Form.Group>
                    </th>
                    <th width="15%" className="text-end pb-2">{t('ord-last_change_time')} &nbsp; <br />
                        <SortIcons name={"last_modified"} sortValue={sortValue} setSortValue={setSortValue}
                            setDirection={setDirection} direction={direction} setType={setType}
                            numeric />
                    </th>
                    <th width="15%" className="text-center">
                        <p className="mb-1">{t('warehouse')}</p>
                        <MultipleSelect checked={checkedWH} setChecked={setCheckedWH}
                            dictionaryTrue={dictionaryOfWHValues} dictionaryFalse={dictionaryOfWHValuesFalse}
                            itemsNames={warehousesWithNull} setOffset={setOffset} id="filterWH" />
                    </th>
                    <th width="10%" className='text-end'> {t('deno-delivery_date')}
                        <SortIcons name={"delivery_date"} sortValue={sortValue} setSortValue={setSortValue}
                            setDirection={setDirection} direction={direction} setType={setType}
                            numeric />
                    </th>
                    <th width="30%" className="text-center">
                        <p className="mb-2">{t('state')}</p>
                        <MultipleSelect checked={checkedStatePD} setChecked={setCheckedStatePD}
                            dictionaryTrue={dictionaryOfStatusValues} dictionaryFalse={dictionaryOfStatusValuesFalse}
                            itemsNames={dictionary_of_statuses} setOffset={setOffset} id="filterStatus" />
                    </th>
                    <th></th>
                </tr>
            </thead>

            <tbody>
                {tracking.map((row) => <TrackingRow one_history={history_logs_by_KodDokladu[row.KodDokladu]}
                    row={row} key={row.KodDokladu} toggleDetailShown={toggleDetailShown}
                    detailShown={detailShown} userlogin={userlogin} setReloadingPeriod={setReloadingPeriod} />)}
            </tbody>

        </Table>
    );
}


function TrackingRow({ row, one_history, toggleDetailShown, detailShown, userlogin, setReloadingPeriod }) {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <tr>
                <td style={{ cursor: "pointer" }} onClick={() => toggleDetailShown(row.KodDokladu)} className='align-middle'>
                    <LinkContainer style={{ cursor: "pointer" }} to={{ pathname: "/delivery-notes/sales/" + row.KodDokladu }}>
                        <h6 className='mb-0 d-inline blueish'>{row.KodDokladu}</h6>
                    </LinkContainer>
                </td>
                <td style={{ cursor: "pointer" }} onClick={() => toggleDetailShown(row.KodDokladu)} className='align-middle' >{row.creator} ({row.username})</td>
                <td style={{ cursor: "pointer" }} onClick={() => toggleDetailShown(row.KodDokladu)} className="text-end align-middle">{date_time_format(row.last_modified)}</td>
                <td style={{ cursor: "pointer" }} onClick={() => toggleDetailShown(row.KodDokladu)} className="text-center align-middle">{row.warehouse_id ? warehouses_names_ids_separe[row.warehouse_id] : t('unknown')}</td>
                <td style={{ cursor: "pointer" }} onClick={() => toggleDetailShown(row.KodDokladu)} className="text-end align-middle">{date_formatCZ(row.delivery_date)}</td>
                <td style={{ cursor: "pointer" }} onClick={() => toggleDetailShown(row.KodDokladu)} className="text-end">
                    <ProgressPathDeliveryNote one_history={one_history} compact userlogin={userlogin} setReloadingPeriod={setReloadingPeriod} />
                </td>
                <td style={{ cursor: "pointer" }} onClick={() => toggleDetailShown(row.KodDokladu)}
                    className='text-center align-middle'>
                    <Button size="sm" variant="light" >{detailShown[row.KodDokladu] ? <MdExpandLess /> : <MdExpandMore />}</Button>
                </td>
            </tr>
            <tr className=" p-0 m-0 ">
                <td colSpan={6} className="p-0 m-0">
                    <div className={'mb-0 px-4 py-3 bg-light' + (detailShown[row.KodDokladu] ? "" : " d-none")}>
                        <ProgressPathDeliveryNote one_history={one_history} userlogin={userlogin} setReloadingPeriod={setReloadingPeriod} />
                    </div>
                </td>
            </tr>
        </React.Fragment>
    );
}


function TrackingTableApp({ tracking, filterCode, handleChangeCode, checkedWH, setCheckedWH, dictionaryOfWHValues, dictionaryOfWHValuesFalse,
    setOffset, checkedStatePD, dictionaryOfStatusValues, dictionaryOfStatusValuesFalse, setCheckedStatePD, sortValue, setSortValue, direction,
    setDirection, setType, filterCreator, handleChangeCreator, dictionary_of_statuses, detailShown, toggleDetailShown, warehousesWithNull,
    userlogin, setReloadingPeriod, username, setFilterCreator }) {
    const { t } = useTranslation();
    return (
        <div className="d-md-none">
            <Card className='m-0 p-2'>
                <Card.Body className='m-0 p-0'>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3 d-md-none" controlId="filterCode">
                                <Form.Label>{t('doc_code')}</Form.Label>
                                <Form.Control type="text"
                                    placeholder="&#128269; "
                                    value={filterCode}
                                    onChange={handleChangeCode} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3 d-md-none" controlId="filterCreator">
                                <Form.Label>{t('ord-created_by')}</Form.Label>
                                <Form.Control type="text"
                                    placeholder="&#128269; "
                                    value={filterCreator}
                                    onChange={handleChangeCreator} />
                            </Form.Group>
                            <Form.Check type="checkbox" id="filter_my_PDs"
                                onChange={filterCreator !== username ? () => setFilterCreator(username) : () => setFilterCreator("")}
                                checked={filterCreator === username}
                                label={t('my_delivery_notes')} />
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col>
                            <span className='bolder'>{t('ord-last_change_time')}</span>
                            <SortIcons name={"last_modified"} sortValue={sortValue} setSortValue={setSortValue} direction={direction}
                                setDirection={setDirection} setType={setType}
                                numeric />
                        </Col>
                        <Col>
                            <span className='bolder'>{t('deno-delivery_date')} &nbsp;</span>
                            <SortIcons name={"delivery_date"} sortValue={sortValue} setSortValue={setSortValue}
                                setDirection={setDirection} direction={direction} setType={setType}
                                numeric />
                        </Col>
                    </Row>

                    <Row className='mt-3'>
                        <Col >
                            <p className="mb-0 bolder">{t('warehouse')}</p>
                            <MultipleSelect checked={checkedWH} setChecked={setCheckedWH}
                                dictionaryTrue={dictionaryOfWHValues} dictionaryFalse={dictionaryOfWHValuesFalse}
                                itemsNames={warehousesWithNull} setOffset={setOffset} id="filterWH" />
                        </Col>
                        <Col className='text-end'>
                            <p className="mb-0 bolder">{t('state')}</p>
                            <MultipleSelect checked={checkedStatePD} setChecked={setCheckedStatePD}
                                dictionaryTrue={dictionaryOfStatusValues} dictionaryFalse={dictionaryOfStatusValuesFalse}
                                itemsNames={dictionary_of_statuses} setOffset={setOffset} id="filterStatus" />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Table striped className="mt-4">
                <tbody>
                    {tracking.map((row, idx) => <TrackingRowApp key={idx} row={row}
                        one_history={row.history} toggleDetailShown={toggleDetailShown} detailShown={detailShown}
                        userlogin={userlogin} setReloadingPeriod={setReloadingPeriod} />)}
                </tbody>
            </Table>
        </div>
    );
}


function TrackingRowApp({ row, one_history, detailShown, toggleDetailShown, userlogin, setReloadingPeriod }) {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <tr onClick={() => toggleDetailShown(row.KodDokladu)}>
                <td>
                    <div className='text-center'>
                        <ProgressPathDeliveryNote one_history={one_history} compact userlogin={userlogin} setReloadingPeriod={setReloadingPeriod} />
                    </div>
                    <Row>
                        <Col> <LinkContainer style={{ cursor: "pointer" }} to={{ pathname: "/delivery-notes/sales/" + row.KodDokladu }}>
                            <h6 className='mb-0 d-inline blueish'>{row.KodDokladu}</h6>
                        </LinkContainer></Col>
                    </Row>
                    <Row>
                        <Col xs="auto">
                            {row.creator ? row.creator : t('unknown')}
                        </Col>
                        <Col className="text-end">
                            {t('warehouse')}: {row.warehouse_id ? warehouses_names_ids_separe[row.warehouse_id] : t('unknown')}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {t('deno-delivery_date')}: {date_formatCZ(row.delivery_date)}
                        </Col>
                        <Col className='text-end'>
                            {t('last_modified')}: {date_time_format(row.last_modified)}
                        </Col>
                    </Row>

                </td>
            </tr>
            <tr className={' p-0 m-0 ' + (detailShown[row.KodDokladu] ? "" : " d-none")}>
                <td>
                    <div className={'mb-0 px-2 pt-3'}>
                        <ProgressPathDeliveryNote one_history={one_history} userlogin={userlogin} setReloadingPeriod={setReloadingPeriod} />
                    </div>
                </td>
            </tr>
        </React.Fragment>
    );
}