/**
 * Main menu component with items only for logged users.
 * @module mainmenu
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */

import React, { /*useState,*/ useEffect } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';
import { NavLink } from "react-router-dom";
import { his_fetch } from './comp/FetchLoader';
import { useTranslation } from 'react-i18next';

//seen all - OK

/** 
 * Navbar with mainmenu options, displays who is logged ad handles logout of user. 
 *  
 * @param {dictionary} userlogin - information about logged user
 * @return {component} - COMPONENT Application header navbar with logged username at the end.
 */
function MainMenuLogged({ userlogin }) {
	const { t } = useTranslation();

	function handleLogoutClick() {
		userlogin.handleLogout();
		his_fetch(
			userlogin,
			[
				{
					uri: "/api/auth",
					json: true,
					ok: function (resource, result) { },
					args: {
						method: "DELETE",
						credentials: 'same-origin'
					}
				}
			]
		)
	}

	//const superuser = userlogin.userinfo && userlogin.userinfo.his_superuser;
	//const reporting_users = ['tereza', 'jahodova', 'lucie', 'joe'];
	const show_reporting = true; // reporting_users.includes(userlogin.userinfo.username);

	var mainmenu = [
		["/partners", "menu-biz", ""],
		["/orders", "menu-ord", ""],
		["/products", "menu-prod", ""],
		["/delivery-notes", "menu-deno", ""],
		["/insurance/info", "menu-ins", ""],
		["/reporting", "menu-rep", "d-none"], //d-none se použije, když není show-reporting
		["/help/pojmy", "menu-help", ""],
		["/system", "menu-sys", ""]
	];

	return (
		<>
			<Navbar.Toggle aria-controls="basic-navbar-nav" />
			<Navbar.Collapse id="basic-navbar-nav">
				<Nav>
					{
						mainmenu.map(function (mi) {
							const location = window.location.pathname;
							const active = location.startsWith(mi[0]);
							return (
								<LinkContainer key={mi[0]} to={mi[0]} active={active} className={show_reporting ? "" : mi[2]}>
									<Nav.Link active={active}>
										{t(mi[1])}
									</Nav.Link>
								</LinkContainer>
							);
						})
					}
				</Nav>
			</Navbar.Collapse>
			<Navbar.Collapse className="justify-content-end">
				<Nav>
					<Navbar.Text>
						{t('sys-logged_as')}: <NavLink to="/settings">{userlogin.username}</NavLink>
						&nbsp;|&nbsp;
						<NavLink to="/" onClick={handleLogoutClick}>{t('sys-log_out')}</NavLink>
					</Navbar.Text>
				</Nav>
			</Navbar.Collapse>
		</>
	);
}

/** 
 * Header of whole application with fetch for title. 
 *  
 * @param {dictionary} userlogin - information about logged user
 * @return - COMPONENT which renders application title and MainMenuLogged component
 */
export function MainMenu({ userlogin, setPingLang }) {
	const title = userlogin.title;
	const setTitle = userlogin.setTitle;
	// const [title, setTitle] = useState("HIS");

	useEffect(function () {
		const running_fetch = his_fetch(
			userlogin,
			[
				{
					uri: "/api/ping",
					json: true,
					ok: function (resource, result) {
						setTitle(result.title);
						setPingLang(result.lang ? result.lang : "cs");
					},
					error: function (resource, reason) {
						console.log('err: ' + reason);
					}
				}
			]
		)
		return () => {
			running_fetch();
		}
	}, [userlogin, setTitle, setPingLang]);

	return (
		<Navbar bg="dark" expand="lg" variant="dark" fixed="top" collapseOnSelect className='px-3'>
			<LinkContainer to="/">
				<Navbar.Brand>
					{title}
				</Navbar.Brand>
			</LinkContainer>
			{userlogin.logged ?
				<MainMenuLogged userlogin={userlogin} />
				:
				<></>
			}
		</Navbar>
	)
}

