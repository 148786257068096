/**
 * Components for rendering web version of delivery notes list
 * @module delivery-notes/delivery-note-sales-web
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React from 'react';
import Form from 'react-bootstrap/Form';
import { BooleanDropdown } from '../comp/boolean';
import { LinkContainer } from 'react-router-bootstrap';
import { date_formatCZ } from '../lib/date-utils';
import { Boolean } from '../comp/boolean';
import Table from 'react-bootstrap/Table';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';
import { mappings } from './delivery-notes-sales';

//seen all - OK

/**
 * Single desktop line of delivery note
 * 
 * @param {any} dn - one line of delivery note
 * @param {string} type - ist "PD" for sales delivery notes and "ND" for purchase delivery notes
 * @returns {component}
 */
function DeliveryNoteSale({ dn, type }) {
	const mapping = mappings[type];
	const url = type === "PD" ? "/delivery-notes/sales/" : "/delivery-notes/purchase/";
	return (
		<LinkContainer to={{ pathname: url + dn[mapping[0]] }} key={dn[0]}>
			<tr key={dn[mapping[0]]}>
				<td>{dn[mapping[0]]}</td>
				<td>{dn[mapping[1]]}</td>
				<td className="text-center"><Boolean variant="onlyTrue" value={dn[mapping[2]]} /></td>
				<td>{dn[mapping[3]]}</td>
				<td>{dn[mapping[4]]}</td>
				<td>{dn[mapping[5]]}</td>
				<td>{dn[mapping[6]] ? date_formatCZ(dn[mapping[6]]) : "-"}</td>
				<td className="text-end">{dn[mapping[7]]} %</td>
				<td className="text-center"><Boolean variant="onlyTrue" value={dn[mapping[8]]} /></td>
				<td className="text-center"><Boolean variant="onlyTrue" value={dn[mapping[9]]} /></td>
				<td className="text-center"><Boolean variant="negative" value={dn[mapping[10]]} /></td>
				<td>{dn[mapping[11]]}</td>
			</tr>
		</LinkContainer>
	);
}

/**
 * Desktop list of delivery notes with filters
 * 
 * @param {boolean} isLoaded - says, whether show new content or loading, prevents old data to stay, when switching from sale to purchase list of delivery notes
 * @returns {component}
 */
export function DeliveryNotesSalesWeb({ dnotes_shown, code, order, partner, partnerName, note, writer,
	handleChangeCode, handleChangeOrder, setFilterExport, handleChangePartner, handleChangePartnerName, handleChangeNote,
	setFilterClosed, setFilterApproved, setFilterCancelled, handleChangeWriter, filterExport, filterClosed, filterApproved,
	filterCancelled, type, isLoaded }) {
	const { t } = useTranslation();
	return (
		<ErrorWrap>
			<div className="d-none d-lg-block">
				<Table hover striped >
					<thead className="beGray">
						<tr>
							<th className="text-center">
								<Form.Group className="m-1" controlId="filterCode">
									<Form.Label>{t('doc_code')}</Form.Label>
									<Form.Control type="text"
										placeholder="&#128269; "
										value={code}
										onChange={handleChangeCode} />
								</Form.Group>
							</th>
							<th className="text-center">
								<Form.Group className="m-1" controlId="filterOrder">
									<Form.Label>{t('deno-ord')}</Form.Label>
									<Form.Control type="text"
										placeholder="&#128269; "
										value={order}
										onChange={handleChangeOrder}
									/>
								</Form.Group>
							</th>
							<th className="text-center" style={{ paddingBottom: "13px" }}>
								<p className="mb-2">{type === "PD" ? t('deno-exported') : t('ord-imported')}</p>
								<BooleanDropdown variant="onlyTrue" onChange={setFilterExport} value={filterExport} />
							</th>
							<th className="text-center">
								<Form.Group className="m-1" controlId="filterPartner">
									<Form.Label>{t('Partner')}</Form.Label>
									<Form.Control type="text"
										placeholder="&#128269; "
										value={partner}
										onChange={handleChangePartner}
									/>
								</Form.Group>
							</th>
							<th className="text-center">
								<Form.Group className="m-1" controlId="filterPartnerName">
									<Form.Label>{t('biz-partner_name')}</Form.Label>
									<Form.Control type="text"
										placeholder="&#128269; "
										value={partnerName}
										onChange={handleChangePartnerName}
									/>
								</Form.Group>
							</th>
							<th className="text-center">
								<Form.Group className="m-1" controlId="filterNote">
									<Form.Label>{t('note')}</Form.Label>
									<Form.Control type="text"
										placeholder="&#128269; "
										value={note}
										onChange={handleChangeNote}
									/>
								</Form.Group>

							</th>
							<th>{type === "PD" ? t('deno-delivery_date') : t('deno-stockin_date')}</th>
							<th className="text-end">{t('biz-invoiced')} %</th>
							<th className="text-center" style={{ paddingBottom: "13px" }}>
								<p className="mb-2">{t('biz-closed')}</p>
								<BooleanDropdown variant="onlyTrue" onChange={setFilterClosed} value={filterClosed} />
							</th>
							<th className="text-center" style={{ paddingBottom: "13px" }}>
								<p className="mb-2">{t('deno-approved')}</p>
								<BooleanDropdown variant="onlyTrue" onChange={setFilterApproved} value={filterApproved} />
							</th>
							<th className="text-center" style={{ paddingBottom: "13px" }}>
								<p className="mb-2">{t('canceled')}</p>
								<BooleanDropdown onChange={setFilterCancelled} variant="negative" value={filterCancelled} />
							</th>
							<th className="text-center">
								<Form.Group className="m-1" controlId="filterWriter">
									<Form.Label>{t('deno-issuer')}</Form.Label>
									<Form.Control type="text"
										placeholder="&#128269; "
										value={writer}
										onChange={handleChangeWriter}
									/>
								</Form.Group>
							</th>
						</tr>
					</thead>
					<tbody>
						{isLoaded ?
							dnotes_shown.map((dn, idx) => <DeliveryNoteSale key={idx} dn={dn} type={type} />)
							:
							<></>
						}
					</tbody>
				</Table>
			</div>
		</ErrorWrap>
	)
}
