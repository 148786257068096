/**
* First step of stock notification process
* @module orders/stock-notify-step1
* @author Lucie Zdeňková <lucie.zdenek@trustica.cz>
*/
import React from 'react';
import {Table, Form, Row, Col, Button, Badge, Alert} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Texts } from './order-purchase';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';

//seen all - OK


/**
 * First step of stockin component, where user choose products from order, that should arrive to warehouse.
 * 
 * @param {string} kodDokladu - code of the order
 * @param {any} items - all items in orders
 * @param {dictionary} pickedItems - state to store items ids, that user picked
 * @param {function} setPickedItems - function to update pickedItems 
 * @param {array} texts - text information to display during the stockin process ("neutralizace" and such)
 * @returns {component}
 */

export function StockNotifi1({ kodDokladu, items, pickedItems, setPickedItems, texts }) {
    const { t } = useTranslation();

    //pick item and update pickedItems dictionary
    function toggleItem(nop_IDPolozky) {
        var item = {};
        item[nop_IDPolozky] = !pickedItems[nop_IDPolozky];
        setPickedItems({ ...pickedItems, ...item });
    }

    // check whether items belongs to same warehouse
    const selectedItems = items.filter((item) => !!pickedItems[item.nop_IDPolozky]);
    const warehousesOfPickedItm = selectedItems.map((item) => item.nop_KodKnihySkladu);
    const allEqual = arr => arr.every(v => v === arr[0]);
    // used also to block second step
    const goodChoice = allEqual(warehousesOfPickedItm); 
    const error = !goodChoice ? <Alert style={{ maxWidth: "500px" }} className="mt-2" variant="danger">{t('ord-choosen_items_doenst_belong_to_same_wh')}!</Alert> : "";

    return (
        <ErrorWrap>
            <Row>
                <Col>
                    <h5 className="mb-0">{t('ord-choose_items')}</h5>
                    <Table size="sm" className="mt-2 border" >
                        <thead className="beGray">
                            <tr>
                                <th className="text-center">{t('ord-check_item')}</th>
                                <th className="text-center">{t('warehouse')}</th>
                                <th className="text-center">{t('ord-remainin')}</th>
                                <th>{t('prod-id')}</th>
                                <th>{t('code')}</th>
                                <th>{t('name')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(function (item) {
                                const disabledItem = item.nop_ZbyvaPrijmout <= 0;
                                return (
                                    <tr key={item.nop_IDPolozky} onClick={disabledItem ? undefined : () => toggleItem(item.nop_IDPolozky)} style={{ cursor: disabledItem ? "" : "pointer" }}>
                                        <td className="text-center">
                                            <Form.Check type="checkbox"
                                                value={1}
                                                checked={!!pickedItems[item.nop_IDPolozky]}
                                                onChange={() => toggleItem(item.nop_IDPolozky)}
                                                id={item.nop_IDPolozky}
                                                disabled={disabledItem} />
                                        </td>
                                        <td className="text-center">{item.nop_KodKnihySkladu}</td>
                                        <td className="text-center">{item.nop_ZbyvaPrijmout}&nbsp;{item.nop_KodMj}</td>
                                        <td>{item.p_k_IDProduktu}</td>
                                        <td>{item.nop_KodProduktu}</td>
                                        <td>{item.nop_NazevProduktu}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    <span className="text-muted mb-0"><Badge pill bg="secondary">i</Badge> {t('ord-items_within_one_wh_only')} {error}</span>
                </Col>
                <Col xs="4">
                    <Texts texts={texts} />
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <LinkContainer to={{ pathname: "/orders/purchase/" + kodDokladu }}>
                        <Button>{t('ord-cancel_sn')}</Button>
                    </LinkContainer>
                </Col>
                <Col className="text-end">
                    <LinkContainer to={{ pathname: "/orders/purchase/" + kodDokladu + "/notification/2" }}>
                        <Button disabled={selectedItems.length === 0 || !goodChoice} className="float-end">{t('ord-second_step')}</Button>
                    </LinkContainer>
                </Col>
            </Row>
        </ErrorWrap>
    );
}