/**
 * Products listing.
 * @module products/products
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Nav, Form } from 'react-bootstrap';
import { Pager } from '../comp/pager';
import { filter_rule } from '../lib/utils';
import { BooleanDropdown } from '../comp/boolean';
import { LinkContainer } from 'react-router-bootstrap';
import { Route, Routes } from "react-router-dom";
import { ProductDetail } from './product-detail';
import { his_fetch, his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import { ProductsTracking } from './products-tracking';
import { Messenger } from '../comp/messenger.js';
import { WarehousesPackagings } from './warehouses-packagings';
import { ErrorWrap } from '../comp/errorwrap';
import { ProductsCheckOverviewHorizontal } from './products-check-overview-horizontal';
//import { Address } from '../partners/partner-detail';
import { ItemsAndFiltered } from '../comp/dynamic-load';
import { StocksSwitch } from './stocks';
import { date_formatISO } from '../lib/date-utils';
import { NotesList } from '../notes/notes-list';
import { useTranslation } from 'react-i18next';
import { ChooseCategory } from '../comp/choose-category';

//seen all - OK

//         0               1                2              3          4                   5
//     KodProduktu    k_IDProduktu    NazevProduktu    k_ADR    KodPartneraDodavatel    Aktivni


export function Products({ user, userinfo, userlogin }) {
	const { t } = useTranslation();

	//products-list states
	const [products, setProducts] = useState(null);
	const [offset, setOffset] = useState(0);
	const [filterCode, setFilterCode] = useState("");
	const [filterID, setFilterID] = useState("");
	const [filterName, setFilterName] = useState("");
	const [filterPartner, setFilterPartner] = useState("");
	const [filterADR, setFilterADR] = useState(null);
	const [filterAktivni, setFilterAktivni] = useState(null);
	const [massFilter, setMassFilter] = useState("");
	const [loadedStatus, setLoadedStatus] = useState(0);

	const defaultDate = date_formatISO(new Date());


	return (
		<ErrorWrap>
			<Nav variant="tabs" defaultActiveKey="/products/list" className="mb-3">
				<Nav.Item>
					<LinkContainer to="/products/list">
						<Nav.Link eventKey="/products/lists">{t('menu2-prod-list')}</Nav.Link>
					</LinkContainer>
				</Nav.Item>
				<Nav.Item>
					<LinkContainer to="/products/check">
						<Nav.Link eventKey="/products/check">{t('menu2-prod-check')}</Nav.Link>
					</LinkContainer>
				</Nav.Item>
				<Nav.Item>
					<LinkContainer to="/products/tracking">
						<Nav.Link eventKey="/products/tracking">{t('menu2-prod-track')}</Nav.Link>
					</LinkContainer>
				</Nav.Item>
				<Nav.Item>
					<LinkContainer to="/products/packagings">
						<Nav.Link eventKey="/products/packagings">{t('menu2-prod-pack')}</Nav.Link>
					</LinkContainer>
				</Nav.Item>
				<Nav.Item>
					<LinkContainer to={"/products/stocks/" + defaultDate}>
						<Nav.Link eventKey={"/products/stocks/" + defaultDate}>{t('menu3-deno-stat-wh')}</Nav.Link>
					</LinkContainer>
				</Nav.Item>
				<Nav.Item>
					<LinkContainer to="/products/notes">
						<Nav.Link eventKey="/products/notes">{t('menu2-notes')}</Nav.Link>
					</LinkContainer>
				</Nav.Item>
				<Nav.Item className="ms-auto">
					<Routes>
						<Route path='view/:id' element={<Messenger userlogin={userlogin} type={'prod'} />} />
						<Route path='stocks/:fetchDate/inventory/:wh/*' element={<Messenger userlogin={userlogin} type={'inventory'} />} />
					</Routes>
				</Nav.Item>


			</Nav>

			<Routes>
				<Route path='list' element={
					<ProductsList user={user} userinfo={userinfo} userlogin={userlogin}
						products={products} setProducts={setProducts} offset={offset} setOffset={setOffset}
						filterCode={filterCode} setFilterCode={setFilterCode} filterID={filterID} setFilterID={setFilterID}
						filterName={filterName} setFilterName={setFilterName} filterPartner={filterPartner} setFilterPartner={setFilterPartner}
						filterADR={filterADR} setFilterADR={setFilterADR} filterAktivni={filterAktivni} setFilterAktivni={setFilterAktivni}
						loadedStatus={loadedStatus} setLoadedStatus={setLoadedStatus} massFilter={massFilter} setMassFilter={setMassFilter}
					/>
				} />
				<Route path='check' element={<ProductsCheckOverviewHorizontal userlogin={userlogin} />} />
				<Route path='view/:id' element={<ProductDetail user={user} userinfo={userinfo} userlogin={userlogin} />} />
				<Route path='tracking' element={<ProductsTracking user={user} userinfo={userinfo} userlogin={userlogin} />} />
				<Route path='packagings' element={<WarehousesPackagings />} />
				<Route path='stocks/:fetchDate/*' element={<StocksSwitch userlogin={userlogin} />} />
				<Route path="notes" element={<NotesList userlogin={userlogin} diff_object_types={{ 1: false, 2: false, 3: true, 4: true }} />} />
				<Route path="" element={<ChooseCategory />} />
			</Routes>
		</ErrorWrap>
	);
}


function ProductsList({ userlogin, products, setProducts, offset, setOffset, filterCode, setFilterCode, filterID, setFilterID,
	filterName, setFilterName, filterPartner, setFilterPartner, filterADR, setFilterADR, filterAktivni, setFilterAktivni,
	loadedStatus, setLoadedStatus, massFilter, setMassFilter }) {

	const reloadIt = () => {
		setProducts(null);
		setLoadedStatus(0);
	}

	useEffect(() => {
		if (products === null) {
			const running_fetch = his_fetch(
				userlogin,
				[
					{
						uri: "/api/products",
						json: true,
						status: setLoadedStatus,
						ok: function (resource, result) {
							setProducts(result.products);
							//console.log(result.products);
						},
						error: function (resource, reason) {
							console.log('err: ' + reason);
							setProducts("error");
						}
					}
				]
			);
			return () => {
				running_fetch();
			}
		}
	}, [userlogin, products, setLoadedStatus, setProducts]);

	const cleanFilters = () => {
		setFilterCode("");
		setFilterID("");
		setFilterName("");
		setFilterPartner("");
		setFilterADR(null);
		setFilterAktivni(null);
		setMassFilter("");
	}

	if (!his_fetch_success(loadedStatus)) {
		return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />;
	}

	const handleChangeCode = function (event) {
		setFilterCode(event.target.value);
		setOffset(0);
	}

	const handleChangeID = function (event) {
		setFilterID(event.target.value);
		setOffset(0);
	}

	const handleChangeName = function (event) {
		setFilterName(event.target.value);
		setOffset(0);
	}

	const handleChangePartner = function (event) {
		setFilterPartner(event.target.value);
		setOffset(0);
	}
	const handleChangeMassFilter = function (event) {
		setMassFilter(event.target.value);
		setOffset(0);
	}

	const all_products = products.filter(
		function (pr) {
			return (
				(filter_rule(filterID, pr.p_k_IDProduktu, true)) &&
				(filter_rule(filterCode, pr.p_KodProduktu, true)) &&
				(filter_rule(filterName, pr.p_NazevProduktu, true)) &&
				(filter_rule(filterPartner, pr.p_k_KodPartneraDodavatel, true)) &&
				((filterADR === null) ||
					(filterADR === pr.p_k_ADR)) &&
				((filterAktivni === null) ||
					(filterAktivni === pr.p_Aktivni)) &&
				(filter_rule(massFilter, pr.p_k_IDProduktu, true) ||
					filter_rule(massFilter, pr.p_KodProduktu, true) ||
					filter_rule(massFilter, pr.p_NazevProduktu, true) ||
					filter_rule(massFilter, pr.p_k_KodPartneraDodavatel, true)
				)
			);
		});
	const show_products = all_products.slice(offset, offset + 20);
	return (
		<ErrorWrap>
			<Row>
				<Col xs="12" sm="0" md="4"></Col>
				<Col xs="12" sm="6" md="4" className='text-center mb-1'>
					<ItemsAndFiltered filtered_data={all_products} data={products} cleanFilters={cleanFilters} />
				</Col>
				<Col xs="12" sm="6" md="4" >
					<Pager offset={offset} pagesize={20} total={all_products.length} callback={setOffset} />
				</Col>
			</Row>
			<ProductsWeb
				filterID={filterID} handleChangeID={handleChangeID} filterCode={filterCode} handleChangeCode={handleChangeCode} filterName={filterName}
				handleChangeName={handleChangeName} setFilterADR={setFilterADR} filterADR={filterADR}
				filterPartner={filterPartner} handleChangePartner={handleChangePartner} setFilterAktivni={setFilterAktivni}
				filterAktivni={filterAktivni} show_products={show_products}
			/>
			<ProductsApp
				setFilterADR={setFilterADR} filterADR={filterADR}
				setFilterAktivni={setFilterAktivni} filterAktivni={filterAktivni}
				show_products={show_products} massFilter={massFilter} handleChangeMassFilter={handleChangeMassFilter}
			/>
			<Pager offset={offset} pagesize={20} total={all_products.length} callback={setOffset} />
		</ErrorWrap>
	);
}

function ProductsApp({ setFilterADR, filterADR, setFilterAktivni, filterAktivni, show_products, massFilter, handleChangeMassFilter }) {
	const { t } = useTranslation();
	return (
		<div className="d-lg-none">
			<Form.Group controlId="filterMass">
				<Form.Label className='mb-1'>{t('mass_filter')}</Form.Label>
				<Form.Control type="text" className="mb-3"
					placeholder={"🔍 " + t('filter_id_code_name_supplier')}
					value={massFilter}
					onChange={handleChangeMassFilter} />
			</Form.Group>
			<Row>
				<Col className='text-center'>
					{t('active')}
					<BooleanDropdown onChange={setFilterAktivni} variant="onlyTrue" value={filterAktivni} className="beGrayButton" />
				</Col>
				<Col className='text-center'>
					{t('prod-ADR')}
					<BooleanDropdown onChange={setFilterADR} variant="onlyTrue" value={filterADR} className="beGrayButton" />
				</Col>
			</Row>
			<Table size="sm" striped className="mt-3" >
				<tbody>
					{show_products.map((row) => <ProductsRowApp row={row} key={row.p_KodProduktu} />)}
				</tbody>
			</Table>
		</div>
	);
}

function ProductsRowApp({ row }) {
	const { t } = useTranslation();

	return (
		<LinkContainer to={{ pathname: "/products/view/" + encodeURIComponent(row.p_KodProduktu) }} key={row.p_KodProduktu}>
			<tr>
				<td>
					<Row>
						<Col xs="auto">{row.p_k_IDProduktu}</Col>
						<Col className="text-end">{t('active')}: {row.p_Aktivni ? <span role="img" aria-label="ano">Ano &#9989;</span> : "Ne"} </Col>
					</Row>
					<Row>
						<Col xs="auto">{row.p_KodProduktu}</Col>
						<Col className="text-end">{t('prod-ADR')}: {row.p_k_ADR ? <span role="img" aria-label="ano">Ano &#9989;</span> : "Ne"}</Col>
					</Row>
					<Row>
						<Col xs="auto">{row.p_NazevProduktu}</Col>
						<Col className="text-end">{t('prod-bbd_batch_settings')}.: {row.pwc_id}</Col> {/**tady bude potřeba sjednocení */}
					</Row>
					<Row>
						<Col xs="auto">{t('prod-supplier')}: {row.p_k_KodPartneraDodavatel}</Col>
						<Col className="text-end">{row.pwc_months ? <>{t('months')}: {row.pwc_months}</> : ""}</Col>
					</Row>
				</td>
			</tr>
		</LinkContainer>
	);
}

function ProductsWeb({ filterID, handleChangeID, filterCode, handleChangeCode, filterName, handleChangeName, setFilterADR, filterADR,
	filterPartner, handleChangePartner, setFilterAktivni, filterAktivni, show_products }) {
	const { t } = useTranslation();
	return (
		<Table className='d-none d-lg-table' hover size="sm" striped>
			<thead className="beGray">
				<tr>
					<th rowSpan="2" className='pb-3'>
						<Form.Group controlId="filterID">
							<Form.Control type="text"
								placeholder={"🔍 " + t('not-prod_id')}
								value={filterID}
								onChange={handleChangeID} />
						</Form.Group>
					</th>
					<th rowSpan="2" className='pb-3'>
						<Form.Group controlId="filterCode">
							<Form.Control type="text"
								placeholder={"🔍 " + t('prod-code')}
								value={filterCode}
								onChange={handleChangeCode} />
						</Form.Group>
					</th>
					<th rowSpan="2" className='pb-3'>
						<Form.Group controlId="filterName">
							<Form.Control type="text"
								placeholder={"🔍 " + t('prod-name')}
								value={filterName}
								onChange={handleChangeName} />
						</Form.Group></th>
					<th rowSpan="2" className="text-center pb-3" style={{ paddingBottom: "21px" }}>{t('prod-ADR')}
						<BooleanDropdown onChange={setFilterADR} variant="onlyTrue" value={filterADR} />
					</th>
					<th rowSpan="2" className='pb-3'>
						<Form.Group controlId="filterPartner">
							<Form.Control type="text"
								placeholder={"🔍 " + t('prod-supplier_partner')}
								value={filterPartner}
								onChange={handleChangePartner} />
						</Form.Group></th>
					<th rowSpan="2" className="text-center pb-3" style={{ paddingBottom: "21px" }}>{t('active')}
						<BooleanDropdown onChange={setFilterAktivni} variant="onlyTrue" value={filterAktivni} />
					</th>
					<th colSpan="2" className="text-center">{t('prod-batch_expiry')}</th>
				</tr>
				<tr>
					<th className="text-center pb-2">{t('prod-settings')}</th>
					<th className="text-center pb-2">{t('months')}</th>
				</tr>
			</thead>
			<tbody>
				{
					show_products.map(function (pr) {
						return (
							<LinkContainer key={pr.p_KodProduktu} to={{ pathname: "/products/view/" + encodeURIComponent(pr.p_KodProduktu) }}>
								<tr>
									<td className="align-middle">{pr.p_k_IDProduktu}</td>
									<td className="align-middle">{pr.p_KodProduktu}</td>
									<td className="align-middle">{pr.p_NazevProduktu}</td>
									<td className="text-center align-middle">{pr.p_k_ADR ? <span role="img" aria-label="ano">{t('yes')} &#9989;</span> : t('no')}</td>
									<td className="align-middle">{pr.p_k_KodPartneraDodavatel}</td>
									<td className="text-center align-middle">{pr.p_Aktivni ? <span role="img" aria-label="ano">{t('yes')} &#9989;</span> : t('no')}</td>
									<td className="text-center">{pr.pwc_id}</td>
									<td className="text-end pe-3">{pr.pwc_months}</td>
								</tr>
							</LinkContainer>
						);
					})
				}
			</tbody>
		</Table>
	);
}
