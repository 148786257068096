/**
 * List of delivery notes, app and web version
 * @module delivery-notes/delivery-notes-sales
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */

import React, { useState, useCallback } from 'react';
import { filter_rule } from '../lib/utils';
import { PDListMobile } from './delivery-notes-sales-app';
import { DeliveryNotesSalesWeb } from './delivery-notes-sales-web';
import { his_fetch, } from '../comp/FetchLoader';
import { ErrorWrap } from '../comp/errorwrap';
import { DynamicLoad, LoadingDataInfo } from '../comp/dynamic-load';
import { Pager } from '../comp/pager';

//seen all - OK

/*
	KodDokladu
	KodDokladuObjednavky
	k_Exportovano
	KodPartnera
	NazevPartnera,
	Poznamka
	DatDokladu
	FakturovanoProc
	Uzavreno
	Schvaleno
	Zruseno
	KodPracovnikaVystavil
*/

export const mappings = {
	"PD": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
	"ND": ["ndd_KodDokladu", "ndd_KodDokladuObjednavky", "ndd_k_Importovano", "ndd_KodPartnera", "p_NazevPartnera", "ndd_Poznamka", "ndd_DatDokladu", "ndd_FakturovanoProc", "ndd_Uzavreno", "ndd_Schvaleno", "ndd_Zruseno", "ndd_KodPracovnikaVystavil"]
}

/**
 * List of delivery notes - following component does fetch, filtering, pagination
 * 
 * @param {any} userlogin - info about logged user
 * @param {string} type - ist "PD" for sales delivery notes and "ND" for purchase delivery notes
 * @returns {component}
 */
export function DeliveryNotesList({ userlogin, type = "PD" }) {

	const [dNotes, setDNotes] = useState([]);
	const [totalCount, setTotalCount] = useState(null);
	const [oldest, setOldest] = useState(null);
	const [offset, setOffset] = useState(0);
	const [code, setCode] = useState("");
	const [order, setOrder] = useState("");
	const [partner, setPartner] = useState("");
	const [partnerName, setPartnerName] = useState("");
	const [note, setNote] = useState("");
	const [writer, setWriter] = useState("");
	const [filterExport, setFilterExport] = useState(null);
	const [filterClosed, setFilterClosed] = useState(null);
	const [filterApproved, setFilterApproved] = useState(null);
	const [filterCancelled, setFilterCancelled] = useState(null);
	const [massFilterApp, setMassFilterApp] = useState("");
	const [loadedStatus, setLoadedStatus] = useState(0);
	const [currentType, setCurrentType] = useState(type);

	const mapping = mappings[type];

	//console.log(type);
	//console.log(loadedStatus);

	if (type !== currentType) {
		setDNotes([]);
		setLoadedStatus(0);
		setCurrentType(type);
	}

	const loadData = useCallback(function (startDate, endDate, result_received, reload = false) {
		const apiPath = type === "PD" ? "/api/delivery-notes/range" : "/api/purchase-delivery-notes/range"
		return (
			his_fetch(
				userlogin,
				[
					{
						uri: [apiPath, startDate, endDate]
							.filter((v) => v !== null)
							.join("/"),
						json: true,
						status: setLoadedStatus,
						ok: function (resource, result) {
							//console.log(result);
							if (result.error) {
								result_received(false);
							} else {
								//console.log("fetch");
								setDNotes(dNotesCat => (reload ? [] : dNotesCat).concat(result.result)); //concatuje k předchozí, pokud nebyl reload
								setTotalCount(result.total_count);
								setOldest(type === "PD" ? result.oldest[6] : result.oldest.ndd_DatDokladu);
								result_received(true);
							}
						},
						error: function (resource, reason) {
							console.log('err: ' + reason);
							result_received(false);
						}
					}
				]
			))
	}, [userlogin, type])

	//console.log(dNotes);

	const cleanFilters = () => {
		setCode("");
		setOrder("");
		setPartner("");
		setPartnerName("");
		setNote("");
		setWriter("");
		setFilterExport(null);
		setFilterClosed(null);
		setFilterApproved(null);
		setFilterCancelled(null);
		setMassFilterApp("");
	}

	const handleChangeCode = (e) => {
		setCode(e.target.value);
		setOffset(0);
	}

	const handleChangeOrder = (e) => {
		setOrder(e.target.value);
		setOffset(0);
	}

	const handleChangePartner = (e) => {
		setPartner(e.target.value);
		setOffset(0);
	}

	const handleChangePartnerName = (e) => {
		setPartnerName(e.target.value);
		setOffset(0);
	}

	const handleChangeNote = (e) => {
		setNote(e.target.value);
		setOffset(0);
	}

	const handleChangeWriter = (e) => {
		setWriter(e.target.value);
		setOffset(0);
	}

	const handleChangeAppFilter = (e) => {
		//console.log(e);
		setMassFilterApp(e.target.value);
		setOffset(0);
	}

	const dnotes_filtered = dNotes.filter( //tady všude ternární operátory pro klíče??? nebo separe filtrovací funkce? 
		function (dn) {
			return (
				(filter_rule(code, dn[mapping[0]], true)) &&   //kod dokladu //rec[mapping[typ][0]]   --- typ je PD nebo ND
				(filter_rule(order, dn[mapping[1]], true)) &&  //objednavka
				(filter_rule(partner, dn[mapping[3]], true)) &&  //partner 
				(filter_rule(partnerName, dn[mapping[4]], true)) &&  //název partnera
				(filter_rule(note, dn[mapping[5]], true)) &&  // poznámka
				(filter_rule(writer, dn[mapping[11]], true)) && // vystavil
				((filterExport === null) ||
					(filterExport === dn[mapping[2]])) &&  //exportováno
				((filterClosed === null) ||
					(filterClosed === dn[mapping[8]])) && // uzavřeno
				((filterApproved === null) ||
					(filterApproved === dn[mapping[9]])) && //schváleno
				((filterCancelled === null) ||
					(filterCancelled === dn[mapping[10]])) &&  //zrušeno
				((filter_rule(massFilterApp, dn[mapping[0]], true)) ||   //kod dokladu
					(filter_rule(massFilterApp, dn[mapping[1]], true)) ||   //objednavka
					(filter_rule(massFilterApp, dn[mapping[3]], true)) ||   //partner 
					(filter_rule(massFilterApp, dn[mapping[4]], true)) ||   //název partnera
					(filter_rule(massFilterApp, dn[mapping[5]], true)) ||  // poznámka
					(filter_rule(massFilterApp, dn[mapping[11]], true)))
			);
		}
	)

	const dnotes_shown = dnotes_filtered.slice(offset, offset + 20);

	const isLoaded = loadedStatus === 2;

	return (
		<ErrorWrap>
			<DynamicLoad period='1y' loadData={loadData}
				filtered_data={dnotes_filtered} data={dNotes}
				offset={offset} setOffset={setOffset}
				totalCount={totalCount} oldest={oldest}
				cleanFilters={cleanFilters}
				flexibleRange
			/>
			<DeliveryNotesSalesWeb
				dnotes_filtered={dnotes_filtered}
				dnotes_shown={dnotes_shown}
				code={code}
				order={order}
				partner={partner}
				setPartnerName={partnerName}
				note={note}
				writer={writer}
				handleChangeCode={handleChangeCode}
				handleChangeOrder={handleChangeOrder}
				setFilterExport={setFilterExport}
				handleChangePartner={handleChangePartner}
				handleChangePartnerName={handleChangePartnerName}
				handleChangeNote={handleChangeNote}
				setFilterClosed={setFilterClosed}
				setFilterApproved={setFilterApproved}
				setFilterCancelled={setFilterCancelled}
				handleChangeWriter={handleChangeWriter}
				filterExport={filterExport}
				filterClosed={filterClosed}
				filterApproved={filterApproved}
				filterCancelled={filterCancelled}
				massFilterApp={massFilterApp}
				offset={offset}
				setOffset={setOffset}
				type={type}
				isLoaded={isLoaded} />

			<PDListMobile
				dnotes_shown={dnotes_shown}
				setFilterExport={setFilterExport}
				setFilterClosed={setFilterClosed}
				setFilterApproved={setFilterApproved}
				setFilterCancelled={setFilterCancelled}
				handleChangeAppFilter={handleChangeAppFilter}
				filterExport={filterExport}
				filterClosed={filterClosed}
				filterApproved={filterApproved}
				filterCancelled={filterCancelled}
				massFilterApp={massFilterApp}
				offset={offset}
				setOffset={setOffset}
				type={type}
				isLoaded={isLoaded}
			/>

			<LoadingDataInfo loadedStatus={loadedStatus} data={dNotes} specialCase />
			<Pager offset={offset} pagesize={20} total={dnotes_filtered.length} callback={setOffset} />
		</ErrorWrap>
	);
}
