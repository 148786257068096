/**
 * Partners list component.
 * @module partners/partners
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React, { useState, useEffect } from 'react';
import { Table, Form, Row, Col, ListGroup, Nav, Accordion } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { format_amount } from '../lib/format';
import { Rating } from '../comp/rating';
import { filter_rule } from '../lib/utils';
import { Routes, Route } from "react-router-dom";
import { Pager } from '../comp/pager';
import { his_fetch, his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import { PaymentsDiscipline } from './payments-discipline';
import { Partner } from './partner-view';
import { ErrorWrap } from '../comp/errorwrap';
import { Image } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Debts } from './debts';
import { InvoicesIssued } from '../invoices/invoices-issued';
import { InvoiceIssuedDetail } from '../invoices/invoice-issued-detail.js';
import { InvoicesReceived } from '../invoices/invoices-received';
import { Margins } from '../invoices/margins';
import { NotesList } from '../notes/notes-list';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { ChooseCategory } from '../comp/choose-category';

//seen all - OK

export function Partners({ userlogin }) {
	const { t } = useTranslation();
	//useStates from PartnerList
	const [filterCodeList, setFilterCodeList] = useState("");
	const [filterNameList, setFilterNameList] = useState("");
	const [filterICList, setFilterICList] = useState("");
	const [filterDICList, setFilterDICList] = useState("");
	const [filterPhoneList, setFilterPhoneList] = useState("");
	const [filterEmailList, setFilterEmailList] = useState("");
	const [filterAppList, setFilterAppList] = useState("");
	const [partnersList, setPartnersList] = useState(null);
	const [offsetList, setOffsetList] = useState(0);
	const [loadedStatusList, setLoadedStatusList] = useState(0);
	//useStates from PartnerMorale
	const [morale, setMorale] = useState([]);
	const [loadedStatusMorale, setLoadedStatusMorale] = useState(0);
	const [offsetMorale, setOffsetMorale] = useState(0);
	const [filterCodeMorale, setFilterCodeMorale] = useState("");
	const [filterNameMorale, setFilterNameMorale] = useState("");
	const [filterCount, setFilterCount] = useState("");
	const [filterDays, setFilterDays] = useState("");
	const [filterTolerance, setFilterTolerance] = useState("");
	const [filterBalance, setFilterBalance] = useState("");
	const [filterBalanceDelayed, setFilterBalanceDelayed] = useState("");
	const [filterDelay, setFilterDelay] = useState("");
	const [sortValue, setSortValue] = useState("");
	const [direction, setDirection] = useState("");
	const [type, setType] = useState("");
	const [months, setMonths] = useState(12);
	const [monthsFetch, setMonthsFetch] = useState(0);

	//názvy jak se to předává nechat původní a unikátní mít ty stavové proměnné 

	return (
		<ErrorWrap>
			<Nav variant="tabs" className="mb-3">
				<Nav.Item>
					<LinkContainer to="/partners/list">
						<Nav.Link eventKey="/partners/list">{t('menu2-biz-par')}</Nav.Link>
					</LinkContainer>
				</Nav.Item>
				<Nav.Item>
					<LinkContainer to="/partners/debts">
						<Nav.Link eventKey="/partners/debts">{t('menu2-biz-debts')}</Nav.Link>
					</LinkContainer>
				</Nav.Item>
				<Nav.Item>
					<LinkContainer to="/partners/morale">
						<Nav.Link eventKey="/partners/morale">{t('menu2-biz-mor')}</Nav.Link>
					</LinkContainer>
				</Nav.Item>
				<Nav.Item>
					<LinkContainer to="/partners/invoices-issued">
						<Nav.Link eventKey="/partners/invoices-issued">{t('menu2-biz-inv_is')}</Nav.Link>
					</LinkContainer>
				</Nav.Item>
				<Nav.Item>
					<LinkContainer to="/partners/invoices-received">
						<Nav.Link eventKey="/partners/invoices-received">{t('menu2-biz-inv_re')}</Nav.Link>
					</LinkContainer>
				</Nav.Item>
				<Nav.Item>
					<LinkContainer to="/partners/margins">
						<Nav.Link eventKey="/partners/margins">{t('menu2-biz-marg')}</Nav.Link>
					</LinkContainer>
				</Nav.Item>
				<Nav.Item>
					<LinkContainer to="/partners/notes">
						<Nav.Link eventKey="/partners/notes">{t('menu2-notes')}</Nav.Link>
					</LinkContainer>
				</Nav.Item>

			</Nav>
			<Routes>
				<Route path='list/:partnerCode/*' element={
					<Partner userlogin={userlogin} user={userlogin.username} userinfo={userlogin.userinfo} />
				} />
				<Route path='list' element={
					<PartnersList userlogin={userlogin} filterCode={filterCodeList} setFilterCode={setFilterCodeList} filterName={filterNameList} setFilterName={setFilterNameList}
						filterIC={filterICList} setFilterIC={setFilterICList} filterDIC={filterDICList} setFilterDIC={setFilterDICList} filterPhone={filterPhoneList} setFilterPhone={setFilterPhoneList}
						filterEmail={filterEmailList} setFilterEmail={setFilterEmailList} filterApp={filterAppList} setFilterApp={setFilterAppList} partners={partnersList} setPartners={setPartnersList}
						offset={offsetList} setOffset={setOffsetList} loadedStatus={loadedStatusList} setLoadedStatus={setLoadedStatusList}
					/>
				} />
				<Route path='debts' element={
					<Debts userinfo={userlogin.userinfo} />
				} />
				<Route path='morale' element={
					<PaymentsDiscipline userlogin={userlogin} morale={morale} setMorale={setMorale} loadedStatus={loadedStatusMorale}
						setLoadedStatus={setLoadedStatusMorale} offset={offsetMorale} setOffset={setOffsetMorale} filterCode={filterCodeMorale}
						setFilterCode={setFilterCodeMorale} filterName={filterNameMorale} setFilterName={setFilterNameMorale} filterCount={filterCount}
						setFilterCount={setFilterCount} filterDays={filterDays} setFilterDays={setFilterDays} filterTolerance={filterTolerance}
						setFilterTolerance={setFilterTolerance} filterBalance={filterBalance} setFilterBalance={setFilterBalance} filterDelay={filterDelay}
						setFilterDelay={setFilterDelay} sortValue={sortValue} setSortValue={setSortValue} direction={direction}
						setDirection={setDirection} type={type} setType={setType} months={months} setMonths={setMonths}
						monthsFetch={monthsFetch} setMonthsFetch={setMonthsFetch}
						filterBalanceDelayed={filterBalanceDelayed} setFilterBalanceDelayed={setFilterBalanceDelayed}
					/>
				} />
				<Route path="invoices-issued/:id" element={
					<InvoiceIssuedDetail user={userlogin.username} userinfo={userlogin.userinfo} userlogin={userlogin} />
				} />
				<Route path="invoices-issued" element={
					<InvoicesIssued userinfo={userlogin.userinfo} userlogin={userlogin} />
				} />
				<Route path="invoices-received" element={
					<InvoicesReceived userinfo={userlogin.userinfo} userlogin={userlogin} />
				} />
				<Route path="margins/:startDate/:endDate" element={
					<Margins userlogin={userlogin} />
				} />
				<Route path="margins" element={
					<Margins userlogin={userlogin} />
				} />
				<Route path="notes" element={
					<NotesList userlogin={userlogin} diff_object_types={{ 1: true, 2: true, 3: false, 4: false }} />
				} />
				<Route path="" element={<ChooseCategory />} />
			</Routes>
		</ErrorWrap >
	);
}

export function PartnersList({ userlogin, filterCode, setFilterCode, filterName, setFilterName, filterIC, setFilterIC,
	filterDIC, setFilterDIC, filterPhone, setFilterPhone, filterEmail, setFilterEmail, filterApp, setFilterApp, partners,
	setPartners, offset, setOffset, loadedStatus, setLoadedStatus }) {

	const reloadIt = () => {
		setPartners(null);
		setLoadedStatus(0);
	}


	useEffect(() => {
		if (partners === null) {
			const running_fetch = his_fetch(
				userlogin,
				[
					{
						uri: "/api/partners",
						json: true,
						status: setLoadedStatus,
						ok: function (resource, result) {
							//console.log(result);
							setPartners(result.partners);
							//console.log(result.partners);
						},
						error: function (resource, reason) {
							console.log('err: ' + reason);
							setPartners("error");
						}
					}
				]
			);
			return () => {
				running_fetch();
			}
		}
	}, [userlogin, partners, setLoadedStatus, setPartners]);

	if (!his_fetch_success(loadedStatus)) {
		return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />;
	}

	const handleChangeCode = (e) => {
		setFilterCode(e.target.value.toLowerCase());
		setOffset(0);
	}
	const handleChangeName = (e) => {
		setFilterName(e.target.value.toLowerCase());
		setOffset(0);
	}
	const handleChangeIC = (e) => {
		setFilterIC(e.target.value.toLowerCase());
		setOffset(0);
	}
	const handleChangeDIC = (e) => {
		setFilterDIC(e.target.value.toLowerCase());
		setOffset(0);
	}
	const handleChangePhone = (e) => {
		setFilterPhone(e.target.value.toLowerCase());
		setOffset(0);
	}
	const handleChangeEmail = (e) => {
		setFilterEmail(e.target.value.toLowerCase());
		setOffset(0);
	}

	const handleChangeAppFilter = (e) => {
		setFilterApp(e.target.value.toLowerCase());
		setOffset(0);
	}

	const listCats = partners.filter(
		function (cat) {
			return ((cat[0].length > 0) &&
				(cat[0] !== '?') &&
				((filter_rule(filterCode, cat[0], true)) &&
					(filter_rule(filterName, cat[1], true)) &&
					(filter_rule(filterIC, cat[2], true)) &&
					(filter_rule(filterDIC, cat[3], true)) &&
					(filter_rule(filterPhone, cat[4], true)) &&
					(filter_rule(filterEmail, cat[5], true)) &&

					((filterApp.length === 0) ||
						(filter_rule(filterApp, cat[0], true) ||
							(filter_rule(filterApp, cat[1], true)) ||
							(filter_rule(filterApp, cat[2], true)) ||
							(filter_rule(filterApp, cat[3], true)) ||
							(filter_rule(filterApp, cat[4], true)) ||
							(filter_rule(filterApp, cat[5], true))))
				));
		});

	const partners_list = listCats.slice(offset, offset + 20);

	return (
		<>
			<Button size="sm" className="me-2 d-inline" onClick={reloadIt}><Image src="/img/reload.svg" height="19" /></Button>
			<Pager offset={offset} pagesize={20} total={listCats.length} callback={setOffset} />
			<Table striped size="sm" hover className="d-none d-lg-table">
				<PartnersFilterWeb
					handleChangeCode={handleChangeCode}
					filterCode={filterCode}
					handleChangeName={handleChangeName}
					filterName={filterName}
					handleChangeIC={handleChangeIC}
					filterIC={filterIC}
					handleChangeDIC={handleChangeDIC}
					filterDIC={filterDIC}
					handleChangePhone={handleChangePhone}
					filterPhone={filterPhone}
					handleChangeEmail={handleChangeEmail}
					filterEmail={filterEmail}
				/>
				<PartnersListWeb listCats={partners_list} />
			</Table>
			<PartnersFilterApp handleChangeAppFilter={handleChangeAppFilter} />
			<PartnersListApp listCats={partners_list} />
			<Pager offset={offset} pagesize={20} total={listCats.length} callback={setOffset} />
		</>
	);
}


function PartnersListApp({ listCats }) {
	var idx = 0;
	return (
		<Accordion className="d-lg-none mb-3 mt-3">
			{
				listCats.map(function (cat) {
					idx += 1;
					return (
						<React.Fragment key={idx}>
							{SinglePartnerFunc(cat)}
						</React.Fragment>
					);
				})}
		</Accordion>
	);
}

function SinglePartnerFunc(cat) {
	const kodFirmy = cat[0];
	return (
		<SinglePartner
			keyx={kodFirmy}
			rating={cat[10]}
			rating_history={cat[11]}
			code={kodFirmy}
			firma={cat[1]}
			ic={cat[2]}
			dic={cat[3]}
			mail={cat[5]}
			phone={cat[4]}
			limit_kr={format_amount(cat[6], false)}
			limit_in={format_amount(cat[7], false)}
			tolerance={cat[8]}
			skupina={cat[9]}
		/>
	);
}

function IC(ic) {
	if (ic) {
		return (
			<span><span className="text-muted">{t('biz-ic')}</span> {ic}</span>
		);
	} else {
		return <Col />;
	}
}

function DIC(dic) {
	if (dic) {
		return (
			<span><span className="text-muted">{t('biz-dic')}</span> {dic}</span>
		);
	} else {
		return <Col />;
	}
}

function SinglePartner({ keyx, rating, code, firma, ic, dic, mail, phone, limit_kr, limit_in, tolerance, skupina, rating_history }) {
	const { t } = useTranslation();

	return (
		<Accordion.Item eventKey={keyx}>
			<Accordion.Header >
				<Row>
					<Col xs="12" md="6">
						<Row className="g-0">
							<Col xs="auto" style={{ marginRight: "5px", marginTop: "6px" }}>
								<Rating rating={rating} history={rating_history} />
							</Col>
							<Col>
								<h5 className="mb-0">{firma}</h5>
								<p className="mb-0"><span className="text-muted">{t('code')}</span> {code}</p>
								{IC(ic)} {DIC(dic)}
							</Col>
						</Row>
					</Col>
					<Col xs="12" md="6">
						<Row className="g-0">
							<Col className="text-start">{phone}</Col>
							<Col xs="auto" className="text-end">{mail}</Col>
						</Row>
					</Col>
				</Row>
			</Accordion.Header>
			<Accordion.Body>
				<LinkContainer to={{ pathname: "/partners/list/" + code + "/detail" }}>
					<ListGroup variant="flush" className="smaller">
						<ListGroup.Item>
							<Row className="g-0">
								<Col>{t('biz-credit_lim')}: {format_amount(limit_kr, false)}</Col>
								<Col>{t('biz-intern_lim')}: {format_amount(limit_in, false)}</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row className="g-0">
								<Col>{t('Tolerance')}: {tolerance} {t('days')}</Col>
								<Col>{t('biz-group')}: {skupina}</Col>
							</Row>
						</ListGroup.Item>
					</ListGroup>
				</LinkContainer>
			</Accordion.Body>
		</Accordion.Item>
	);
}

function PartnersListWeb({ listCats }) {
	const rstyle = {
		cursor: "pointer"
	};
	return (
		<tbody>
			{
				listCats.map(function (cat) {
					//console.log(cat[0]);
					return (
						<LinkContainer to={{ pathname: "/partners/list/" + cat[0] + "/detail" }} key={cat[0]}>
							<tr key={cat[0]}>
								<td style={rstyle} className="align-middle">{cat[0]}</td>
								<td style={rstyle} className="align-middle">{cat[1]}</td>
								<td style={rstyle} className="align-middle">{cat[2]}</td>
								<td style={rstyle} className="align-middle">{cat[3]}</td>
								<td style={rstyle} className="align-middle">{cat[4]}</td>
								<td style={rstyle} className="align-middle">{cat[5]}</td>
								<td style={rstyle} className="text-center align-middle">{format_amount(cat[6], false)}</td>
								<td style={rstyle} className="text-center align-middle">{format_amount(cat[7], false)}</td>
								<td style={rstyle} className="text-center align-middle">{cat[8]}</td>
								<td style={rstyle} className="text-center align-middle">{cat[9]}</td>
								<td style={rstyle} className="text-center align-middle"><Rating rating={cat[10]} history={cat[11]} /></td>
							</tr>
						</LinkContainer>
					);
				})
			}
		</tbody>
	);
}

function PartnersFilterApp({ handleChangeAppFilter }) {
	const { t } = useTranslation();
	return (
		<>
			<br />
			<br />
			<Form.Group controlId="filterApp" className="d-lg-none">
				<Form.Label >{t('mass_filter')}</Form.Label>
				<Form.Control type="text" placeholder={"🔍 " + t('filter_code_name_ic_dic_phone_mail')} onChange={handleChangeAppFilter} />
			</Form.Group>
		</>
	);
}

function PartnersFilterWeb({ handleChangeCode, handleChangeName, handleChangeIC, handleChangeDIC, handleChangePhone, handleChangeEmail, filterName, filterCode, filterIC, filterDIC, filterPhone,
	filterEmail }) {
	const { t } = useTranslation();
	return (
		<thead className="beGray">
			<tr>
				<th width="12%" scope="col" className="text-center pb-2">
					<Form.Group controlId="filterCode">
						<Form.Control type="text" placeholder={"🔍 " + t('code')} onChange={handleChangeCode} value={filterCode} />
					</Form.Group>
				</th>
				<th width="15%" scope="col" className="text-center pb-2">
					<Form.Group controlId="filterName">
						<Form.Control type="text" placeholder={"🔍 " + t('name')} onChange={handleChangeName} value={filterName} />
					</Form.Group>
				</th>
				<th width="12%" scope="col" className="text-center pb-2">
					<Form.Group controlId="filterIC">
						<Form.Control type="text" placeholder={"🔍 " + t('biz-ic')} onChange={handleChangeIC} value={filterIC} />
					</Form.Group>
				</th>
				<th width="12%" scope="col" className="text-center pb-2">
					<Form.Group controlId="filterDIC">
						<Form.Control type="text" placeholder={"🔍 " + t('biz-dic')} onChange={handleChangeDIC} value={filterDIC} />
					</Form.Group>
				</th>
				<th width="12%" scope="col" className="text-center pb-2">
					<Form.Group controlId="filterPhone">
						<Form.Control type="text" placeholder={"🔍 " + t('biz-phon')} onChange={handleChangePhone} value={filterPhone} />
					</Form.Group>
				</th>
				<th width="12%" scope="col" className="text-center pb-2">
					<Form.Group controlId="filterEmail">
						<Form.Control type="text" placeholder={"🔍 " + t('email')} onChange={handleChangeEmail} value={filterEmail} />
					</Form.Group>
				</th>
				<th width="5%" scope="col" className="text-center align-middle">{t('biz-credit_lim')}</th>
				<th width="5%" scope="col" className="text-center align-middle">{t('biz-intern_lim')}</th>
				<th width="5%" scope="col" className="text-center align-middle">{t('biz-pay_tolerance')}</th>
				<th width="5%" scope="col" className="text-center align-middle">{t('biz-group')}</th>
				<th width="5%" scope="col" className="text-center align-middle">{t('biz-rating')}</th>
			</tr>
		</thead>
	);
}
