/**
* Third step of stock notification process
* @module orders/stock-notify-step3
* @author Lucie Zdeňková <lucie.zdenek@trustica.cz>
*/
import React from 'react';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { LinkContainer } from 'react-router-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';
import { Texts } from './order-purchase';
import { date_formatCZ, date_isValidObject } from '../lib/date-utils';
import { mesice } from '../lists/months';
import { ErrorWrap } from '../comp/errorwrap';
import { warehouses_names_ids_separe } from '../lists/warehouses-defs';
import { useTranslation } from 'react-i18next';

//seen all - OK

/**
 * Formats expiry information according to its type.
 * 
 * @param {any} expiry - expiry of batch
 * @param {string} expiryType - type of expiry - month-year, exact-date, whatever
 * @returns {string} - date in czech format or another information about expiry
 */
function displayExpiration(expiry, expiryType) {
    if (expiryType === "month-year") {
        const year = expiry.getFullYear();
        const month = mesice[parseInt(expiry.getMonth())][1];
        return month + " " + year;
    } else if (expiryType === "exact-date") {
        return date_formatCZ(expiry);
    } else if (date_isValidObject(expiry)) {
        return date_formatCZ(expiry);
    } else {
        return expiry;
    }
}

/**
 * Displays the third step of stockin: overview and submit button
 * 
 * @param {string} kodDokladu - id of an order for url path
 * @param {any} items - all items in the order
 * @param {any} etaDate - state - date object
 * @param {dictionary} pickedItems - ids of picked items
 * @param {any} notifyItems - item id: batches: [], expiry: ""
 * @param {function} createNotification - onClink for submit button - sends stockin to warehouse
 * @param {boolean} finalResult - true, if successfuly sent
 * @param {boolean} saving - stockin is being sent right now
 * @param {function} reloadIt - goes back to order after successful submit 
 * @param {string} errorMessage - text of submit result 
 * @param {array} texts - text information to display during the stockin process ("neutralizace" and such)
 * @returns {component}
 */
export function StockNotifi3({ kodDokladu, items, etaDate, pickedItems, notifyItems, createNotification, finalResult,
    saving, reloadIt, errorMessage, texts }) {
        const { t } = useTranslation();
   
    const selectedItems = items.filter((item) => !!pickedItems[item.nop_IDPolozky]);

    //console.log(selectedItems);
    //console.log(notifyItems);

    return (
        <ErrorWrap>
            <Row>
                <Col>
                    <h4>🕒 ETA: {date_formatCZ(new Date(etaDate))}</h4> ({t('ord-estimated_delivery_time')})
                    <br />
                    <br />
                    <h4 className="mb-0"><Image src="/img/warehouse.svg" height="30" /> {t('warehouse')} {selectedItems[0] ? warehouses_names_ids_separe[selectedItems[0].nop_KodKnihySkladu] : ""}</h4>
                </Col>
                <Col xs="4">
                    <Texts texts={texts} />
                </Col>
            </Row>


            <h5 className="mt-5">{t('ord-notifyied_items')}</h5>
            <Table size="sm" className="mb-3 border">
                <thead className="beGray">
                    <tr>
                        <th>{t('prod-id')}</th>
                        <th>{t('name')}</th>
                        <th className="text-center">{t('ord-remaining')}</th>
                        <th className="text-end">{t('ord-notified_amount')}</th>
                        <th className="text-center">{t('measure_unit')}</th>
                        <th>{t('ord-batch')}</th>
                        <th className="text-center">{t('ord-expiry')}</th>
                    </tr>
                </thead>
                <tbody>
                    {selectedItems.map(function (item, idx) {
                        return (
                            <tr key={idx}>
                                <td>{item.p_k_IDProduktu}</td>
                                <td className="align-middle">{item.nop_NazevProduktu}</td>
                                <td className="align-middle text-center">{item.nop_ZbyvaPrijmout}</td>
                                <td className="text-end">
                                    {notifyItems[item.nop_IDPolozky].batches.map(function (batches, idx) {
                                        return (<div key={idx}>{batches.amount}</div>);
                                    })}
                                </td>
                                <td className="text-center align-middle">{item.p_KodMjSkl}</td>
                                <td>
                                    {notifyItems[item.nop_IDPolozky].batches.map(function (batches, idx) {
                                        return (<div key={idx}>{batches.batch}</div>);
                                    })}
                                </td>
                                <td className="text-muted align-middle text-center">
                                    {notifyItems[item.nop_IDPolozky].batches.map(function (batches, idx) {
                                        return (<div key={idx}>{displayExpiration(batches.expiration, batches.expiryType)}</div>);
                                    })}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <Row className="mt-4">
                <Col>
                    <LinkContainer to={{ pathname: "/orders/purchase/" + kodDokladu + "/notification/2" }}>
                        <Button disabled={saving || finalResult === true}>{t('ord-back_to_second_step')}</Button>
                    </LinkContainer>
                </Col>
                <Col className="text-end"></Col>
            </Row>
            <br />
            <Row>
                <Col></Col>
                <Col xs="auto">
                    {saving ?
                        <Button disabled className="p-2" variant="info" ><h4 className="m-2">{t('ord-sending_to_wh')} ... &nbsp;&nbsp;</h4></Button>
                        :
                        <Button onClick={createNotification} disabled={saving || finalResult === true} className="p-2" variant="info" >
                            <h4 className="m-2 text-white"> <Image src="/img/warehouse.svg" height="60" />&nbsp; {t('ord-submit_sn_to_wh')} {selectedItems[0] ? selectedItems[0].nop_KodKnihySkladu : ""} </h4>
                        </Button>
                    }
                </Col>
                <Col>
                    {finalResult === null ? "" : <FinalResult finalResult={finalResult} kodDokladu={kodDokladu} reloadIt={reloadIt} errorMessage={errorMessage} />}
                </Col>
            </Row>
        </ErrorWrap>
    )
}

/**
 * 
 * @param {boolean} finalResult - default null, true if sucessfully submited
 * @param {string} kodDokladu - id of order
 * @param {function} reloadIt - goes back to order
 * @param {string} errorMessage - error message from sumbmit function fetcher
 * @returns {component}
 */
function FinalResult({ finalResult, kodDokladu, reloadIt, errorMessage }) {
    const { t } = useTranslation();

    const color = finalResult === true ? "success" : "danger";
    const message = finalResult === true ? t('successfully_sent') : t('error');
    return (
        <Alert variant={color} className="clearfix">

            <h5 className="mb-0">{message}</h5>
            <small>{errorMessage}</small>

            <br />
            <LinkContainer to={{ pathname: "/orders/purchase/" + kodDokladu }} >
                <Button onClick={reloadIt} size="sm" variant="primary" className="mb-2">{t('ord-back_to_NO')}</Button>
            </LinkContainer>

        </Alert>
    );
}

