/**
 * Detail of user.
 * @module user-detail
 * @author Lucie Zdenkova <lucie.zdenek@trustica.cz>
 */
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { his_fetch, his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import { Button } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { date_time_format } from '../lib/date-utils';
import { isFunction } from '../lib/utils';
import { Card } from 'react-bootstrap';
import FormField from './FormField';
import PasswordField from '../login-users/PasswordField';
import { Image } from 'react-bootstrap';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';
/*
import { ExcelDownloader } from '../comp/excel';
import translations from '../i18n/locales/cs/translations.json';
import translationssk from '../i18n/locales/sk/translations.json';
import translationsen from '../i18n/locales/en/translations.json';
*/

/**
 * Universal checkbox function with changeIt function used multiple times in UserProps
 * 
 * @param {string} label - checkbox label
 * @param {boolean} value - value of checkbox
 * @param {boolean} disabled - is checkbox editable
 * @param {function} onChange - function for changing value
 * @param {string} id  - controlId of checbox 
 * @returns {component}
 */
function Checkbox({ label, value, disabled, onChange, id }) {

    const changeIt = function () {
        if (isFunction(onChange)) {
            if (value === false) {
                onChange(true);
            } else {
                onChange(false);
            }
        }
    }

    return (
        <ErrorWrap>
            <Form.Group className="mb-3 d-inline-block me-5" controlId={id}>
                <Form.Check type="checkbox" onChange={changeIt} label={label} disabled={disabled} checked={value || false} />
            </Form.Group>
        </ErrorWrap>
    );
}

/**
 * User detail overview for admin - user's editable attributes, user's info from twist and current HIS sessions 
 * 
 * @param {any} userlogin 
 * @param {array} existingUsers - field of all existing users in his - to avoid users duplication 
 * @returns {component}
 */
export function UserDetail({ userlogin, existingUsers }) {
    const { t } = useTranslation();

    const { userID } = useParams();

    const [user, setUser] = useState(null);
    const [loadedStatus, setLoadedStatus] = useState(0);

    const [username, setUsername] = useState(null);
    const [usernameOrig, setUsernameOrig] = useState(null);
    const [workerCode, setWorkerCode] = useState("");
    const [workerCodeOrig, setWorkerCodeOrig] = useState("");
    const [hisMail, setHisMail] = useState(null);
    const [hisMailOrig, setHisMailOrig] = useState(null);
    const [active, setActive] = useState(null);
    const [activeOrig, setActiveOrig] = useState(null);
    const [admin, setAdmin] = useState(null);
    const [adminOrig, setAdminOrig] = useState(null);
    const [compliance, setCompliance] = useState(null);
    const [complianceOrig, setComplianceOrig] = useState(null);
    const [headCSR, setHeadCSR] = useState(null);
    const [headCSROrig, setHeadCSROrig] = useState(null);
    const [editEnabled, setEditEnabled] = useState(false);
    const [saving, setSaving] = useState(false);
    const [psswd, setPsswd] = useState("");
    const [psswd2, setPsswd2] = useState("");
    const [errorMesssage, setErrorMessage] = useState("");
    const [lang, setLang] = useState(null);
    const [langOrig, setLangOrig] = useState(null);

    const reloadIt = () => {
        setUser(null);
        setLoadedStatus(0);
        setUsername(null);
        setUsernameOrig(null);
        setWorkerCode(null);
        setWorkerCodeOrig(null);
        setHisMail(null);
        setHisMailOrig(null);
        setActive(null);
        setActiveOrig(null);
        setAdmin(null);
        setAdminOrig(null);
        setCompliance(null);
        setComplianceOrig(null);
        setHeadCSR(null);
        setHeadCSROrig(null);
        setEditEnabled(false);
        setSaving(false);
        setPsswd("");
        setPsswd2("");
        setErrorMessage("");
        setLang(null);
    }

    useEffect(() => {
        if (user === null) {
            const running_fetch = his_fetch(
                userlogin,
                [
                    {
                        uri: "/api/users/" + userID,
                        json: true,
                        status: setLoadedStatus,
                        ok: function (resource, result) {
                            //console.log(result);
                            setUser(result.user);
                            setUsername(result.user.username);
                            setUsernameOrig(result.user.username);
                            setWorkerCode(result.user.tw_kodpracovnika);
                            setWorkerCodeOrig(result.user.tw_kodpracovnika);
                            setHisMail(result.user.his_email);
                            setHisMailOrig(result.user.his_email);
                            setActive(result.user.password_set);
                            setActiveOrig(result.user.password_set);
                            setAdmin(result.user.his_superuser);
                            setAdminOrig(result.user.his_superuser);
                            setCompliance(result.user.role_compliance);
                            setComplianceOrig(result.user.role_compliance);
                            setHeadCSR(result.user.role_csr_head);
                            setHeadCSROrig(result.user.role_csr_head);
                            setLangOrig(result.user.language);
                            setLang(result.user.language);
                            //console.log(result.users);
                        },
                        error: function (resource, reason) {
                            console.log('err: ' + reason);
                            setUser("error");
                        }
                    }
                ]
            );
            return () => {
                running_fetch();
            }
        }
    }, [userlogin, userID, user]);

    if (!his_fetch_success(loadedStatus)) {
        return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />;
    }

    const existingUsername = existingUsers.includes(username);
    const invalidUsername = existingUsername && username !== usernameOrig;

    const nothingChanged = (username === usernameOrig) && (workerCode === workerCodeOrig)
        && (hisMail === hisMailOrig) && (active === activeOrig)
        && (admin === adminOrig) && (compliance === complianceOrig) && (headCSR === headCSROrig) && (lang === langOrig);

    const psswdEmpty = (psswd.length === 0) && (psswd2.length === 0);
    const psswdSame = ((psswd.length > 0 || psswd2.length > 0) && (psswd === psswd2));
    const psswdNOK = !psswdSame || psswdEmpty;
    const newPsswdNeeded = (activeOrig === false) && (active === true);
    const cannotReactivate = newPsswdNeeded && !psswdSame;

    const cannotBeSaved = (nothingChanged && psswdNOK) || cannotReactivate || invalidUsername;

    //console.log("cannotReac: " + cannotReactivate);
    // console.log("nothingChanged: " + nothingChanged);
    //console.log("psswdSame: " + psswdSame);
    // console.log("psswdEmpty: " + psswdEmpty + psswdNOK);
    // console.log("cannotBeSaved: " + cannotBeSaved);
    //console.log(lang);
    function saveIt() {
        if (!saving) {
            setSaving(true);
            setEditEnabled(false);

            var user = {}; //vyrábím slovník slovníků
            user['email'] = hisMail;
            user['role_compliance'] = compliance;
            user['role_csr_head'] = headCSR;
            user['superuser'] = admin;
            user['tw_kodpracovnika'] = workerCode === "" ? null : workerCode;
            user['username'] = username;
            user['language'] = lang;
            if (active === false) {
                user['password'] = false;
            }
            if (psswdSame) {
                user['password'] = psswd;
                user['password2'] = psswd2;
            }

            const jsonData = JSON.stringify(user);
            // console.log(user);
            //console.log(jsonData);

            his_fetch(
                userlogin,
                [
                    {
                        uri: "/api/users/" + userID,
                        json: true,
                        ok: function (resource, result) {
                            // console.log(result);
                            reloadIt();
                        },
                        error: function (resource, reason) {
                            //console.log('err: ' + reason);
                            setSaving(false);
                            reloadIt();
                            setErrorMessage(t('error'));
                        },
                        args: {
                            method: 'PUT',
                            body: jsonData
                        }
                    }
                ]
            );
        }
    }

    const user_usable = user ? user : {};

    /*
    //table of all transaltions to doownload for superusers
    const csTable = Object.keys(translations).map((row) => [row, translations[row]]);
    const csSkTable = csTable.map((row) => [...row, translationssk[row[0]]]);
    const finalTable = csSkTable.map((row) => [...row, translationsen[row[0]]]);
    */

    return (
        <ErrorWrap>
            <Button size="sm" className="me-2 mb-2" onClick={reloadIt}><Image src="/img/reload.svg" height="19" /></Button>
            <h4 className="mb-2 d-inline">{t('not-user')} {user_usable.username}
                {!editEnabled && !saving ?
                    <Button size="sm" className="mb-1 ms-4" onClick={() => setEditEnabled(true)}>{t('edit')}</Button>
                    :
                    <>
                        {saving ? <Button size="sm" className="mb-1 ms-4" disabled >{t('saving')}...</Button> :
                            <>
                                <Button size="sm" className="mb-1 ms-4" disabled={cannotBeSaved} onClick={saveIt}>{t('save')}</Button>
                                <Button size="sm" className="mb-1 ms-4" variant="danger" disabled={saving} onClick={reloadIt}>{t('discard_changes')}</Button>
                            </>
                        }
                    </>
                }
                {errorMesssage ? <span className="text-danger ms-4">{errorMesssage}</span> : ""}
            </h4>

            <Row>
                <Col md="4">
                    <UserProps userlogin={userlogin} editEnabled={editEnabled} active={active} setActive={setActive}
                        username={username} setUsername={setUsername} workerCode={workerCode} setWorkerCode={setWorkerCode}
                        hisMail={hisMail} setHisMail={setHisMail} admin={admin} setAdmin={setAdmin} compliance={compliance}
                        setCompliance={setCompliance} headCSR={headCSR} setHeadCSR={setHeadCSR} psswd={psswd}
                        setPsswd={setPsswd} setPsswd2={setPsswd2} detailView={true} newPsswdNeeded={newPsswdNeeded}
                        invalidUsername={invalidUsername} lang={lang} setLang={setLang} />
                </Col>
                <Col>
                    <BusinessCard user={user_usable} />
                </Col>
                <Col xs="4">
                    <Card body>
                        <Sessions userID={userID} userlogin={userlogin} />
                    </Card>
                    {/**  Table of all laguages for development purposes
                    {userlogin.userinfo.his_superuser ?
                        <Card body className='mt-2'>
                            <h5>Languages Table</h5>
                            <ExcelDownloader tableData={finalTable} fileName={"languages-his.xlsx"} />
                        </Card>
                        : ""}
                        */}
                </Col>
            </Row>
        </ErrorWrap>
    )
}

/**
 * Form for editing user attributes - username, code, mail, roles
 * 
 * @param {*} userlogin - current user info
 * @param {boolean} editEnabled - editing is allowed
 * @param {boolean} active - is user active in HIS?
 * @param {function} setActive - setting active hook
 * @param {string} username - username
 * @param {function} setUsername - setting username hook
 * @param {string} workerCode - code of worker
 * @param {function} setWorkerCode - setting workerCode hook
 * @param {string} hisMail - email used for loggin into HIS
 * @param {function} setHisMail - setting email
 * @param {boolean} admin - has user admin role?
 * @param {function} setAdmin - setting admin role
 * @param {boolean} compliance - has user compliance role?
 * @param {function} setCompliance - setting compliance role
 * @param {boolean} headCSR - has user headCSR role?
 * @param {function} setHeadCSR  - setting headCSR role
 * @param {string} psswd - user's password
 * @param {function} setPsswd - setting new password
 * @param {function} setPsswd2 - setting new password again
 * @param {boolean} mandatory - is filed mandatory? - its mandatory when creating new user, optional when editing existing user
 * @param {boolean} detailView - are we loogin at it in user-detail? then we show info, whether user is active
 * @param {boolean} newPsswdNeeded - if new password setting is mandatory - when we reactivating user
 * @param {boolean} invalidUsername - result of username validation
 * @returns {component}
 */
export function UserProps({ userlogin, editEnabled, active, setActive, username, setUsername, workerCode, setWorkerCode, hisMail, setHisMail, admin, setAdmin,
    compliance, setCompliance, headCSR, setHeadCSR, psswd, setPsswd, setPsswd2, mandatory, detailView, newPsswdNeeded, invalidUsername,
    lang, setLang }) {
    const { t } = useTranslation();

    const [twistCodes, setTwistCodes] = useState(null);

    useEffect(() => {
        if (twistCodes === null) {
            const running_fetch = his_fetch(
                userlogin,
                [
                    {
                        uri: "/api/users/twist",
                        json: true,
                        //    status: setLoadedStatus,
                        ok: function (resource, result) {
                            //console.log(result);
                            setTwistCodes(result.twistUsers);
                        },
                        error: function (resource, reason) {
                            console.log('err: ' + reason);
                            setTwistCodes("error");
                        }
                    }
                ]
            )
            return () => {
                running_fetch();
            }
        }
    }, [userlogin, twistCodes]);

    //console.log(twistCodes);

    const newpasswordChanged = (target) => {
        setPsswd(target.value);
    }

    const newpassword2Changed = (target) => {
        setPsswd2(target.value);
    }

    const validatePassword2Change = (value) => {
        const psswd2 = value;
        if ((psswd2.length > 0) && (psswd === psswd2)) {
            // setAllowSubmit(true);
            return true;
        }
        // setAllowSubmit(false);
        throw new Error(t('passwd_dont_match'));
    }

    function changeWorkerCode(ev) {
        setWorkerCode(ev.target.value);
    }

    const labelUsername = mandatory ? t('sys-username') + " " + t('mandatory') : t('sys-username');
    const LabelMail = mandatory ? t('sys-his_mail') + " " + t('mandatory') : t('sys-his_mail');
    const labelPasswd = mandatory || newPsswdNeeded ? t('sys-new_psswd') + " " + t('mandatory') : t('sys-new_psswd');
    const labelPasswd2 = mandatory || newPsswdNeeded ? t('sys-new_psswd2') + " " + t('mandatory') : t('sys-new_psswd2');
    const labelWorkerCode = mandatory ? t('sys-worker_code') + " " + t('mandatory') : t('sys-worker_code');

    //console.log(userlogin);
    //console.log(username);

    return (
        <ErrorWrap>
            <Card body>
                {detailView ? <Checkbox label="Aktivní" value={active} disabled={!editEnabled} onChange={setActive} id="active" /> : ""}
                <Form>
                    <Row>
                        <Col>
                            <p style={{ marginBottom: "8px" }}>{labelUsername}</p> {/* this prevents browsers from autofill, cuz they officially ignore autoComplete="off" */}
                            <Form.Group className="mb-3" controlId="1" >
                                <Form.Control type="text" autoComplete='new-password' value={username || ""} onChange={(ev) => setUsername(ev.target.value)} disabled={!editEnabled} isInvalid={invalidUsername} />
                                <Form.Control.Feedback type="invalid">{t('username_exists_choose_different')}.</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            <p className="mb-2">{labelWorkerCode}</p>
                            <Form.Control as="select" value={workerCode || ""} onChange={changeWorkerCode} disabled={!editEnabled}>
                                {workerCode ?
                                    <option value={workerCode}>{workerCode}</option>
                                    :
                                    <option disabled value="">{t('choose')}</option>}
                                {(twistCodes || []).map(function (code) {
                                    return <option key={code} value={code}>{code}</option>
                                })}
                            </Form.Control>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p style={{ marginBottom: "8px" }}>{LabelMail}</p> {/* this prevents browsers from autofill, cuz they officially ignore autoComplete="off" */}
                            <Form.Group className="mb-3" controlId="2"  >
                                <Form.Control autoComplete='new-password' type="text" value={hisMail || ""} onChange={(ev) => setHisMail(ev.target.value)} disabled={!editEnabled} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <p className="mb-2">{t('sys-language')}</p>
                            <Form.Control as="select" value={lang} onChange={(ev) => setLang(ev.target.value)} disabled={!editEnabled}>
                                <option value='cs'>česky</option>
                                <option value='sk'>slovenski</option>
                                <option value='en'>english</option>
                            </Form.Control>
                        </Col>
                    </Row>
                    <h5 className="mt-4 mb-2">{t('sys-roles')}</h5>
                    <Checkbox label={t('sys-admin')} value={admin} disabled={!editEnabled} onChange={setAdmin} id="admin" />
                    <Checkbox label={t('sys-compliance')} value={compliance} disabled={!editEnabled} onChange={setCompliance} id="compliance" />
                    <Checkbox label={t('sys-CSR_manager')} value={headCSR} disabled={!editEnabled} onChange={setHeadCSR} id="headCSR" />
                    {editEnabled ?
                        <>
                            <h5 className="mt-4 mb-2">{t('sys-new_psswd_set')}</h5>
                            <PasswordField fieldId="newpassword" placeholder="" label={labelPasswd} onStateChanged={newpasswordChanged} thresholdLength={7} minStrength={3} required />
                            <FormField fieldId="newpassword2" validator={validatePassword2Change} onStateChanged={newpassword2Changed} label={labelPasswd2} placeholder="" type="password" required />
                        </> : ""}
                </Form>
            </Card>
        </ErrorWrap>
    )
}

/**
 * Card with current sessions of an user with button for logout.
 * 
 * @param {any} userlogin - current user info
 * @param {number} userID - id of displayed user
 * @returns {component}
 */
function Sessions({ userlogin, userID }) {
    const { t } = useTranslation();

    const [sessions, setSessions] = useState(null);
    const [loadedStatus, setLoadedStatus] = useState(0);
    const [saving, setSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const reloadIt = () => {
        setSessions(null);
        setLoadedStatus(0);
        setSaving(false);
    }


    useEffect(() => {
        if (sessions === null) {
            const running_fetch = his_fetch(
                userlogin,
                [
                    {
                        uri: "/api/users/" + userID + "/sessions",
                        json: true,
                        status: setLoadedStatus,
                        ok: function (resource, result) {
                            //console.log(result);
                            setSessions(result.sessions);
                            //console.log(result.users);
                        },
                        error: function (resource, reason) {
                            console.log('err: ' + reason);
                            setSessions("error");
                        }
                    }
                ]
            )
            return () => {
                running_fetch();
            }
        }
    }, [userlogin, userID, sessions]);

    if (!his_fetch_success(loadedStatus)) {
        return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />;
    }

    function logout() {
        if (!saving) {
            setSaving(true);

            his_fetch(
                userlogin,
                [
                    {
                        uri: "/api/users/" + userID + "/force-logout",
                        json: true,
                        ok: function (resource, result) {
                            //console.log(result);
                            if (result.result === "ok") {
                                reloadIt();
                            } else {
                                setErrorMessage(t('error'));
                            }

                        },
                        error: function (resource, reason) {
                            console.log('err: ' + reason);
                            setSaving(false);
                            reloadIt();
                            setErrorMessage(t('error'));
                        },
                    }
                ]
            );
        }
    }
    //console.log(sessions);

    var idx = 0;
    return (
        <ErrorWrap>
            <h5 className="mb-3">{t('current_login')}</h5>
            <Table size="sm" borderless>
                <thead>
                    <tr>
                        <th className="text-end">{t('login_time')}</th>
                        <th className="text-end">{t('last_activity')}</th>
                    </tr>
                </thead>
                <tbody>
                    {(sessions || []).map(function (ss) {
                        idx += 1;
                        return (
                            <tr key={idx}>
                                <td className="text-end">{ss.created ? date_time_format(ss.created) : ""}</td>
                                <td className="text-end">{ss.activity ? date_time_format(ss.activity) : ""}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            {saving ?
                <Button className="float-end mt-3" size="sm" variant="warning" disabled>{t('logging_out')} ... </Button>
                :
                <Button className="float-end mt-3" size="sm" variant="warning" disabled={sessions[0].activity === null} onClick={logout}>{t('logout_user_everywhere')}</Button>
            }
            <br />
            <br />
            <p className="text-end text-danger">{errorMessage ? errorMessage : ""}</p>
        </ErrorWrap>
    )
}

/**
 * Just personal user information displayed as business card
 * 
 * @param {any} user - user information from userlogin
 * @returns {component}
 */
export function BusinessCard({ user }) {
    const { t } = useTranslation();
    return (
        <Card body>
            <div className="text-center mb-3">
                <h4> {user.Titul} {user.Jmeno} </h4>
                <hr className="m-0" />
                {user.Pozice}
            </div>
            <Row>
                <Col xs="4" className="text-muted text-end">{t('biz-centre_code')}</Col>
                <Col>{user.KodStrediska}</Col>
            </Row>
            <Row>
                <Col xs="4" className="text-muted text-end">{t('email')}</Col>
                <Col>{user.Email}</Col>
            </Row>
            <Row>
                <Col xs="4" className="text-muted text-end">{t('biz-phon')}</Col>
                <Col>{user.Telefon}</Col>
            </Row>
            <Row>
                <Col xs="4" className="text-muted text-end">{t('biz-mobile')}</Col>
                <Col>{user.mobil}</Col>
            </Row>
        </Card>
    )
}