/**
 * Detail of an e-mail.
 * @module email-detail
 * @author Lucie Zdenkova <lucie.zdenek@trustica.cz>
 */

import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { his_fetch, his_fetch_success, HisFetchStatus } from './comp/FetchLoader';
import { date_time_format } from './lib/date-utils';
import { Row, Col, ListGroup, Card, Badge } from 'react-bootstrap';
import { ErrorWrap } from './comp/errorwrap';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser'
import { AttachmentDownloader } from './comp/downloader';

export function EmailDetail({ userlogin }) {
    const { t } = useTranslation();

    const { code } = useParams();

    const [loadedStatus, setLoadedStatus] = useState(0);
    const [email, setEmail] = useState(null);
    const [struct, setStruct] = useState(null);

    //new style: začínám na null a v error funkci nastavuji na "error", viz. threads

    const reloadIt = () => {
        setEmail(null);
    }

    useEffect(() => {
        if (email === null) {
            const running_fetch = his_fetch(
                userlogin,
                [
                    {
                        uri: "/api/email/view/" + code,
                        json: true,
                        status: setLoadedStatus,
                        ok: function (resource, result) {
                            //console.log(result);
                            setEmail(result.email);
                            // console.log(email);
                            setStruct(result.struct);
                        },
                        error: function (resource, reason) {
                            console.log('err: ' + reason);
                            setEmail("error");
                        }
                    }
                ]
            );
            return () => {
                running_fetch();
            }
        }
    }, [userlogin, email, code]);

    if (!his_fetch_success(loadedStatus)) {
        return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />;
    }

    return (
        <ErrorWrap>
            <ListGroup variant="flush">
                <ListGroup.Item>
                    <Row>
                        <Col><span className="text-muted">{t('sys-from')}: </span> {email.from}</Col>
                        <Col><span className="text-muted">{t('date')}: </span>{date_time_format(email.sent)}</Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Row>
                        <Col>
                            <Row className='g-0'>
                                <Col xs="auto" className="me-1"><span className="text-muted">{t('sys-to_pl')}: </span></Col>
                                <Col>{email.to.map((a) => <React.Fragment key={a}>{a}<br /></React.Fragment>)}</Col>
                            </Row>
                        </Col>
                        <Col><span className="text-muted">ID: </span>{email.id}</Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Row>
                        <Col xs="6"><span className="text-muted">{t('sys-subject')}: </span>{email.subject}</Col>
                        <Col xs="3"><span className="text-muted">{t('sys-cc')}: </span>{email.cc.map((a) => <React.Fragment key={a}>{a}<br /></React.Fragment>)}</Col>
                        <Col xs="3"><span className="text-muted">{t('sys-bcc')}: </span>{email.bcc.map((a) => <React.Fragment key={a}>{a}<br /></React.Fragment>)}</Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>

                    <Row>
                        <Col>
                            <Entity entity={struct.entity} />
                        </Col>
                        <Col>
                            <pre>
                                {email.mime}
                            </pre>
                        </Col>
                    </Row>
                </ListGroup.Item>
            </ListGroup>
        </ErrorWrap>
    );
}

/**
 * Displays body of email based on type and subtype
 * 
 * @param {any} entity - dictionary with email mime content 
 * @returns {component}
 */
function Entity({ entity }) {

    const text = entity.type === "text";
    const multipart = entity.type === "multipart";
    const application = entity.type === "application";
    if (text) {
        const plain = entity.subtype === "plain";
        const html = entity.subtype === "html";
        if (plain) {
            return <EmailPartCard entity={entity}
                content={<pre>{entity.body}</pre>}
            />;
        } else if (html) {
            return <EmailPartCard entity={entity}
                content={parse(entity.body.replace('\n', '<br/><br/>'))}
            />;
        } else {
            return <EmailPartCard entity={entity} content="unknown" />;
        }
    } else if (multipart) {
        const alternative = entity.subtype === "alternative";
        const mixed = entity.subtype === "mixed";
        if (alternative || mixed) {
            return <EmailPartCard entity={entity}
                content={entity.parts.map((part, idx) => <Entity key={idx} entity={part.entity} />)}
            />;
        } else {
            return <EmailPartCard entity={entity} content="unknown" />;
        }
    } else if (application) {
        const supportedTypes = entity.subtype === "pdf" || entity.subtype === "vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        if (supportedTypes) {
            return <EmailPartCard entity={entity}
                content={<AttachmentDownloader data={entity.body} subtype={entity.subtype} alt="" height="30" filename={entity.disposition.filename} />}
            />;
        } else {
            return <EmailPartCard entity={entity} content="unknown" />;
        }
    } else {
        return <EmailPartCard entity={entity} content="unknown" />;
    }
}

/**
 * Single entity Card
 * 
 * @param {any} entity 
 * @param {any} content
 * @returns {component}
 */
function EmailPartCard({ entity, content }) {
    const { t } = useTranslation();

    return <Card body className='my-2'>
        <p className='mb-4'>
            <Badge className='float-end fw-normal' bg="light" text="dark">
            {entity.type}/{entity.subtype}
        </Badge>
        </p>
        {content === "unknown" ?
            <>{t('cannot_display_unknown_type')} : {String(entity.type)}/{String(entity.subtype)}</>
            :
            content
        }
    </Card>;
}