/**
 * Button for downloading files.
 * @module comp/downloader
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React, { useState } from 'react';
import { fetcher2 } from '../lib/fetcher';
import { Loading } from './loading';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';

//seen all - OK

/** 
 * Button allowing download file
 *  
 * @param {string} img - path of image of the file
 * @param {string} alt - alternative text if the image is missing
 * @param {number} height - height of the image
 * @param {string} filename - name of the downloaded file
 * @param {string} message - message while loading, default is "Příprava stahování..."
 * @param {string} label - text for the button
 * @param {string} path - api path for the file
 * @param {string} failMess - message if download fails, default is "Selhání sítě."
 * @return {component} - Button with downloading funtion and errorhandling
 */

// <Downloader img="/application-pdf.svg" alt="pdf" label="CZ" path="/api/..." filename="..." />
export function Downloader({ img, alt, height, filename, message = "loading_preparation", label,
	path, failMess = "network_fail" }) {
	const { t } = useTranslation();

	const [isLoading, setIsLoading] = useState(false);
	const [fetcherFail, setFetcherFail] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	function doDownload() {
		setIsLoading(true);
		setFetcherFail(false);
		fetcher2(path,
			function (result) {
				if (result.ok) {
					result.blob().then(
						function (blob) {
							setIsLoading(false);
							//následujících 5 řádek vzbudí ten dialog uložit jako, nícméně musím vyrobit funkční blob z toho body, co dostávám jako body v tom mailu
							let url = window.URL.createObjectURL(blob); //následující řádky spouští download metodu, 
							let a = document.createElement('a');
							a.href = url;
							a.download = filename;
							a.click();
							// console.log("result.ok: " + result.ok);
						}
					);
				} else {
					result.text().then(
						function (error) {
							console.log('error: ' + error);
							const obj = JSON.parse(error);
							//console.log('parsovaný: ' + obj.error);
							setErrorMessage(obj.error);
							setFetcherFail(true);
							//console.log("fetcherFail0: " + fetcherFail);
							//console.log("isLoading0: " + isLoading);
						});
				}
			},
			function (error) {
				console.log('error- ' + error);
				setFetcherFail(true);
				console.log("fetcherFail: " + fetcherFail);
				console.log("isLoading: " + isLoading);
			});
	}

	if (isLoading) {
		return (
			<div className="d-inline-block align-middle">
				{fetcherFail ?
					<span className='btn my-alert' onClick={doDownload}><span className='align-middle'>
						<small>{errorMessage ? t('error') + ": " + errorMessage : t(failMess)}</small>
					</span>
						<Button size="sm" className='myGrey' >{t('try_again')}</Button>
					</span>
					:
					<Loading message={message} margin="0" />
				}
			</div>
		);
	} else {
		return (
			<Button size="sm" className='myGrey' onClick={doDownload} ><img src={img} alt={alt} height={height} />&nbsp;{label}</Button>
		);
	}
}

/**
 * Returns correct icon src according to file type
 * 
 * @param {string} type - type of file
 * @returns {string}
 */
function attachment_img(type) {
	if (type === "pdf") {
		return "/application-pdf.svg";
	} else if (type === "vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
		return "/application-excel.svg";
	} else {
		return "";
	}
}

/** 
 * Button allowing download file in case of email attachment
 *  
 * @param {string} data - file data from response
 * @param {string} subtype - type of file - now we accept 
 * @param {string} alt - alternative text if the image is missing
 * @param {number} height - height of the image
 * @param {string} filename - name of the downloaded file
 * @return {component} - Button with downloading funtion and errorhandling
 */

// <Downloader img="/application-pdf.svg" alt="pdf" label="soubor.pdf" />
export function AttachmentDownloader({ data, subtype, height, filename }) {

	function doDownload() {
		const linkSource = `data:application/${subtype};base64,${data}`;
		const downloadLink = document.createElement("a");
		downloadLink.href = linkSource;
		downloadLink.download = filename;;
		downloadLink.click();
	}

	return (
		<Button size="sm" className='myGrey' onClick={doDownload} ><img src={attachment_img(subtype)} alt="img" height={height} />&nbsp;{filename}</Button>
	);
}