
/**
* List of orders purchase with filtering
* @module orders/orders-purchase
* @author Lucie Zdeňková <lucie.zdenek@trustica.cz>
*/

import React, { useState, useCallback } from 'react';
import { Table, Row, Col, Form, Dropdown, DropdownButton, Card } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { filter_rule, icompare } from '../lib/utils';
import { format_amount } from '../lib/format';
import { date_formatCZ } from '../lib/date-utils';
import { Boolean } from '../comp/boolean';
import { his_fetch } from '../comp/FetchLoader';
import { ErrorWrap } from '../comp/errorwrap';
import { DynamicLoad, LoadingDataInfo } from '../comp/dynamic-load';
import { useTranslation } from 'react-i18next';
import { currencyTitles } from '../lists/currency_titles';
import { BooleanDropdown } from '../comp/boolean';
import { SortIcons } from '../comp/sort';

/**
 * Returns list of orders purchase
 * 
 * @param {any} userlogin - info about logged user
 * @returns {component}
 */
export function OrdersPurchase({ userlogin }) {

	const [orders, setOrders] = useState([]);
	const [totalCount, setTotalCount] = useState(null);
	const [oldest, setOldest] = useState(null);
	const [offset, setOffset] = useState(0);
	const [filterCode, setFilterCode] = useState("");
	const [filterPartner, setFilterPartner] = useState("");
	const [filterNote, setFilterNote] = useState("");
	const [loadedStatus, setLoadedStatus] = useState(0);
	const [massFilterApp, setMassFilterApp] = useState("");
	const [filterAmount, setFilterAmount] = useState("");
	const [filterCurrency, setFilterCurrency] = useState("");
	const [filterClosed, setFilterClosed] = useState(null);
	const [filterAccepted, setFilterAccepted] = useState("");
	const [filterInvoiced, setFilterInvoiced] = useState("");
	const [sortValue, setSortValue] = useState(null);
	const [direction, setDirection] = useState("up");
	const [type, setType] = useState("");

	const loadData = useCallback(function (startDate, endDate, result_received, reload = false) {
		return (
			his_fetch(
				userlogin,
				[
					{
						uri: "/api/orders/purchase/range/" + startDate + "/" + endDate,
						json: true,
						status: setLoadedStatus,
						ok: function (resource, result) {
							//console.log(result);
							if (result.error) {
								result_received(false);
							} else {
								setOrders(ordersCat => (reload ? [] : ordersCat).concat(result.result));
								setTotalCount(result.total_count);
								setOldest(result.oldest[2]);
								result_received(true);
							}
						},
						error: function (resource, reason) {
							console.log('err: ' + reason);
							result_received(false);
						}
					}
				]
			))
	}, [userlogin])

	const cleanFilters = () => {
		setFilterCode("");
		setFilterPartner("");
		setFilterNote("");
		setMassFilterApp("");
		setFilterAmount("");
		setFilterCurrency("");
		setSortValue(null);
		setDirection("up");
	}

	const handleChangeCode = function (event) {
		setFilterCode(event.target.value);
		setOffset(0);
	}
	const handleChangePartner = function (event) {
		setFilterPartner(event.target.value);
		setOffset(0);
	}
	const handleChangeNote = function (event) {
		setFilterNote(event.target.value);
		setOffset(0);
	}
	const handleChangeFilterApp = function (event) {
		setMassFilterApp(event.target.value);
		setOffset(0);
	}

	const orders_filtered = orders.filter(
		function (ord) {
			return (
				filter_rule(filterCode, ord[0], true) &&
				filter_rule(filterPartner, ord[1], true) &&
				filter_rule(filterNote, ord[3], true) &&
				(filter_rule(massFilterApp, ord[0], true) ||
					filter_rule(massFilterApp, ord[1], true) ||
					filter_rule(massFilterApp, ord[3], true)) &&
				icompare(ord[4], filterAmount) &&
				((filterCurrency === "") || (ord[5] === filterCurrency)) &&
				((filterClosed === null) ||
					(filterClosed === ord[6])) &&
				icompare(ord[7], filterAccepted) &&
				icompare(ord[8], filterInvoiced)
			);
		});


	const orders_sorted = orders_filtered.sort(function (a, b) {
		if (type === "letters") {
			if (direction === "up") {
				var nameA = a[sortValue] ? a[sortValue].toUpperCase() : "žžžžž";
				var nameB = b[sortValue] ? b[sortValue].toUpperCase() : "žžžžž";
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}
				return 0;
			}
			if (direction === "down") {
				var nameAA = a[sortValue] ? a[sortValue].toUpperCase() : "žžžžž";
				var nameBB = b[sortValue] ? b[sortValue].toUpperCase() : "žžžžž";
				if (nameAA > nameBB) {
					return -1;
				}
				if (nameAA < nameBB) {
					return 1;
				}
				return 0;
			}
		}
		if (type === "num") {
			if (direction === "up") {
				return new Date(a[sortValue]) - new Date(b[sortValue]);
			}
			if (direction === "down")
				return new Date(b[sortValue]) - new Date(a[sortValue]);
		}
		return 0;
	});


	const show_orders = orders_sorted.slice(offset, offset + 20);

	return (
		<ErrorWrap>
			<DynamicLoad period='1y' loadData={loadData}
				filtered_data={orders_filtered} data={orders}
				offset={offset} setOffset={setOffset}
				totalCount={totalCount} oldest={oldest}
				cleanFilters={cleanFilters}
			/>
			<OPWeb show_orders={show_orders}
				setOffset={setOffset}
				filterCode={filterCode}
				handleChangeCode={handleChangeCode}
				filterPartner={filterPartner}
				handleChangePartner={handleChangePartner}
				filterNote={filterNote}
				handleChangeNote={handleChangeNote}
				filterAmount={filterAmount}
				setFilterAmount={setFilterAmount}
				filterCurrency={filterCurrency}
				setFilterCurrency={setFilterCurrency}
				filterClosed={filterClosed}
				setFilterClosed={setFilterClosed}
				filterAccepted={filterAccepted}
				setFilterAccepted={setFilterAccepted}
				filterInvoiced={filterInvoiced}
				setFilterInvoiced={setFilterInvoiced}
				sortValue={sortValue}
				setSortValue={setSortValue}
				direction={direction}
				setDirection={setDirection}
				setType={setType}
			/>
			<OPApp
				show_orders={show_orders}
				massFilterApp={massFilterApp}
				handleChangeFilterApp={handleChangeFilterApp}
				setOffset={setOffset}
				filterAmount={filterAmount}
				setFilterAmount={setFilterAmount}
				filterCurrency={filterCurrency}
				setFilterCurrency={setFilterCurrency}
				filterClosed={filterClosed}
				setFilterClosed={setFilterClosed}
				filterAccepted={filterAccepted}
				setFilterAccepted={setFilterAccepted}
				filterInvoiced={filterInvoiced}
				setFilterInvoiced={setFilterInvoiced}
				sortValue={sortValue}
				setSortValue={setSortValue}
				direction={direction}
				setDirection={setDirection}
				setType={setType}
			/>
			<LoadingDataInfo loadedStatus={loadedStatus} data={orders} />
		</ErrorWrap>
	)
}

/**
 * Return list of orders purchase with filters for web.
 * 
 * @param {any} show_orders - list of orders to show on one page
 * @param {string} filterCode - state for code filter
 * @param {function} handleChangeCode - function for code filter
 * @param {string} filterPartner - state for partner filter
 * @param {function} handleChangePartner - function for partner filter
 * @param {string} filterNote - state for note filter
 * @param {function} handleChangeNote - function for note filter
 * @param {string} filterAmount - state for amount filter
 * @param {function} setFilterAmount - function for amount filter
 * @param {string} filterCurrency - state for currency filter
 * @param {function} setFilterCurrency - function for currency filter
 * @param {string} filterClosed - state for closed filter
 * @param {function} setFilterClosed - function for closed filter
 * @param {string} filterAccepted - state for accepted filter
 * @param {function} setFilterAccepted - function for accepted filter
 * @param {string} filterInvoiced - state for invoices filter
 * @param {function} setFilterInvoiced - function for invoiced filter
 * @param {number} sortValue - currently valid value for sorting
 * @param {function} setSortValue - changes sorting value
 * @param {string} direction - direction of sorting - up/down
 * @param {function} setDirection - function to change direction of sorting
 * @param {function} setType - function to change type of sorting - letters/num
 * @param {function} setOffset - changes start of pagination
 * @returns {component}
 */
function OPWeb({ show_orders, filterCode, handleChangeCode, filterPartner, handleChangePartner, filterNote, handleChangeNote,
	setOffset, filterAmount, setFilterAmount, filterCurrency, setFilterCurrency, filterClosed, setFilterClosed,
	filterAccepted, setFilterAccepted, filterInvoiced, setFilterInvoiced,
	sortValue, setSortValue, direction, setDirection, setType
}) {
	const { t } = useTranslation();
	//console.log(direction);
	//console.log(sortValue);
	return (
		<div className="d-none d-lg-block">
			<Table hover size="sm" striped >
				<thead className="beGray">
					<tr>
						<th className="pb-2">
							<Form.Label className="pb-1">
								<p className="mb-2">{t('doc_code')}</p>
								<SortIcons name={0} sortValue={sortValue} setSortValue={setSortValue}
									direction={direction} setDirection={setDirection} setType={setType}
								/>
							</Form.Label>
							<Form.Group controlId="filterCode">
								<Form.Control type="text"
									placeholder={"🔍 "}
									value={filterCode}
									onChange={handleChangeCode} />
							</Form.Group>

						</th>
						<th className="pb-2">
							<Form.Group controlId="filterPartner">
								<Form.Label>{t('biz-partner_code')}</Form.Label>
								<Form.Control type="text"
									placeholder={"🔍 "}
									value={filterPartner}
									onChange={handleChangePartner} />
							</Form.Group>
						</th>
						<th width="8%" className="pb-3">
							<p className='mb-2 pb-1'>{t('date')}</p>
							<SortIcons name={2} sortValue={sortValue} setSortValue={setSortValue}
								direction={direction} setDirection={setDirection} setType={setType} numeric
							/></th>
						<th className="pb-2">
							<Form.Group controlId="filterNote">
								<Form.Label>{t('note')}</Form.Label>
								<Form.Control type="text"
									placeholder={"🔍 "}
									value={filterNote}
									onChange={handleChangeNote} />
							</Form.Group>
						</th>
						<th className="pb-2 text-end">
							<Form.Group controlId="filterAmount">
								<Form.Label>{t('biz-amount_money')}</Form.Label>
								<Form.Control type="text"
									placeholder="&#128269; > < ="
									value={filterAmount} onChange={(ev) => { setFilterAmount(ev.target.value); setOffset(0); }}
								/>
							</Form.Group>
						</th>
						<th className="pb-2">
							<p className="mb-2">{t('biz-currency')}</p>
							<DropdownButton id="filterCurrency" title={t(currencyTitles[filterCurrency])} variant="light">
								{Object.keys(currencyTitles)
									.map((title, idx) =>
										<Dropdown.Item key={idx} onClick={() => { setFilterCurrency(title); setOffset(0); }} >{t(currencyTitles[title])}</Dropdown.Item>
									)}
							</DropdownButton>
						</th>
						<th className="pb-2">
							<p className='mb-2'>{t('biz-closed')}</p>
							<BooleanDropdown onChange={setFilterClosed} value={filterClosed} />
						</th>
						<th className="pb-2 text-end">
							<Form.Group controlId="filterAccepted">
								<Form.Label>{t('sys-accepted')}</Form.Label>
								<Form.Control type="text"
									placeholder="&#128269; > < ="
									value={filterAccepted} onChange={(ev) => { setFilterAccepted(ev.target.value); setOffset(0); }}
								/>
							</Form.Group>
						</th>
						<th className="pb-2 text-end">
							<Form.Group controlId="filterInvoiced">
								<Form.Label>{t('biz-invoiced')}&nbsp;%</Form.Label>
								<Form.Control type="text"
									placeholder="&#128269; > < ="
									value={filterInvoiced} onChange={(ev) => { setFilterInvoiced(ev.target.value); setOffset(0); }}
								/>
							</Form.Group>
						</th>
					</tr>
				</thead>
				<tbody>
					{
						show_orders.map(
							function (ord) {
								return (
									<LinkContainer to={{ pathname: "/orders/purchase/" + ord[0] }}
										key={ord[0]}>
										<tr key={ord[0]}>
											<td>{ord[0]}</td>
											<td>{ord[1]}</td>
											<td>{date_formatCZ(ord[2])}</td>
											<td>{ord[3]}</td>
											<td className="text-end">{format_amount(ord[4])}</td>
											<td>{ord[5]}</td>
											<td className="text-center"><Boolean value={ord[6]} variant="onlyTrue" /></td>
											<td className="text-end">{ord[7]}</td>
											<td className="text-end">{ord[8]}&nbsp;%</td>
										</tr>
									</LinkContainer>
								);
							}
						)
					}
				</tbody>
			</Table>
		</div>
	)
}

/**
 * Returns list of orders purchase for mobile with merged filter
 * 
 * @param {any} show_orders - list of orders to show on one page
 * @param {string} massFilterApp - state for filter
 * @param {function} handleChangeFilterApp - function for filter
 * @param {function} setOffset - changes start of pagination
 * @param {string} filterAmount - state for amount filter
 * @param {function} setFilterAmount - function for amount filter
 * @param {string} filterCurrency - state for currency filter
 * @param {function} setFilterCurrency - function for currency filter
 * @param {string} filterClosed - state for closed filter
 * @param {function} setFilterClosed - function for closed filter
 * @param {string} filterAccepted - state for accepted filter
 * @param {function} setFilterAccepted - function for accepted filter
 * @param {string} filterInvoiced - state for invoices filter
 * @param {function} setFilterInvoiced - function for invoiced filter
 * @param {number} sortValue - currently valid value for sorting
 * @param {function} setSortValue - changes sorting value
 * @param {string} direction - direction of sorting - up/down
 * @param {function} setDirection - function to change direction of sorting
 * @param {function} setType - function to change type of sorting - letters/num
 * @returns {component}
 */

function OPApp({ show_orders, massFilterApp, handleChangeFilterApp, setOffset,
	filterAmount, setFilterAmount, filterCurrency, setFilterCurrency, filterClosed,
	setFilterClosed, filterAccepted, setFilterAccepted, filterInvoiced,
	setFilterInvoiced, sortValue, setSortValue, direction, setDirection, setType }) {
	const { t } = useTranslation();

	return (
		<div className="d-lg-none">
			<br />
			<Card className='m-0 p-2 mb-2'>
				<Card.Body className='m-0 p-0'>
					<Row className='mb-2'>
						<Col>
							<p className="mb-0 bolder">{t('doc_code')}</p>
							<SortIcons name={0} sortValue={sortValue} setSortValue={setSortValue}
								direction={direction} setDirection={setDirection} setType={setType}
							/>

						</Col>
						<Col>
							<p className='mb-0 bolder'>{t('date')}</p>
							<SortIcons name={2} sortValue={sortValue} setSortValue={setSortValue}
								direction={direction} setDirection={setDirection} setType={setType} numeric
							/>
						</Col>
					</Row>

					<p className="mb-1">{t('mass_filter')}</p>
					<Form.Group controlId="filterCodex" className='mb-2'>
						<Form.Control type="text" placeholder={"🔍 " + t('filter_doc_code_note_partner')} value={massFilterApp} onChange={handleChangeFilterApp} />
					</Form.Group>

					<Row>
						<Col>
							<Form.Group controlId="filterAmount">
								<Form.Label className='mb-1'>{t('biz-amount_money')}</Form.Label>
								<Form.Control type="text"
									placeholder="&#128269; > < ="
									value={filterAmount} onChange={(ev) => { setFilterAmount(ev.target.value); setOffset(0); }}
								/>
							</Form.Group>
						</Col>
						<Col xs="auto">
							<p className="mb-1">{t('biz-currency')}</p>
							<DropdownButton id="filterCurrency" title={t(currencyTitles[filterCurrency])} variant="light">
								{Object.keys(currencyTitles)
									.map((title, idx) =>
										<Dropdown.Item key={idx} onClick={() => { setFilterCurrency(title); setOffset(0); }} >{t(currencyTitles[title])}</Dropdown.Item>
									)}
							</DropdownButton>
						</Col>

					</Row>
					<Row className='mt-2'>

						<Col>
							<p className='mb-1'>{t('biz-closed')}</p>
							<BooleanDropdown onChange={setFilterClosed} value={filterClosed} />
						</Col>
						<Col>
							<Form.Group controlId="filterAccepted">
								<Form.Label className='mb-1'>{t('sys-accepted')}</Form.Label>
								<Form.Control type="text"
									placeholder="&#128269; > < ="
									value={filterAccepted} onChange={(ev) => { setFilterAccepted(ev.target.value); setOffset(0); }}
								/>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group controlId="filterInvoiced">
								<Form.Label className='mb-1'>{t('biz-invoiced')}&nbsp;%</Form.Label>
								<Form.Control type="text"
									placeholder="&#128269; > < ="
									value={filterInvoiced} onChange={(ev) => { setFilterInvoiced(ev.target.value); setOffset(0); }}
								/>
							</Form.Group>
						</Col>

					</Row>
				</Card.Body>
			</Card>

			<Table striped>
				<tbody>
					{show_orders.map(function (ord) {
						return (
							<LinkContainer to={{ pathname: "/orders/purchase/" + ord[0] }}
								key={ord[0]}>
								<tr>
									<td>
										<Row className="g-0">
											<Col className="beBigger me-1">{ord[0]}</Col>
											<Col className="text-end align-bottom">{format_amount(ord[4])} {ord[5]}</Col>
										</Row>
										<Row>
											<Col>{ord[1]}</Col>
											<Col className="text-end">{t('biz-closed')}: <Boolean value={ord[6]} variant="onlyTrue" /></Col>
										</Row>
										<Row>
											<Col>{date_formatCZ(ord[2])}</Col>
											<Col className="text-end">{t('sys-accepted')}: {ord[7]}</Col>
										</Row>
										<Row>
											<Col>{t('note_short')}: {ord[3]}</Col>
											<Col xs="auto" className="text-end">{t('biz-invoiced')}: {ord[8]}&nbsp;%</Col>
										</Row>
									</td>
								</tr>
							</LinkContainer>
						);
					})
					}
				</tbody>
			</Table>
		</div>
	);
}