import { date_isAtMostNextWorkday } from '../lib/date-utils';
var Fraction = require('fraction.js');

//seen all - OK

/**
 * Checks whether given user input can be parsed as valid number, the
 * item contains valid product packaging and units specification and
 * validates the amount against this specification.
 *
 * @param {string} user_input - the string as given by text Form.Control
 * @param {any} item - sales order item dictionary containing at least:
 *   * pjo_MnostviSklMjVObalu - amount in packaging in storage units
 *   * pj_HmotnostMj - storage unit weight in kg
 *   * pj_MjHmotnostiMj - storage unit weight unit - must be "kg"
 *   * npj_HmotnostMj - sales unit weight in kg
 *   * npj_MjHmotnostiMj - sales unit weight unit - must be "kg"
 *   * nop_ZbyvaPrijmout - remaining amount in sales units
 * @return {array} - [ status, message, cleaned version of user_input (see below) ]
 */
export function check_sn_amount(user_input, item, t) {
    // No input at all
    if (user_input.length === 0) {
        return [null, null, null];
    }

    // Packaging weight in kg
    const pack_units = new Fraction(item.pjo_MnozstviSklMjVObalu);
    const pack_unit_kg = new Fraction(item.pj_HmotnostMj);
    const pack_unit_kg_unit = item.pj_MjHmotnostiMj; // to kontrolují checky na detailu a ty by mě nepustily dál, můžu klidně použít item.package_weight_wh
    if ((pack_unit_kg_unit || "").trim() !== "kg") {
        return [false, t('wh_unit_doesnt_have_kg_weight'), null];
    }
    if (pack_unit_kg.n === 0) {
        return [false, t("wh_unit_is_zero"), null];
    }
    if (pack_units.n === 0) {
        return [false, t("package_amount_is_zero"), null];
    }
    const pack_weight_kg = pack_unit_kg.mul(new Fraction(pack_units));

    // Sales - string check and conversion to number, remove redundant trailing decimal point
    const user_input_no_spaces = user_input.replaceAll(' ', '').replace(/[,.]$/, '');
    const user_input_regexp = /^[-]{0,1}[0-9]+([.,][0-9]+){0,1}$/;
    if (user_input_no_spaces.match(user_input_regexp) === null) {
        return [false, t("number_isnt_valid_number"), null];
    }
    const user_input_decimal_point = user_input_no_spaces.replace(',', '.');
    const user_fraction = new Fraction(user_input_decimal_point);

    // Sales amount converted to weight in kg
    const sales_unit_kg = new Fraction(item.npj_HmotnostMj);
    const sales_unit_kg_unit = item.npj_MjHmotnostiMj;
    if ((sales_unit_kg_unit || "").trim() !== "kg") {
        return [false, t("sale_unit_is_missing_kg_weight"), null];
    }
    if (sales_unit_kg.n === 0) {
        return [false, t("sale_unit_has_zero_kg_weight"), null];
    }
    const user_fraction_in_kg = user_fraction.mul(sales_unit_kg);

    // Sales remaining
    const sales_remaining = item.nop_ZbyvaPrijmout;
    const sales_remaining_kg = sales_unit_kg.mul(sales_remaining);

    // Perform the check
    return do_check_sn_amount(user_fraction_in_kg, pack_weight_kg, sales_unit_kg,
        sales_remaining_kg,
        user_input_decimal_point, t);
}

//console.log(check_sn_amount("bllaa", {pjo_MnozstviSklMjVObalu: " "}));

/**
 * Checks whether given amount in kg is an integer multiple of
 * packaging size in kg, it is positive and less than remaining amount.
 *
 * @param {number} amount_kg - user input (in sales units) converted to Fraction in kg
 * @param {number} pack_kg - packaging size Fraction in kg
 * @param {number} sales_unit_kg - weight as Fraction of single packaging unit in kg
 * @param {number} sales_remaining_kg - remaining amount as Fraction in kg
 * @param {string} clean_result - original user input without spaces and with proper (english) decimal point
 * @return {array} - [ status, message, clean_result ]
 */
export function do_check_sn_amount(amount_kg, pack_kg, sales_unit_kg,
    sales_remaining_kg,
    clean_result, t) {
    if (sales_remaining_kg < pack_kg) {
        return [false, "remaining_is_less_than_one_package", null];
    }
    if ((amount_kg.s < 0) || (amount_kg.n === 0)) {
        return [false, "positive_number_required", null];
    }

    // How many packagings are there
    const pack_count = amount_kg.div(pack_kg);
    if (pack_count.d !== 1) {
        // If non-integer multiply, suggest surrounding integer multiplies
        const pack_count_lower = pack_count.floor();
        const pack_count_higher = pack_count.ceil();
        const pack_count_min = new Fraction(1); // Checked above
        const pack_count_max = sales_remaining_kg.div(pack_kg).floor();
        const pack_kg_min = pack_count_min.mul(pack_kg);
        const pack_kg_max = pack_count_max.mul(pack_kg);
        const pack_sales_min = pack_kg_min.div(sales_unit_kg);
        const pack_sales_max = pack_kg_max.div(sales_unit_kg);
        const pack_kg_lower = pack_count_lower.mul(pack_kg);
        const pack_kg_higher = pack_count_higher.mul(pack_kg);
        const pack_sales_lower = pack_kg_lower.div(sales_unit_kg);
        const pack_sales_higher = pack_kg_higher.div(sales_unit_kg);
        const pack_sales_lower_clamped = fraction_clamp(pack_sales_lower, pack_sales_min, pack_sales_max);
        const pack_sales_higher_clamped = fraction_clamp(pack_sales_higher, pack_sales_min, pack_sales_max);

        // Limit to non-zero suggestions smaller or equal to remaining amount
        const suggestions = [pack_sales_lower_clamped, pack_sales_higher_clamped]
            .filter((amount) => ((amount.n > 0) &&
                (amount.compare(sales_remaining_kg) <= 0)))
            .map((n) => n.toString())
            .filter((v, i, a) => a.indexOf(v) === i);

        return [false,
            t("you_have_to_fill_whole_multiple") + " " + //t musím udělat už tu, jinak to překládá celý
            suggestions.join(" " + t('or') + " ") + ".",
            null];
    }

    // More than remaining?
    if (amount_kg.compare(sales_remaining_kg) > 0) {
        const pack_count_max = sales_remaining_kg.div(pack_kg).floor();
        const pack_kg_max = pack_count_max.mul(pack_kg);
        const pack_sales_max = pack_kg_max.div(sales_unit_kg);
        return [false, t("cannot_notify_more_than_remaining") + pack_sales_max, null];
    }

    // Return cleaned user input upon success
    return [true, null, clean_result];
}

/**
 * Checks whether the batch is non-empty string for pwc_id 1 and 2.
 *
 * @param {string} user_input - the string as given by text Form.Control
 * @param {any} item - sales order item dictionary containing at least:
 *   * pwc_id - product warehouses configuration id: 3 means no batch checks performed
 * @param {string} eta - estimated arrival of the goods to the warehouse - cannot be null
 * @param {string} batch_amount - cleaned amount from previous check
 * @return {array} - [ status, message, user_input if check passed ]
 */
export function check_sn_batch(user_input, item, eta, batch_amount) {
    //console.log(user_input);
    if (user_input === null) {
        return [null, null, null];
    }
    if (!Object.keys(item).includes("pwc_id")) {
        return [false, "missing_bbd_batch_settings", null];
    }
    const pwc_id = item.pwc_id;
    if (pwc_id === 3) {
        return [true, null, null];
    }
    if (user_input.trim().length === 0) {
        /*
        if (is_sn_batch_check_special(item, eta, batch_amount)) {
            return [null, null, user_input];
        }
        */
        return [false, "batch_mandatory", null];
    }
    return [true, null, user_input];
}

/**
 * Checks whether this item is special case - at least 5t or ETA is
 * next working day. This function works correctly only if check_sn_amount()
 * succeeded at least with specification tests.
 *
 * @param {any} item - item dictionary, must contain at least:
 *   * nop_ZbyvaPrijmout
 *   * npj_HmotnostMj
 * @param {string} eta - estimated date of arrival to the warehouse
 * @param {string} batch_amount - cleared string representing the amount
 *   for this batch in sales units
 * @return {boolean} - true if this really is an exceptional case
 */
export function is_sn_batch_check_special(item, eta, batch_amount) {
    if (batch_amount !== null) {
        // >= 5t, validity of product specification was checked in check_sn_amount
        const amount_fraction = new Fraction(batch_amount);
        const sales_remaining = item.nop_ZbyvaPrijmout;
        if (amount_fraction.compare(sales_remaining) <= 0) {
            // The amount is less than remaining
            const sales_unit_kg = new Fraction(item.npj_HmotnostMj);
            const amount_fraction_in_kg = amount_fraction.mul(sales_unit_kg);
            if (amount_fraction_in_kg.compare(5000) >= 0) {
                // More than 5t and actually possible - special case
                return true;
            }
        }
    }
    // Check tomorrow
    if (date_isAtMostNextWorkday(eta)) {
        return true;
    }
    // No special case
    return false;
}

/**
 * Re-interprets null result of check_sn_batch as true if this was a special case.
 *
 * @param {any} orig_result - true, false or null from check_sn_batch (true - ok, false - nok, null - nothing)
 * @param {any} item - item dictionary, must contain at least:
 *   * nop_ZbyvaPrijmout
 *   * npj_HmotnostMj
 * @param {string} eta - estimated date of arrival to the warehouse
 * @param {string} batch_amount - cleared string representing the amount
 *   for this batch in sales units
 * @return {boolean} - true if this really is an exceptional case
 */
export function reinterpret_check_sn_batch(orig_result, item, eta, batch_amount) {
    if (orig_result) {
        return true;
    }
    if ((is_sn_batch_check_special(item, eta, batch_amount)) &&
        (orig_result === null)) {
        return true;
    }
    return false;
}

/**
 * Clamps given value in the specified interval. Works internally with
 * Fraction representation only.
 *
 * @param {number} value - the value to clamp
 * @param {number} minimum - minimal allowed value
 * @param {number} maximum - the maximal value allowed
 * @return {number} - the clamped value as Fraction
 */
export function fraction_clamp(value, minimum, maximum) {
    const f_value = new Fraction(value);
    const f_min = new Fraction(minimum);
    const f_max = new Fraction(maximum);
    if (f_value.compare(f_min) < 0) {
        return f_min;
    }
    if (f_value.compare(f_max) > 0) {
        return f_max;
    }
    return f_value;
}
