/**
 * Overview of all products in hsh and warehouses parameters with checks and filtering.
 * @module products/product-checks-overview-horizontal
 * @author Lucie Zdenkova <lucie.zdenek@trustica.cz>
 */
import React, { useState, useEffect } from 'react';
import { his_fetch, his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import Table from 'react-bootstrap/Table';
import { Button, Image, Row, Col, Form } from 'react-bootstrap';
import { Pager } from '../comp/pager';
import { filter_rule, icompare } from '../lib/utils';
import { MultipleSelect } from '../comp/multiple-select';
import { BooleanDropdown, Boolean } from '../comp/boolean';
import { check_warehouse_container_name, romanize_packaging_group, } from './product-checks';
import { ShowADR } from '../comp/adr';
import { ItemsAndFiltered } from '../comp/dynamic-load';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { LoadingDataInfo } from '../comp/dynamic-load';
import { mandatory_format } from './product-utils';
import { LinkContainer } from 'react-router-bootstrap';
import { whs, valid_packaging_groups } from '../lists/warehouses-defs';
import { monitoring_names, monitoring_titles } from '../lists/monitoring_batch_bbd';
import { boolean_titles } from '../lists/boolean_titles';
import { useTranslation } from 'react-i18next';
var Fraction = require('fraction.js');

//seen all - OK

// https://stackoverflow.com/questions/7656719/c-sharp-or-javascript-determining-common-prefix-in-strings
export function findLongestPrefixLen(list) {
    // console.log(list);
    var prefix = '' + list[0];
    var prefixLen = prefix.length;
    for (var i = 1; i < list.length && prefixLen > 0; i++) {
        var word = list[i];
        // The next line assumes 1st char of word and prefix always match.
        // Initialize matchLen to -1 to test entire word.
        var matchLen = 0;
        var maxMatchLen = Math.min(word.length, prefixLen);
        while (++matchLen < maxMatchLen) {
            if (word.charAt(matchLen) !== prefix.charAt(matchLen)) {
                break;
            }
        }
        prefixLen = matchLen;
    }
    return prefixLen;
}


function get_unique_something(arr, key) { //arr je vstupní pole slovníků, vytáhne to z nich jeden klíč a následně to pro ten klíč získá unikátní hodnoty
    const values = arr.map((rec) => rec[key]);
    return values.filter((v, i, a) => a.indexOf(v) === i);
}

export function ProductsCheckOverviewHorizontal({ userlogin }) {
    const [loadedStatusCheck, setLoadedStatusCheck] = useState(0);
    const [data, setData] = useState(null);
    const [offset, setOffset] = useState(0);

    const reloadIt = () => {
        setData(null);
        setLoadedStatusCheck(0);
    }

    useEffect(() => {
        if (data === null) {
            const running_fetch = his_fetch(
                userlogin,
                [
                    {
                        uri: "/api/products/check",
                        json: true,
                        status: setLoadedStatusCheck,
                        ok: function (resource, result) {
                            //console.log(result);
                            setData(result.products);
                        },
                        error: function (resource, reason) {
                            console.log('err: ' + reason);
                            setData("error");
                        }
                    }
                ]
            );
            return () => {
                running_fetch();
            }
        }
    }, [userlogin, data]);

    //  uri: "/api/products/checky", proč to tady jde na 400 badRequest a jde to do 2?

    const usable_data = (data !== null && data !== "error") ? data : [];

    return (
        <ChecksTable records={usable_data} offset={offset} setOffset={setOffset}
            reloadIt={reloadIt} loadedStatusCheck={loadedStatusCheck}
        />
    );
}

/**
  * pokud má  gw - check batch - kontroluje sarži, pokud check_bbd - kontrolují expiraci a my chceme vědět, jestli ty kontroly ve
  * skladu odpovídají kontrole v HSH, 
  * 1 nebude nikdy správně, bbd - nikdy není ano 
  * srovnávám, jestli sklad splnuje HSH nastavení!!! pouze v případě,  že sklad má opačné nastavení, tak je to problém, pokud má sklad  nastavení navíc, tak mírný warning
  * null je okej stav - u jiných než gw skladů to jen tak kontrolovat nebudem
  */

function checks_etc(piw, hsh_product) {
    //console.log(hsh_product);
    if (!piw) {
        return {
            piw: false
        };
    } else {
        // váha 1 balení výpočet + kontrola
        const worth_display1_wh = piw.pcr_MnozstviSklMjVObalu && piw.pcr_HmotnostMjVKg;
        const package_weight_count_wh = Fraction(piw.pcr_MnozstviSklMjVObalu).mul(Fraction(piw.pcr_HmotnostMjVKg));
        const package_weight_wh = worth_display1_wh ? String(package_weight_count_wh) : <>&mdash;</>;
        const package_weight_problem = !hsh_product.worth_display_hsh ? false : hsh_product.package_weight !== package_weight_wh;
        //název produktu kontroly
        const wNazevProduktu = piw.pcr_NazevProduktu;
        const NazevProduktu = piw.tp_NazevProduktu; //v tom piw jsou hsh hodnoty taky!
        const prefixLen = findLongestPrefixLen([NazevProduktu, wNazevProduktu]);
        const nazevPrefix = wNazevProduktu.substring(0, prefixLen);
        const nazevSuffix = wNazevProduktu.substring(prefixLen);
        // amount compare checks
        const amount_problem = hsh_product.amount_hsh_problem ? false : piw.pjo_MnozstviSklMjVObalu !== piw.pcr_MnozstviSklMjVObalu;
        // mj compare check - problémy, když není jednotka v HSH/Twistu
        const unit_problem = hsh_product.unit_hsh_problem ? false : (piw.tp_KodMjSkl || "").toLowerCase().trim() !== (piw.pcr_KodMjSkl || "").toLowerCase().trim();
        // density compare check
        const density_problem = hsh_product.density_hsh_problem ? false : piw.pj_HmotnostMj !== piw.pcr_HmotnostMjVKg;
        //batch & bbd checkings
        const batch_problem = piw.pcr_gw_check_batch === null ? false : (piw.pwc_id === 1 || piw.pwc_id === 2) && piw.pcr_gw_check_batch !== "MANDATORY"; // pwc je 1 || 2 a batch není "mandatory"
        const bbd_problem = piw.pcr_gw_check_bbd === null ? false : piw.pwc_id === 1 && piw.pcr_gw_check_bbd !== "MANDATORY"; //pwc je 1 a bbd není "mandatory"
        const batch_warning = piw.pwc_id === 3 && piw.pcr_gw_check_batch === "MANDATORY"; // pwc je 3 a batch je mandatory
        const bbd_warning = (piw.pwc_id === 3 || piw.pwc_id === 2) && piw.pcr_gw_check_bbd === "MANDATORY"; //pwc je 3 || 2 a bbd je mandatory
        //safety_sheet
        const safety_sheet_problem = piw.pcr_safety_sheet === false;
        //obal check
        const container_problem = hsh_product.container_hsh_problem ? false : !check_warehouse_container_name(piw.pci_warehouse_id, piw.pcr_k_HSHObal5, piw.co_k_HSHObal5);
        //adr check
        const isADR = piw.tp_k_ADR;
        const package_group_problem = hsh_product.package_group_hsh_problem ? false : isADR && (!piw.pcr_k_ADRObalovaSkupina || (romanize_packaging_group(piw.tp_k_ADRObalovaSkupina) !== (piw.pcr_k_ADRObalovaSkupina || "").trim()));
        const class_problem = hsh_product.class_hsh_problem ? false : isADR && (!piw.pcr_TridaADR || (piw.kcunc_TridaADR !== piw.pcr_TridaADR));
        const un_num_problem = hsh_product.un_num_hsh_problem ? false : isADR && (!piw.pcr_k_ADRUNCislo || (piw.tp_k_ADRUNCislo !== piw.pcr_k_ADRUNCislo));

        // console.log(package_weight_problem);

        return {
            piw: true,
            package_weight_wh: package_weight_wh,
            nazevPrefix: nazevPrefix,
            nazevSuffix: nazevSuffix,
            amount_problem: amount_problem,
            unit_problem: unit_problem,
            density_problem: density_problem,
            package_weight_problem: package_weight_problem,
            batch_problem: batch_problem,
            bbd_problem: bbd_problem,
            batch_warning: batch_warning,
            bbd_warning: bbd_warning,
            safety_sheet_problem: safety_sheet_problem,
            packaging_problem: container_problem,
            package_group_problem: package_group_problem,
            class_problem: class_problem,
            un_num_problem: un_num_problem,
            problem: package_weight_problem || safety_sheet_problem || batch_problem || bbd_problem || container_problem || package_group_problem || class_problem || un_num_problem,
        };
    }
}

function intelligent_romanize_packaging_group(v) {
    return v === null ? null : romanize_packaging_group(v);
}

function get_packaging_group_keys(pcr_values, hsh_values) {
    //  //logika val === null || val === "" je také v match_pg
    return pcr_values
        .concat(hsh_values)
        .map((v) => (v === null || v === "") ? null : String(v).trim().toLowerCase())
        .filter((v, i, a) => a.indexOf(v) === i)
        .sort((a, b) => a === null ? -1 : b === null ? 1 : String(a) < String(b) ? -1 : 1);
}

function get_packaging_group_name(key, uppercase) {
    return key === null ? "? Neznámé" : uppercase ? key.toUpperCase() : key.toLowerCase();
}

function get_packaging_group_names(keys, uppercase = false) {
    return keys.reduce((acc, v) => ({ ...acc, [v]: get_packaging_group_name(v, uppercase) }), {});
}

function match_bb(piw, val, fil) {
    return piw ? ((val === null ? null : (val === "MANDATORY")) === fil) : false; //(val === "MANDATORY")) to přemapovává na true-false
}

function match_pg(val, fil) {
    //logika val === null || val === "" je také v get_packaging_group_keys
    return (val === null || val === "") ? fil[null] : fil[String(val).trim().toLowerCase()];
}

function match_MJ(val, fil) {
    return (val === null || val === "") ? fil[null] : fil[String(val).trim().toLowerCase()];
}

const dictionaryOfWHValues = Object.keys(whs).reduce((acc, v) => ({ ...acc, [v]: true }), {});
const dictionaryOfWHValuesFalse = Object.keys(whs).reduce((acc, v) => ({ ...acc, [v]: false }), {});

function get_class_dict(records) {
    const unique_class_hsh = get_unique_something(records, "kcunc_TridaADR");
    const unique_class_wh = get_unique_something(records, "pcr_TridaADR");
    const classes_keys = get_packaging_group_keys(unique_class_wh, unique_class_hsh);
    const dictClass = get_packaging_group_names(classes_keys);
    return dictClass;
}

function get_package_group_dict(records) {
    const unique_PG_hsh = get_unique_something(records, "tp_k_ADRObalovaSkupina").map(intelligent_romanize_packaging_group);
    const unique_PG_wh = get_unique_something(records, "pcr_k_ADRObalovaSkupina");
    const PG_keys = get_packaging_group_keys(unique_PG_wh, unique_PG_hsh);
    return PG_keys;
}


function get_Mj_dict(records) {
    const unique_MJ_hsh = get_unique_something(records, "tp_KodMjSkl");
    const unique_MJ_wh = get_unique_something(records, "pcr_KodMjSkl");
    const MJ_keys = get_packaging_group_keys(unique_MJ_wh, unique_MJ_hsh);
    const dictMJ = get_packaging_group_names(MJ_keys);
    return dictMJ;
}


function create_dict_const(dict, val = false) {
    return Object.keys(dict).reduce((acc, v) => ({ ...acc, [v]: val }), {});
}

function create_dict_const_arr(array, val = false) {
    return array.reduce((acc, v) => ({ ...acc, [v]: val }), {});
}

function create_class_dict_const(records, val = false) {
    return create_dict_const(get_class_dict(records), val);
}

function create_MJ_dict_const(records, val = false) {
    return create_dict_const(get_Mj_dict(records), val);
}

function ChecksTable({ records, offset, setOffset, reloadIt, loadedStatusCheck }) {
    const { t } = useTranslation();

    const dictMJ = get_Mj_dict(records);

    const dictClass = get_class_dict(records);

    const PG_keys = get_package_group_dict(records);
    const PG_names = get_packaging_group_names(PG_keys, true);

    const dictOfMJVal = create_MJ_dict_const(records, true);
    const dictOfMJValFalse = create_MJ_dict_const(records, false);
    //console.log(dictOfMJVal);
    //console.log(dictOfMJValFalse);

    const dictOfClassVal = create_class_dict_const(records, true);
    const dictOfClassValFalse = create_class_dict_const(records, false);
    //console.log(dictOfClassVal);
    //console.log(dictOfClassValFalse);

    const dictOfPackageGroupVal = create_dict_const_arr(PG_keys, true);
    const dictOfPackageGroupValFalse = create_dict_const_arr(PG_keys, false);


    const [filterIDCodeCheck, setFilterIDCodeCheck] = useState("");
    const [filterName, setFilterName] = useState("");
    const [checkedWH, setCheckedWH] = useState(dictionaryOfWHValues);
    const [filterStatus, setFilterStatus] = useState(null);
    const [filterADR, setFilterADR] = useState(null);
    const [filterBatch, setFilterBatch] = useState("");
    const [filterBBD, setFilterBBD] = useState("");
    const [filterSafetySheet, setFilterSafetySheet] = useState("");
    const [filterPwcID, setFilterPwcID] = useState("");
    const [filterContainer, setFilterContainer] = useState("");
    const [filterAmount, setFilterAmount] = useState("");
    const [filterMJ, setFilterMJ] = useState(dictOfMJVal);
    const [filterDensity, setFilterDensity] = useState("");
    const [filterPackageWeight, setFilterPackageWeight] = useState("");
    const [filterClass, setFilterClass] = useState(dictOfClassVal);
    const [filterPackageGroup, setFilterPackageGroup] = useState(dictOfPackageGroupVal);
    const [filterUnNum, setFilterUnNum] = useState("");

    //console.log(filterPackageGroup);

    //console.log(checkedWH);

    const cleanFilters = () => {
        setFilterIDCodeCheck("");
        setFilterName("");
        setCheckedWH(dictionaryOfWHValues);
        setFilterStatus(null);
        setFilterADR(null);
        setFilterBatch("");
        setFilterBBD("");
        setFilterSafetySheet("");
        setFilterPwcID("");
        setFilterContainer("");
        setFilterAmount("");
        setFilterMJ(dictOfMJVal);
        setFilterDensity("");
        setFilterPackageWeight("");
        setFilterClass(dictOfClassVal);
        setFilterPackageGroup(dictOfPackageGroupVal);
        setFilterUnNum("");
    }

    //tadyta komponenta bude mít useeffect, který bude resetovat filtry
    useEffect(() => {
        //  cleanFilters();
        //má to začít s default filtrem a přeplácnout aktuálním filtrem
        setCheckedWH(checkedWHCat => ({ ...dictionaryOfWHValues, ...checkedWHCat })); //functional updates 
        const dictOfMJVal = create_MJ_dict_const(records, true);
        setFilterMJ(filterMJCat => ({ ...dictOfMJVal, ...filterMJCat }));
        const dictOfClassVal = create_class_dict_const(records, true);
        setFilterClass(filterClassCat => ({ ...dictOfClassVal, ...filterClassCat })); //dictOfClassVal slovník ze všech records, co má vše zaškrnuté a přes něj přeplácnnu aktuální slovník, čím udělám, že explicitně false hodnoty se přepíší, záleží na pořadí spread operátorů
        const PG_keys = get_package_group_dict(records);
        const dictOfPackageGroupVal = create_dict_const_arr(PG_keys, true);
        setFilterPackageGroup(filterPackageGroupCat => ({ ...dictOfPackageGroupVal, ...filterPackageGroupCat }));

    }, [records,]); // dictOfClassVal, dictOfMJVal, dictOfPackageGroupVal, dictionaryOfWHValues cant be part of dependency array, otherwise  Maximum update depth exceeded. 
    // This can happen when a component calls setState inside useEffect, but useEffect either doesn't have a dependency array, or one of the dependencies changes on every render. happens

    const handleChangeIDCode = (e) => {
        setFilterIDCodeCheck(e.target.value);
        setOffset(0);
    }
    const handleChangeName = (e) => {
        setFilterName(e.target.value);
        setOffset(0);
    }
    const handleChangeContainer = (e) => {
        setFilterContainer(e.target.value);
        setOffset(0);
    }
    const handleChangeBatch = function (val) {
        setFilterBatch(val);
        setOffset(0);
    }
    const handleChangeBBD = function (val) {
        setFilterBBD(val);
        setOffset(0);
    }
    const handleChangePwcID = function (val) {
        setFilterPwcID(val);
        setOffset(0);
    }
    const handleChangeSS = function (val) {
        setFilterSafetySheet(val);
        setOffset(0);
    }
    const handleChangeAmount = (e) => {
        setFilterAmount(e.target.value);
        setOffset(0);
    }
    const handleChangeDensity = (e) => {
        setFilterDensity(e.target.value);
        setOffset(0);
    }
    const handleChangePackageWeight = (e) => {
        setFilterPackageWeight(e.target.value);
        setOffset(0);
    }
    const handleChangeUnNum = (e) => {
        setFilterUnNum(e.target.value);
        setOffset(0);
    }


    const uniqueProducts = get_unique_something(records, "pcr_k_IDProduktu");
    const warehouses = get_unique_something(records, "pci_warehouse_id");

    const productsDetails = uniqueProducts.map((id) => {
        const rec = records.find((r) => r.pcr_k_IDProduktu === id); //najdeme první záznam od daného produktu a pak v 207 tam přidáme záznamy ke všem wh
        const isInTwist = rec.tp_KodProduktu !== null;

        const worth_display_hsh = rec.pjo_MnozstviSklMjVObalu && rec.pj_HmotnostMj;
        const package_weight_count = Fraction(rec.pjo_MnozstviSklMjVObalu).mul(Fraction(rec.pj_HmotnostMj));
        const package_weight = worth_display_hsh ? String(package_weight_count) : <>&mdash;</>;

        const adr_hsh_problem = !rec.tp_k_ADR === null;
        const monitoring_hsh_problem = !rec.pwc_id;
        const name_hsh_problem = !rec.tp_NazevProduktu;
        const container_hsh_problem = !rec.co_k_HSHObal5;
        const amount_hsh_problem = !rec.pjo_MnozstviSklMjVObalu;
        const unit_hsh_problem = !rec.tp_KodMjSkl;
        const density_hsh_problem = !rec.pj_HmotnostMj;
        const class_hsh_problem = !rec.kcunc_TridaADR && rec.tp_k_ADR;
        const package_group_hsh_problem = !valid_packaging_groups[rec.tp_k_ADRObalovaSkupina] && rec.tp_k_ADR;
        const un_num_hsh_problem = !rec.tp_k_ADRUNCislo && rec.tp_k_ADR;

        const hsh_product = {
            id: id,
            KodProduktu: rec.tp_KodProduktu,
            pwc_id: rec.pwc_id,
            NazevProduktu: rec.tp_NazevProduktu,
            obal: rec.co_k_HSHObal5,
            amount: rec.pjo_MnozstviSklMjVObalu,
            density: rec.pj_HmotnostMj,
            unit: rec.tp_KodMjSkl,
            package_weight_count: package_weight_count,
            package_weight: package_weight,
            worth_display_hsh: worth_display_hsh,
            adr: rec.tp_k_ADR,
            trida: rec.kcunc_TridaADR,
            obal_sk: rec.tp_k_ADRObalovaSkupina,
            un_cislo: rec.tp_k_ADRUNCislo,
            isInTwist: isInTwist,
            adr_hsh_problem: adr_hsh_problem,
            monitoring_hsh_problem: monitoring_hsh_problem,
            name_hsh_problem: name_hsh_problem,
            container_hsh_problem: container_hsh_problem,
            amount_hsh_problem: amount_hsh_problem,
            unit_hsh_problem: unit_hsh_problem,
            density_hsh_problem: density_hsh_problem,
            class_hsh_problem: class_hsh_problem,
            package_group_hsh_problem: package_group_hsh_problem,
            un_num_hsh_problem: un_num_hsh_problem,
        };


        const piws = warehouses.map((wid) => {
            const piw = records.find(
                (rec) => rec.pcr_k_IDProduktu === id && rec.pci_warehouse_id === wid);

            const checks_result = checks_etc(piw, hsh_product);

            return {
                ...piw, //piw je record pro správný warehouse
                ...checks_result, // slovník s výsledky kontrol a preprocessingu
                isInTwist: isInTwist,
            };
        });

        //console.log(piws);
        const problemAtLeastOneVisibleWH = piws.reduce((acc, v) => acc || (v.problem && checkedWH[v.pci_warehouse_id]), false); //začne na false, projde všechny v.problem v piws a oruje je mezi sebou //toto mi řekne, jestli je problem aspon v jednom wh na produktu, přidána podmínka, že to musí být ve filtru vybrané

        return {
            ...hsh_product,
            warehouses: piws, //to jsou pak jednotlivý řádky warehousů pro daný produkt
            //   warehouseProblemTest: piws.reduce((acc, v) => acc || v.problem && checkedWH[v.pci_warehouse_id], false), //v.problem && checkedWH[v.pci_warehouse_id] je ve filtru
            problem: !isInTwist || adr_hsh_problem || monitoring_hsh_problem || name_hsh_problem || container_hsh_problem
                || amount_hsh_problem || unit_hsh_problem || density_hsh_problem || class_hsh_problem || package_group_hsh_problem
                || un_num_hsh_problem || problemAtLeastOneVisibleWH
        };
    });

    //console.log(productsDetails);
    //console.log(filterPackageGroup);
    //console.log(filterMJ);

    const products_with_filtered_piws = productsDetails.map( //prochází všechny produkty a mění: v product v klíči warehouses a ty, které nesplňují filter pro warehouse,  nahradí záznamem, co říká, že není karta 
        function (pr) {
            const piws = pr.warehouses;
            const piws_filtered = piws.map(
                function (piw) {
                    if (piw.piw && (!(checkedWH[piw.pci_warehouse_id]))) {
                        return {
                            piw: false
                        };
                    } else {
                        return piw;
                    }
                });
            return {
                ...pr,
                warehouses: piws_filtered //přepisuje ten klíč, pokud je product in warehouse, předávám ho jak je, jinak tam dělám dojem, že karta není ve skladu
            };
        });

    const products_filtered_by_warehouse = products_with_filtered_piws.filter(
        function (pr) {
            return (pr.warehouses.reduce((acc, v) => acc || checkedWH[v.pci_warehouse_id], false));
        }
    );
    //console.log(products_filtered_by_warehouse.length);
    const products_filtered = products_filtered_by_warehouse.filter(
        function (pr) {
            return (
                ((filter_rule(filterIDCodeCheck, pr.id, true)) ||
                    (filter_rule(filterIDCodeCheck, pr.KodProduktu, true))) &&
                ((filterStatus === null) ||
                    (filterStatus === pr.problem)) &&
                (filter_rule(filterContainer, pr.obal, true)
                    || pr.warehouses.reduce((acc, v) => acc || filter_rule(filterContainer, v.pcr_k_HSHObal5, true), false)) &&

                (filter_rule(filterName, pr.NazevProduktu, true)
                    || pr.warehouses.reduce((acc, v) => acc || filter_rule(filterName, v.pcr_NazevProduktu, true), false) //začínám na false, procházím strukturu warehouses a koukám na hodnotu filter_rule v kontextu pcr_nazevProduktu a oruju, protože chci, aby mi tam true spadlo //pro všechny warehousy se zeptáme na filter_rule získáme boolean hodnotu toho
                ) &&
                // (pr.warehouses.reduce((acc, v) => acc || checkedWH[v.pci_warehouse_id], false)) &&
                (pr.warehouses.reduce((acc, v) => acc || match_bb(v.piw, v.pcr_gw_check_batch, filterBatch), false) || (filterBatch === "")) &&
                (pr.warehouses.reduce((acc, v) => acc || match_bb(v.piw, v.pcr_gw_check_bbd, filterBBD), false) || (filterBBD === "")) &&
                (pr.warehouses.reduce((acc, v) => acc || v.pwc_id === filterPwcID, false) || (filterPwcID === "")) &&
                (pr.warehouses.reduce((acc, v) => acc || v.pcr_safety_sheet === filterSafetySheet, false) || (filterSafetySheet === "")) &&
                (filter_rule(filterUnNum, pr.tp_k_ADRUNCislo, true)
                    || pr.warehouses.reduce((acc, v) => acc || filter_rule(filterUnNum, v.pcr_k_ADRUNCislo, true), false)) &&
                (icompare(pr.pjo_MnozstviSklMjVObalu, filterAmount)
                    || pr.warehouses.reduce((acc, v) => acc || icompare(v.pcr_MnozstviSklMjVObalu, filterAmount), false)) &&
                (icompare(pr.pj_HmotnostMj, filterDensity)
                    || pr.warehouses.reduce((acc, v) => acc || icompare(v.pcr_HmotnostMjVKg, filterDensity), false)) &&
                (icompare(parseInt(pr.package_weight), filterPackageWeight)
                    || pr.warehouses.reduce((acc, v) => acc || icompare(parseInt(v.package_weight_wh), filterPackageWeight), false)) &&
                ((filterADR === null) ||
                    (filterADR === pr.adr))

                && (match_pg(intelligent_romanize_packaging_group(pr.tp_k_ADRObalovaSkupina), filterPackageGroup)
                    || pr.warehouses.reduce((acc, v) => acc || match_pg(v.pcr_k_ADRObalovaSkupina, filterPackageGroup), false))

                && (match_pg(pr.kcunc_TridaADR, filterClass)
                    || pr.warehouses.reduce((acc, v) => acc || match_pg(v.pcr_TridaADR, filterClass), false))

                && (match_MJ(pr.unit, filterMJ)
                    || pr.warehouses.reduce((acc, v) => acc || match_MJ(v.pcr_KodMjSkl, filterMJ), false))
            );
        }
    );
    //console.log(products_filtered.length);
    const products_to_show_sliced = products_filtered.slice(offset, offset + 20);

    function parseKey(k) {
        if (k === 'null') {
            return null;
        } else {
            const n = parseInt(k);
            if (String(n) === k) {
                return n;
            } return k;
        }
    }

    return (
        <div>
            <Row>
                <Col>
                    <Button disabled={loadedStatusCheck !== 2} size="sm" className="me-2 d-inline" onClick={reloadIt}><Image src="/img/reload.svg" height="19" /></Button>
                </Col>
                <Col className='text-center'>
                    <ItemsAndFiltered filtered_data={products_filtered} data={uniqueProducts} cleanFilters={cleanFilters} />
                </Col>
                <Col>
                    <Pager offset={offset} pagesize={20} total={products_filtered.length} callback={setOffset} />
                </Col>
            </Row>

            <Table size="sm" bordered>
                <thead className='beGray'>
                    <tr>
                        <th rowSpan={2}>
                            <p className="mb-2">{t('state')}</p>
                            <BooleanDropdown variant="product-checks" onChange={setFilterStatus} value={filterStatus} />
                        </th>
                        <th rowSpan={2} className="text-center">
                            <Form.Group controlId="filterID" className="mb-0">
                                <Form.Label>ID / {t('prod-code')}</Form.Label>
                                <Form.Control type="text" placeholder="&#128269;" onChange={handleChangeIDCode} value={filterIDCodeCheck} />
                            </Form.Group>
                            {t('prod-ADR')}
                            <BooleanDropdown onChange={setFilterADR} variant="onlyTrue" value={filterADR} />
                        </th>
                        <th rowSpan={2}>
                            <p className="mb-1">{t('menu-prod')} <br /> {t('prod-in_wh')}</p>
                            <MultipleSelect checked={checkedWH} setChecked={setCheckedWH}
                                dictionaryTrue={dictionaryOfWHValues} dictionaryFalse={dictionaryOfWHValuesFalse}
                                itemsNames={whs} setOffset={setOffset} id="filterWH" withoutNumber />
                        </th>
                        <th rowSpan={2}>
                            {t('prod-monitoring')}
                            < DropdownButton id="filterMonitoring" title={t(monitoring_titles[filterPwcID])} variant="light">
                                {Object.keys(monitoring_titles).map(function (m, idx) {
                                    return <Dropdown.Item key={idx} onClick={() => handleChangePwcID(parseKey(m))}>{t(monitoring_titles[m])}</Dropdown.Item>;
                                })}
                            </DropdownButton>
                            <Row className="g-0">
                                <Col>
                                    {t('ord-batch')}:
                                    <DropdownButton id="filterBatch" title={t(boolean_titles[filterBatch])} variant="light">
                                        <Dropdown.Item onClick={() => handleChangeBatch("")}>{t('all')}</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleChangeBatch(null)}>? - {t('unknown')}</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleChangeBatch(true)}>{t('yes')}</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleChangeBatch(false)}>{t('no')}</Dropdown.Item>
                                    </DropdownButton>
                                </Col>
                                <Col>
                                    {t('prod-bbd')}:
                                    < DropdownButton id="filterBBD" title={t(boolean_titles[filterBBD])} variant="light">
                                        <Dropdown.Item onClick={() => handleChangeBBD("")}>{t('all')}</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleChangeBBD(null)}>? - {t('unknown')}</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleChangeBBD(true)}>{t('yes')}</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleChangeBBD(false)}>{t('no')}</Dropdown.Item>
                                    </DropdownButton>
                                </Col>
                            </Row>
                        </th>
                        <th rowSpan={2}>{t('prod-safe_list')}
                            < DropdownButton id="filterSS" title={t(boolean_titles[filterSafetySheet])} variant="light">
                                <Dropdown.Item onClick={() => handleChangeSS("")}>{t('all')}</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleChangeSS(null)}>? - {t('unknown')}</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleChangeSS(true)}>{t('yes')}</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleChangeSS(false)}>{t('no')}</Dropdown.Item>
                            </DropdownButton>
                        </th>
                        <th rowSpan={2}>
                            <Form.Group controlId="filterName" className="mb-0" >
                                <Form.Label>{t('name')}</Form.Label>
                                <Form.Control type="text" placeholder="&#128269;" onChange={handleChangeName} value={filterName} />
                            </Form.Group>
                        </th>
                        <th colSpan={4} className="text-center">{t('prod-package')}</th>
                        <th colSpan={3} className="text-center">{t('prod-ADR')}</th>
                    </tr>
                    <tr>
                        <th className='text-center'>
                            <Form.Group controlId="filterContainer" className="mb-0" >
                                <Form.Label>{t('ord-pack')}</Form.Label>
                                <Form.Control type="text" placeholder="&#128269;" onChange={handleChangeContainer} value={filterContainer} />
                            </Form.Group>
                        </th>
                        <th className='text-center'>
                            <Row className="g-0">
                                <Col>
                                    {t('prod-content')}
                                    <Form.Group controlId="filterAmount" className="mb-0" >
                                        <Form.Control type="text"
                                            placeholder="&#128269; > < ="
                                            value={filterAmount} onChange={handleChangeAmount} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    {t('measure_unit')}
                                    <MultipleSelect checked={filterMJ} setChecked={setFilterMJ}
                                        dictionaryTrue={dictOfMJVal} dictionaryFalse={dictOfMJValFalse}
                                        itemsNames={dictMJ} setOffset={setOffset} id="filterMJ" withoutNumber />
                                </Col>
                            </Row>
                        </th>
                        <th className='text-center'>{t('ord-density')}
                            <Form.Group controlId="filterDensity" className="mb-0" >
                                <Form.Control type="text"
                                    placeholder="&#128269; > < ="
                                    value={filterDensity} onChange={handleChangeDensity} />
                            </Form.Group>
                        </th>
                        <th className='text-center'>{t('ord-1_pack_weight')}
                            <Form.Group controlId="filterAmount" className="mb-0" >
                                <Form.Control type="text"
                                    placeholder="&#128269; > < ="
                                    value={filterPackageWeight} onChange={handleChangePackageWeight} />
                            </Form.Group>
                        </th>
                        <th className='text-center'>
                            {t('prod-class')}
                            <MultipleSelect checked={filterClass} setChecked={setFilterClass}
                                dictionaryTrue={dictOfClassVal} dictionaryFalse={dictOfClassValFalse}
                                itemsNames={dictClass} setOffset={setOffset} id="filterClass" withoutNumber />
                        </th>
                        <th className='text-center'>{t('prod-pack-group')}
                            <MultipleSelect checked={filterPackageGroup} setChecked={setFilterPackageGroup}
                                dictionaryTrue={dictOfPackageGroupVal} dictionaryFalse={dictOfPackageGroupValFalse}
                                itemsNames={PG_names} setOffset={setOffset} id="filterPackageGroup" withoutNumber />
                        </th>
                        <th className='text-center'>
                            <Form.Group controlId="filterUnNum" className="mb-0" >
                                <Form.Label>{t('ord-un_num')}</Form.Label>
                                <Form.Control type="text" placeholder="&#128269;" onChange={handleChangeUnNum} value={filterUnNum} />
                            </Form.Group>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {products_to_show_sliced.map((product, idx) => (
                        <ProductRow key={idx} product={product} warehouses={warehouses} row_num={idx} checkedWH={checkedWH} />
                    ))}
                </tbody>
            </Table>
            <LoadingDataInfo loadedStatus={loadedStatusCheck} data={records} withoutLoading />
            {!his_fetch_success(loadedStatusCheck) ?
                <HisFetchStatus status={loadedStatusCheck} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />
                : <></>
            }
        </div>
    );
}


function ProductRow({ product, warehouses, row_num, checkedWH }) {
    const { t } = useTranslation();
    //console.log(product);
    //console.log(warehouses);
    const bg_class = product.problem ? 'bg-danger' : 'bg-success';
    const td_bg = row_num % 2 === 0 ? "" : "beGray3";
    const isInTwist = product.isInTwist;

    /*
    console.log("product.problem of: " + product.id + " " + product.problem);
    console.log(product.warehouseProblemTest);

   // console.log(isInTwist);
    console.log("------------------------");
    console.log(!product.isInTwist);
    console.log(product.adr_hsh_problem);
    console.log(product.monitoring_hsh_problem);
    console.log(product.name_hsh_problem);
    console.log(product.container_hsh_problem);
    console.log(product.amount_hsh_problem);
    console.log(product.unit_hsh_problem);
    console.log(product.density_hsh_problem);
    console.log(product.class_hsh_problem);
    console.log(product.package_group_hsh_problem);
    console.log(product.obal_sk);
    console.log(!product.obal_sk && product.adr);

    console.log(product.un_num_hsh_problem);
    console.log(product.warehouses[4]);
    console.log(product.warehouses);
    console.log("warehouse problem: " + product.warehouses[4].problem);

    console.log("------------------------");
    */


    const adr_missing_message = isInTwist && product.adr_hsh_problem ? <div className='text-danger'>{t('ADR_info_missing')}!</div> : "";
    const monitoring_bg = isInTwist && product.monitoring_hsh_problem ? " alert-danger " : "";
    const name_bg = isInTwist && product.name_hsh_problem ? " alert-danger " : "";
    const package_bg = isInTwist && product.container_hsh_problem ? " alert-danger " : "";
    const amount_bg = isInTwist && product.amount_hsh_problem ? " alert-danger " : "";
    const unit_bg = isInTwist && product.unit_hsh_problem ? " alert-danger " : "";
    const density_bg = isInTwist && product.density_hsh_problem ? " alert-danger " : "";
    const class_bg = isInTwist && product.class_hsh_problem ? " alert-danger " : "";
    const package_group_bg = isInTwist && product.package_group_hsh_problem ? " alert-danger " : "";
    const un_num_bg = isInTwist && product.un_num_hsh_problem ? " alert-danger " : "";
    const rowSpan = warehouses.reduce((acc, v) => acc + (checkedWH[v] ? 1 : 0), 0) + 1; //začínám na 0, projdu všechny warehousy, a když je cheked, tak přičtu 1
    return (
        <>
            <tr className={td_bg}>
                <td rowSpan={rowSpan} className={'align-middle text-center ' + bg_class}>{product.problem ? <span className='bg-light p-1'>❌</span> : "✅"}</td>
                <td rowSpan={rowSpan} className={'align-middle text-center '}>
                    <h5>
                        {product.id} <br /> <br />
                        <LinkContainer style={{ cursor: "pointer" }} to={{ pathname: "/products/view/" + encodeURIComponent(product.KodProduktu) }}>
                            <a href="!#">{product.KodProduktu}</a>
                        </LinkContainer>
                        {isInTwist ? "" : <span className='text-danger'>{t('product_not_registered_in_twist')}!</span>}
                        {adr_missing_message}
                        <br /> <br />
                        <ShowADR isADR={product.adr} />
                    </h5>
                </td>
                <td className='align-middle text-center bolder '>HSH:</td>
                {isInTwist ?
                    <>
                        <td className={monitoring_bg + 'align-middle text-center bolder'} >{t(monitoring_names[product.pwc_id])}</td>
                        <td></td>
                        <td className={name_bg + 'bolder'}>{product.NazevProduktu}</td>
                        <td className={package_bg + 'text-center bolder'}>{product.obal}</td>
                        <td className={amount_bg + unit_bg + 'text-center bolder'}>{product.amount} {product.unit}</td>
                        <td className={density_bg + 'text-center bolder'}>{product.density} {t('prod-kg')} </td>
                        <td className={'text-center bolder'}>{product.package_weight} {t('prod-kg')}</td>
                        {product.adr ?
                            <>
                                <td className={class_bg + 'text-center bolder'}>{product.trida}</td>
                                <td className={package_group_bg + 'text-center bolder'}>{romanize_packaging_group(product.obal_sk)}</td>
                                <td className={un_num_bg + 'text-center bolder'}>{product.un_cislo}</td>
                            </>
                            :
                            <td colSpan={3} className='text-center'>&mdash;</td>
                        }
                    </>
                    :
                    <td colSpan={10} className="text-danger font-weighht-bold"> {t('product_not_registered_in_twist')}.</td>
                }
            </tr >
            {product.warehouses.map(function (piw, idx) {
                const wid = warehouses[idx];
                if (!checkedWH[wid]) {
                    return <></>;
                }
                return (
                    <tr className={td_bg} key={idx}>
                        <td className='text-center' colSpan={1}><><Boolean value={piw.problem} variant="piw-problem" /> {wid}: </></td>
                        <ProductInWarehouse piw={piw} classa={td_bg} />
                    </tr>
                );
            })}
        </>
    );
}


function ProductInWarehouse({ piw, classa }) {
    const { t } = useTranslation();
    //console.log(piw);
    if (!piw.pcr_k_IDProduktu) {
        return (
            <td className={"text-center text-muted " + classa} colSpan={10}>
                &mdash;
            </td>
        );
    }
    const green_bg = " alert-success ";
    const yellow_bg = " alert-warning ";
    const red_bg = " alert-danger ";
    const isInTwist = piw.isInTwist;
    const name_bg = isInTwist ? green_bg : "";
    const amount_unit_bg = isInTwist && (piw.amount_problem || piw.unit_problem) ? yellow_bg : "";
    const density_bg = piw.density_problem && isInTwist ? yellow_bg : "";
    const package_weight_bg = piw.package_weight_problem && isInTwist ? red_bg : "";
    const safety_sheet_bg = piw.safety_sheet_problem && isInTwist ? red_bg : "";
    const batch_bg = isInTwist && piw.batch_problem ? red_bg : isInTwist && piw.batch_warning ? yellow_bg : "";
    const bbd_bg = isInTwist && piw.bbd_problem ? red_bg : piw.bbd_warning ? yellow_bg : "";
    const package_bg = isInTwist && piw.packaging_problem ? red_bg : "";
    const adr_class_bg = isInTwist && piw.class_problem ? red_bg : "";
    const adr_package_group_bg = isInTwist && piw.package_group_problem ? red_bg : "";
    const adr_un_num_bg = isInTwist && piw.un_num_problem ? red_bg : "";
    return (
        <>
            <td className='align-middle'>
                <Table borderless className='m-0'>
                    <tbody>
                        <tr>
                            <td className={batch_bg + 'border-0 py-0 my-0 align-middle'}>{t('ord-batch')}:&nbsp;<span>{mandatory_format(piw.pcr_gw_check_batch)}</span></td>
                            <td className={bbd_bg + 'border-0 py-0 my-0 align-middle'}>{t('prod-bbd')}:&nbsp;<span>{mandatory_format(piw.pcr_gw_check_bbd)}</span></td>
                            {/** old and out of out visual logic
                          * <td className='border-0 py-0 my-0 align-middle'>{symbol_validity(piw.problem)}</td>
                          */}
                        </tr>
                    </tbody>
                </Table>
            </td>
            <td className={safety_sheet_bg + " align-middle text-center"}>
                {piw.pcr_safety_sheet === null ? "?" : <Boolean value={piw.pcr_safety_sheet} variant="true_false" />}
            </td>
            <td className='align-middle'>
                <span className={name_bg}>{piw.nazevPrefix}</span>{piw.nazevSuffix}
            </td>
            <td className={package_bg + 'text-center align-middle'}>{piw.pcr_k_HSHObal5}</td>
            <td className={amount_unit_bg + 'text-center align-middle'}>{piw.pcr_MnozstviSklMjVObalu} {piw.pcr_KodMjSkl}</td>
            <td className={density_bg + 'text-center align-middle'}>{piw.pcr_HmotnostMjVKg} kg </td>
            <td className={package_weight_bg + 'text-center align-middle'}>{piw.package_weight_wh} kg</td>
            <td className={adr_class_bg + 'text-center align-middle'}>{piw.pcr_TridaADR}</td>
            <td className={adr_package_group_bg + 'text-center align-middle'}>{piw.pcr_k_ADRObalovaSkupina}</td>
            <td className={adr_un_num_bg + 'text-center align-middle'}>{piw.pcr_k_ADRUNCislo}</td>
        </>
    );
}
