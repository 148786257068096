/**
 * Detail of delivery note with checks, timed reloading, rendered infomation.
 * @module delivery-notes/delivery-note-sales-detail
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */

import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Row, Col, Card } from 'react-bootstrap';
import { ResultMessage } from '../comp/resultmessage';
import { date_formatCZ } from '../lib/date-utils';
import { PDItemsMobile } from './delivery-notes-sales-detail-app';
import { his_fetch, his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import { make_checker } from '../comp/checker';
import {
	dnote_validate_items, dnote_validate_KodAdresyDodani,
	dnote_validate_KodDopravce, dnote_validate_DodaciPodminka, dnote_validate_DodaciAdresa,
	dnote_validate_DatumDodani_soft, dnote_validate_DatumDodani, dnote_validate_SingleWarehouse,
	dnote_validate_DodaciAdresaData, dnote_validate_HSH_DIN, dnote_validate_DIN_Phone,
	dnote_validate_Uzavreno, dnote_validate_DopravniProstredek, dnote_validate_TypAdresyFakturacni,
	dnote_validate_DatDokladuPRP9, dnote_validate_DatExpirace, dnote_validate_partner,
	dnote_validate_all_texts, dnote_validate_FakturacniAdresaData, dnote_validate_KodStrediska,
	dnote_validate_KodZakazky, dnote_validate_KodKategorie, check_amount_fits_packages,
	check_expiration,
} from './delivery-note-sales-checks';
import { Emails } from '../emails';
import {
	check_product_hsh_adr, check_product_warehouse_adr,
	check_product_hsh_container, check_product_warehouse_container,
	check_product_hsh_density, check_product_warehouse_density,
	check_card_in_warehouse, package_weight_compare, check_product_hsh_MJ, check_product_wh_MJ,
	check_product_hsh_amount, check_product_wh_amount, check_product_twist_approval,
	check_product_his_approval, check_product_batches_expirations_monitoring, check_product_expiration,
	check_package_weight_wh_values, check_suppliers_addresses
} from '../products/product-checks';
import { get_package_weight_kg, check_package_weight_kg_input } from '../lib/generic-checks';
import { ErrorWrap } from '../comp/errorwrap';
import { SyncProblemHugeAlert, OkOrProblemsCardPD, newest_history_log } from '../comp/problems-card';
import { ProgressPathDeliveryNote } from './delivery-notes-progress-path';
import { storno_allowed_states } from './progress-delivery-note-def';
import { Header, GeneralInfo, DeliveryInfo, AdditionalInfo, PDItemsWeb } from './delivery-notes-sales-detail-web';
import { useTranslation } from 'react-i18next';
var Fraction = require('fraction.js');

//seen all - OK

/** 
 * Returns dashboard of delivery note. 
 * @param {dictionary} userlogin - userlogin needed for fetch
 * @return {type} - components displaying mobile and desktop delivery note
 */
export function DeliveryNoteSales({ userlogin }) {
	const { t } = useTranslation();
	const { kodDokladu } = useParams();

	const [document, setDocument] = useState(null);
	const [emails, setEmails] = useState([]);
	const [loadedStatus, setLoadedStatus] = useState(0);
	const [updatingStatus, setUpdatingStatus] = useState(false); //když posílám PD do skladu - tohle využiju i na storno
	const [mess, setMess] = useState(''); // error message při odesílání do skladu
	const [newHistoryLog, setNewHistoryLog] = useState(null);
	const [oldHistoryLog, setOldHitoryLog] = useState(null);
	const [reloadingPeriod, setReloadingPeriod] = useState(0); //0 = no backgroud reloading, or milisecond

	//console.log(document);
	//console.log(loadedStatus);

	const reloadIt = function () { 
		setLoadedStatus(0);
		setDocument(null);
		setReloadingPeriod(0);
		setMess('');
	}

	useEffect(() => {
		//console.log("useEffect - start");
		//console.log(reloadingPeriod);
		if (document === null ) { //&& loadedStatus === 0
			//console.log("fetch start");
			const running_fetch = his_fetch(
				userlogin,
				[
					{
						uri: "/api/delivery-notes/" + kodDokladu,
						json: true,
						status: setLoadedStatus,
						ok: function (resource, result) {
							// console.log(result);
							setDocument(result.deliveryNote);
							setNewHistoryLog(result.deliveryNote.stockout_logs);
							setOldHitoryLog(result.deliveryNote.stockout_logs);
							setEmails(result.emails);
							//console.log("fetch ok");
							if (result.deliveryNote.stockout_logs.length > 0 && newest_history_log(result.deliveryNote.stockout_logs) !== 20) { //možná ještě nějakou funkci, která nebude dělat timeout, když je to v nefinálních stavech
								setReloadingPeriod(10 * 1000);
							}
						},
						error: function (resource, reason) {
							console.log('err: ' + reason);
							setNewHistoryLog("error");
							setDocument("error");
						}
					}
				]);
			//console.log("useEffect - fetch running");
			return () => {
				//console.log("return useEffect - stopping fetch");
				running_fetch();
				//console.log("return useEffect - finished");
			}
		} else {
			if (reloadingPeriod > 0) {
				//console.log("starting timeout");
				const timeout_id = setTimeout(() => {
					//console.log("tick tock");
					his_fetch(
						userlogin,
						[
							{
								uri: "/api/delivery-notes/" + kodDokladu + "/status-logs",
								json: true,
								ok: function (resource, result) {
									// console.log(result);
									setNewHistoryLog(result.stockout_logs);
									setMess(""); // aby se po úspěšném updatu vynulovala
								},
								error: function (resource, reason) {
									console.log('err: ' + reason);
								}
							}
						]
					);
				}, reloadingPeriod);
				//console.log("timeout set");
				return () => {
					clearTimeout(timeout_id);
				};
			}
		}

	}, [userlogin, reloadingPeriod, newHistoryLog, document, kodDokladu]);

	const historyLog = (newHistoryLog !== null && newHistoryLog !== "error") ? newHistoryLog : oldHistoryLog !== null ? oldHistoryLog : [];

	/*
	console.log(newHistoryLog);
	console.log(oldHistoryLog);
	console.log(document);
	*/

	if (!his_fetch_success(loadedStatus)) {
		return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />;
	}

	function sendIt(command) {
		if (!updatingStatus) {
			setUpdatingStatus(true);
			setMess("");
			his_fetch(
				userlogin,
				[
					{
						uri: "/api/delivery-notes/" + kodDokladu + "/" + command,
						json: true,
						ok: function (resource, result) {
							//console.log('confirmation: ' + result);
							if (result.error) {
								console.log(result);
								setMess([t('sending_fail'), "text-danger"]);
								setUpdatingStatus(false);
							} else {
								console.log(result);
								setMess([t('sent_successfully'), "text-success"]);
								if (command === "send") {
									setReloadingPeriod(10 * 1000);
									// startDataUpdate(); //setReloadingPeriod to nahradilo
								}
							}
						},
						error: function (resource, reason) {
							console.log('error: ' + reason);
							setUpdatingStatus(false);
						},
						args: {
							method: 'PUT'
						}
					}
				]
			)
		}
	}

	function exportIt() {
		sendIt("send");
	}

	function cancelIt() {
		sendIt("send-cancel");
	}


	const PD_sent = historyLog.length > 0;
	const { performCheck, ErrorBadge, TodoList, checksOK } = make_checker();

	// je dodák uzavřen? 
	// má dodák položky?
	// je to podporovaný sklad? 
	// poté zbytek kontrol
	if (!PD_sent) {
		performCheck("closed", "danger",
			function () {
				return dnote_validate_Uzavreno(document);
			});
		const successfully_closed = checksOK("closed");
		if (successfully_closed) {
			performCheck("noItems", "danger",
				function () {
					return dnote_validate_items(document);
				});
			const itemsOK = checksOK("noItems");
			if (itemsOK) {
				performCheck("noWarehousePartner", "danger",
					function () {
						return dnote_validate_partner(document);
					});
				const supportedWh = checksOK("noWarehousePartner");
				if (supportedWh) {
					if ((document) && (document.items) && (document.items.length > 0)) {
						performCheck("warehouseProblems", "danger",
							function () {
								return dnote_validate_KodDopravce(document);
							});
						performCheck("AddressProblems", "danger",
							function () {
								return dnote_validate_DodaciAdresa(document);
							});
						performCheck("singleWarehouse", "danger",
							function () {
								return dnote_validate_SingleWarehouse(document);
							});
					}
					performCheck("deliveryCondition", "danger",
						function () {
							return dnote_validate_DodaciPodminka(document);
						});
					performCheck("deliveryDateSoft", "warning",
						function () {
							return dnote_validate_DatumDodani_soft(document);
						});
					performCheck("deliveryDate", "danger",
						function () {
							return dnote_validate_DatumDodani(document);
						});
					performCheck("DodaciAdresaAllData", "danger",
						function () {
							return dnote_validate_DodaciAdresaData(document);
						});
					performCheck("hsh-din", "danger",
						function () {
							return dnote_validate_HSH_DIN(document);
						});
					performCheck("din-phone", "danger",
						function () {
							return dnote_validate_DIN_Phone(document);
						});
					performCheck("missing-vehicle", "danger",
						function () {
							return dnote_validate_DopravniProstredek(document);
						});
					performCheck("InvoicingAddressType", "danger",
						function () {
							return dnote_validate_TypAdresyFakturacni(document);
						});
					performCheck("InvoicingAddressData", "danger",
						function () {
							return dnote_validate_FakturacniAdresaData(document);
						});
					performCheck("wrongAddress", "danger",
						function () {
							return dnote_validate_KodAdresyDodani(document);
						});
					if (checksOK("closed")) {
						performCheck("CentreCode", "danger",
							function () {
								return dnote_validate_KodStrediska(document);
							});
						performCheck("contractCode", "danger",
							function () {
								return dnote_validate_KodZakazky(document);
							});
						performCheck("categoryCode", "danger",
							function () {
								return dnote_validate_KodKategorie(document);
							});
					}
					performCheck("expiryMissing", "danger",
						function () {
							return dnote_validate_DatExpirace(document);
						});
					// console.log("Controlling PRP9");
					performCheck("PRP9Delivery", "danger",
						function () {
							return dnote_validate_DatDokladuPRP9(document);
						});
					performCheck("missingText", "danger",
						function () {
							return dnote_validate_all_texts(document);
						});
					check_suppliers_addresses(performCheck, "", document.customer_data, false, true);
				}
			}
		}
	}

	//ppj - proddejní produkt jednotka a bere se z položky PD
	// spj - skladovací produkt jednotka - produktová kodMjSkl daného produktu a k ní mj hmotnosti 


	//console.log(document.customer_data);

	const preprocessedItems = document.items.map(function (item) {

		// 666: "Počítání hmotnosti obalu v kg" - zobrazení i pro check
		//console.log(item);
		const [packageWeightInputsOK_HSH] = check_package_weight_kg_input(item.spj_HmotnostMj, item.spj_MjHmotnostiMj, item.pjo_MnozstviSklMjVObalu); //spj_MjHmotnostiMj je správně
		const package_weight_kg_hsh = packageWeightInputsOK_HSH ? get_package_weight_kg(item.spj_HmotnostMj, item.spj_MjHmotnostiMj, item.pjo_MnozstviSklMjVObalu) : null;
		const [packageWeightInputsOK_wh] = check_package_weight_kg_input(item.pcr_HmotnostMjVKg, "kg", item.pcr_MnozstviSklMjVObalu); //ze skladu to nechodí
		const package_weight_kg_wh = packageWeightInputsOK_wh ? get_package_weight_kg(item.pcr_HmotnostMjVKg, "kg", item.pcr_MnozstviSklMjVObalu) : null;
		// const worth_display1 = item.pjo_MnozstviSklMjVObalu && item.spj_HmotnostMj;
		// const package_weight_kg_hsh_count = Fraction(item.spj_HmotnostMj).mul(Fraction(item.pjo_MnozstviSklMjVObalu));
		/////////// ------------------////
		// když nahradí vnitřek tohoto checku check_package_weight_wh_values tímto: check_package_weight_kg_input, tak se nikdy nespustí check package_weight_compare a tímto mi nevadí 2x &mdash;
		///////////---------------------///
		// const package_weight_kg_hsh = worth_display1 ? String(package_weight_kg_hsh_count) : <>&mdash;</>; 
		//const worth_display2 = item.pcr_MnozstviSklMjVObalu && item.pcr_HmotnostMjVKg;
		// const package_weight_kg_wh_count = Fraction(item.pcr_HmotnostMjVKg).mul(Fraction(item.pcr_MnozstviSklMjVObalu));
		//const package_weight_kg_wh = worth_display2 ? String(package_weight_kg_wh_count) : <>&mdash;</>;
		//---------------------
		const package_quantity_count = Fraction(item.svs_MnozstviSkl).div(item.pjo_MnozstviSklMjVObalu);
		const package_quantity = String(package_quantity_count);
		const altNames = [item.pp_ExtKodProduktu, item.pp_NazevProduktu]
			.filter((s) => (s || "").length > 0);
		const altNamesStr = altNames.length > 0 ? altNames.join(", ") : false;

		if (!PD_sent) {

			if (checksOK("noWarehousePartner")) {
				check_expiration(performCheck, item.p_k_IDProduktu, item.ps_DatExpirace, true);
				check_product_twist_approval(performCheck, item.p_k_IDProduktu, item.approved_in_twist, true);
				check_product_his_approval(performCheck, item.p_k_IDProduktu, item.needs_approval, item.approved_in_his, true);
				const configured = parseInt(item.pwc_id || "0") > 0;
				check_product_batches_expirations_monitoring(performCheck, + item.p_k_IDProduktu, configured, true);
				check_product_expiration(performCheck, + item.p_k_IDProduktu, item.pwc_id, item.pwc_months, true);

				check_product_hsh_density(performCheck, "hsh-" + item.p_k_IDProduktu, item.spj_HmotnostMj, item.spj_MjHmotnostiMj);
				check_product_hsh_amount(performCheck, "hsh-" + item.p_k_IDProduktu, item.pjo_MnozstviSklMjVObalu);
				check_product_hsh_MJ(performCheck, "hsh-" + item.p_k_IDProduktu, item.p_KodMjSkl, true);
				check_product_hsh_container(performCheck, "hsh-" + item.p_k_IDProduktu, item.co_k_HSHObal5);
				check_product_hsh_adr(performCheck, "hsh-" + item.p_k_IDProduktu, item.p_k_ADR,
					item.kcunc_TridaADR, item.p_k_ADRObalovaSkupina, item.p_k_ADRUNCislo);

				const soFarSoGood = checksOK('hsh-' + item.p_k_IDProduktu + '$');

				if (soFarSoGood) {
					check_card_in_warehouse(performCheck, item.svp_KodKnihy + "-" + item.p_k_IDProduktu, item.pcr_repeats);
					if (checksOK("missing-card-" + item.svp_KodKnihy + "-" + item.p_k_IDProduktu)) {
						check_product_warehouse_density(performCheck, "wh-" + item.svp_KodKnihy + "-" + item.p_k_IDProduktu, item.pcr_HmotnostMjVKg, item.spj_HmotnostMj, true);
						check_product_wh_amount(performCheck, "wh-" + item.svp_KodKnihy + "-" + item.p_k_IDProduktu, item.pcr_MnozstviSklMjVObalu, item.pjo_MnozstviSklMjVObalu, true);
						check_product_wh_MJ(performCheck, "wh-" + item.svp_KodKnihy + "-" + item.p_k_IDProduktu, item.pcr_KodMjSkl, item.p_KodMjSkl, true);
						check_package_weight_wh_values(performCheck, "wh-" + item.svp_KodKnihy + "-" + item.p_k_IDProduktu, item.pcr_MnozstviSklMjVObalu, item.pcr_HmotnostMjVKg); // může to být špatně, ale nesmí to chybět
						if (checksOK("missing-amount-or-density-wh-" + item.svp_KodKnihy + "-" + item.p_k_IDProduktu)) {
							package_weight_compare(performCheck, "wh-" + item.svp_KodKnihy + "-" + item.p_k_IDProduktu, package_weight_kg_hsh, package_weight_kg_wh);
						}
						check_product_warehouse_container(performCheck, "wh-" + item.svp_KodKnihy + "-" + item.p_k_IDProduktu, item.co_k_HSHObal5,
							item.svp_KodKnihy, item.pcr_k_HSHObal5, true);
						check_product_warehouse_adr(performCheck, "wh-" + item.svp_KodKnihy + "-" + item.p_k_IDProduktu, item.p_k_ADR,
							item.kcunc_TridaADR, item.p_k_ADRObalovaSkupina, item.p_k_ADRUNCislo,
							item.pcr_TridaADR, item.pcr_k_ADRObalovaSkupina, item.pcr_k_ADRUNCislo, true);
					}
				}
				check_amount_fits_packages(performCheck, item.svs_IDPolozkySerie, package_quantity_count, item.svs_MnozstviSkl, t);
			}
		}
		const no_card = !checksOK("missing-card-" + item.svp_KodKnihy + "-" + item.p_k_IDProduktu); //když to neproběhne, hlásí false, to je dobré
		//console.log(no_card);
		const redish = no_card ? " bg-red" : "";

		return {
			...item,
			package_weight_kg_hsh: package_weight_kg_hsh,
			package_weight_kg_wh: package_weight_kg_wh,
			package_quantity: package_quantity,
			altNamesStr: altNamesStr,
			no_card: no_card,
			redish: redish,

		};
	});

	const readyToExport = checksOK() && historyLog.length === 0;

	if ((document === null) || (document.header === false)) {
		var message = t('deno_loading_err') + kodDokladu + t('please_call_support');
		if ((document !== null) && (document.items) && (document.items.length === 0)) {
			message += t('no_items_deno');
		}
		return <ResultMessage message={message} variant="danger" reloadButton={reloadIt} />
	}
	const KodDokladu = document.KodDokladu;
	if (document.environment === "production") {
		// Must NOT be 977
		if (KodDokladu.match(/^PD20[2-9][0-9]977/)) {
			return <ResultMessage message={t('testing_deno')} variant="danger" />
		}
	} else if (document.environment === "testing") {
		// Must be 977
		if (KodDokladu.match(/^PD20[2-9][0-9]977/)) {
		} else {
			return <ResultMessage message={t('not_testing_deno')} variant="danger" />
		}
	} // else (development) it can be anything

	const header = document.header;
	const KodDopravce = header.pdd_KodDopravce;
	const dnote = document;
	const realDatumDodani = ((KodDopravce === "SAMOODBĚR") ||
		(KodDopravce === "HSHLOGISTIKA")) ?
		(dnote.text_prp9 ? dnote.text_prp9.pdt_TextText : "-") : date_formatCZ(header.pdd_DatDokladu);
	const cancel_conditions_ok = document.partner === "LF" && document.header.pdd_Zruseno && document.items.length === 0;
	//console.log(cancel_conditions_ok);
	const canBeCanceled = storno_allowed_states.includes(newest_history_log(historyLog)) && cancel_conditions_ok;
	const isADR = document.items.filter((item) => item.kcunc_TridaADR).length > 0;
	const syncProblem = document.header.twist_sync_problem;

	//console.log(document);

	return (
		<ErrorWrap>
			{syncProblem ?
				<SyncProblemHugeAlert sentence={t('blocked_deno')} />
				: ""
			}
			<Header reloadIt={reloadIt} kodDokladu={document.KodDokladu} document={document} partner={document.partner} />


			<Row className='mt-4'>
				<Col xs="12" md="3">
					<OkOrProblemsCardPD historyLog={historyLog} todo={<TodoList />} readyToExport={readyToExport}
						exportIt={exportIt} cancelIt={cancelIt} mess={mess} updatingStatus={updatingStatus}
						stornoTime={canBeCanceled}
					/>
				</Col>
				<Col className='pt-2'>
					<ProgressPathDeliveryNote one_history={historyLog} userlogin={userlogin} 
					//startDataUpdate={startDataUpdate}
					setReloadingPeriod={setReloadingPeriod}
					 />
				</Col>
			</Row>
			<Row className='mt-3'>
				<Col xs="12" md='6' xl="4">
					<GeneralInfo ErrorBadge={ErrorBadge}
						datumDokladu={document.header.pdd_DatDokladu} vystavil={document.header.pdd_KodPracovnikaVystavil}
						kodPartnera={document.header.pdd_KodPartnera} nazevPartnera={document.header.tp_NazevPartnera}
						typAdresyFaktur={document.header.pa2_TypAdresy} nazevAdresy={document.header.pa2_NazevAdresy}
						adresa={document.header.pa2_Adresa} adresa2={document.header.pa2_Adresa2} misto={document.header.pa2_Misto}
						kodStatu={document.header.pa2_KodStatu} psc={document.header.pa2_psc} stredisko={document.header.svd_KodStrediska}
						zakazka={document.header.svd_KodZakazky} kategorie={document.header.svd_KodKategorie} uzavreno={document.header.pdd_Uzavreno}
						schvaleno={document.header.pdd_Schvaleno} poznamka={document.header.svd_Poznamka}
						kodAdresy={document.header.pa2_KodAdresy} />
				</Col>
				<Col xs="12" md='6' xl="4" className='pb-3'>
					<DeliveryInfo ErrorBadge={ErrorBadge}
						realDatumDodani={realDatumDodani} KodDopravce={KodDopravce} kodDoprProstredku={header.pdd_KodDopravnihoProstredku}
						kodDodaciPodminky={header.pdd_KodDodaciPodminky} dopravce={document.header.pdd_KodDopravce}
						kodAdresyDodani={document.header.pdd_KodAdresyDodani} nazevAdresy={document.header.pa_NazevAdresy}
						adresa={document.header.pa_Adresa} adresa2={document.header.pa_Adresa2} misto={document.header.pa_Misto}
						kodStatu={document.header.pa_KodStatu} psc={document.header.pa_psc} poznamka={document.header.pa_Poznamka}
						telefon={document.header.pa_Telefon} mobil={document.header.pa_Mobil} zastupcePartnera={document.header.pdd_ZastupcePartnera}
					/>
				</Col>
				<Col xs="12" md='12' xl="4" className='pb-4'>
					<AdditionalInfo ErrorBadge={ErrorBadge}
						isADR={isADR} document={document} />
				</Col>
			</Row>
			<Items items={preprocessedItems} header={header} ErrorBadge={ErrorBadge} />
			{emails.length > 0 ?
				<>
					<Card className='mt-4 mb-4'>
						<Card.Body className='px-0 pb-0'>
							<Emails pagesize={5} existingEmails={emails} noFetch optionalHeader={t('deno-mails') + " " + KodDokladu} />
						</Card.Body>
					</Card>
				</>
				: ""}
		</ErrorWrap>
	);
}

function Items({ items, header, ErrorBadge }) {
	return (
		<ErrorWrap>
			<Card className='mb-4 mt-2'>
				<Card.Body className='p-0 m-0'>
					<PDItemsWeb items={items} header={header} ErrorBadge={ErrorBadge} />
					<PDItemsMobile items={items} header={header} ErrorBadge={ErrorBadge} />
				</Card.Body>
			</Card>
		</ErrorWrap>
	);
}





