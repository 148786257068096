/**
 * Statistics of delivery notes sending by users, warehouses, statistics about problems 
 * @module delivery-notes/delivery-note-sales-statistics
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React, { useState, useEffect} from 'react';
import { MonthYearDropdown } from '../comp/month-year-dropdown';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import { his_fetch, his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import { platneRoky, monthsOfYear } from '../lib/month-year-utils';
import {
	getDaysOfMonth, getWeeksOfYear, getMonthsOfYear, get_users,
	get_warehouses
} from '../lib/statistics-utils';
import { StatsTableWeekly } from '../comp/statistics';
import { date_formatMM_YYYY } from '../lib/date-utils';
import { Button } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';

//seen all - OK


export function DeliveryNotesSalesStatistics({ userlogin }) {
	const { t } = useTranslation();

	const [statistics, setStatistics] = useState(null);
	const [today, setToday] = useState(new Date());
	const [viewSpan, setViewSpan] = useState(1);
	const [statsOf, setStatsOf] = useState(1);
	const [loadedStatus, setLoadedStatus] = useState(0);

	const reloadIt = () => {
		setStatistics(null);
		setLoadedStatus(0);
	}

	useEffect(() => {
		if (statistics === null) {
			const running_fetch = his_fetch(
				userlogin,
				[
					{
						uri: "/api/delivery-notes/stats",
						json: true,
						status: setLoadedStatus,
						ok: function (resource, result) {
							setStatistics(result);
							//console.log(result);
						},
						error: function (resource, reason) {
							console.log('err: ' + reason);
							setStatistics("error");
						}
					}
				]
			)
			return () => {
				running_fetch();
			}
		}
	}, [userlogin, statistics]);

	if (!his_fetch_success(loadedStatus)) {
		return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />;
	}

	const month_title = date_formatMM_YYYY(today);
	const year_title = today.getFullYear();
	const minDate = statistics.requests_by_users[0]["cday"];
	const maxDate = new Date();
	const years = platneRoky(minDate, maxDate);
	const months = monthsOfYear(minDate, maxDate, today.getFullYear());
	//console.log(months);

	const handleChangeView = function (val) {
		setViewSpan(val);
		const actYear = new Date().getFullYear();
		const prevYear = today.getFullYear();
		if (actYear > prevYear) { //hodila jsem se na minulý rok
			today.setMonth(parseInt(months[0] - 1));
		} else { // hodila jsem se na budoucí rok a chci být nejblíž současnému měsíci
			today.setMonth(new Date().getMonth());
		}
		//console.log(today);
	}

	const periods = viewSpan === 1 ? getDaysOfMonth(today) : viewSpan === 2 ? getWeeksOfYear(today) : getMonthsOfYear(today);
	const type_title = statsOf === 1 ? t('deno-by_user') : t('deno-by_warehouse');
	const stats_requests = statsOf === 1 ? statistics.requests_by_users : statistics.requests_by_warehouses;
	const stats_resolves = statsOf === 1 ? statistics.resolved_by_users : statistics.resolved_by_warehouses;
	const get_keys = statsOf === 1 ? get_users : get_warehouses;
	const key = statsOf === 1 ? "username" : "warehouse_id";
	const objectname = statsOf === 1 ? t('not-user') : t('warehouse');

	//console.log(statistics);
	//console.log(today);
	return (
		<ErrorWrap>
			<Nav variant="tabs" className="mb-3" activeKey={statsOf}>
				<Nav.Link eventKey="1" onClick={() => setStatsOf(1)}>{t('menu3-deno-stat-user')}</Nav.Link>
				<Nav.Link eventKey="2" onClick={() => setStatsOf(2)}>{t('menu3-deno-stat-wh')}</Nav.Link>
			</Nav>
			<div className='bg-white p-3'>
				<Button size="sm" className="me-2 mb-2 d-inline" onClick={reloadIt}><Image src="/img/reload.svg" height="19" /></Button>
				<h4 className="d-inline pb-5">{t('deno-stats')} {viewSpan === 1 ? month_title : year_title} {t('according_to')} {type_title}</h4>
				<div className="float-end">
					<Form.Group controlId="display">
						<Form.Check inline name="viewSpan" type="radio" value={1} id='1' label={t('month_by_days')} onClick={() => handleChangeView(1)} defaultChecked={viewSpan === 1} />
						<Form.Check inline name="viewSpan" type="radio" value={2} id='2' label={t('year_by_weaks')} onClick={() => handleChangeView(2)} defaultChecked={viewSpan === 2} />
						<Form.Check inline name="viewSpan" type="radio" value={3} id='3' label={t('year_by_month')} onClick={() => handleChangeView(3)} defaultChecked={viewSpan === 3} />
					</Form.Group>
					<div className="float-end mt-3">
						{viewSpan === 1 ?
							<MonthYearDropdown minDate={minDate} maxDate={maxDate} setToday={setToday} today={today} years={years} months={months} />
							:
							<MonthYearDropdown minDate={minDate} maxDate={maxDate} setToday={setToday} today={today} years={years} months={months} yearOnly />
						}
					</div>
				</div>

				<div>&nbsp;</div>

				<h5>{t('deno-requirements')}</h5>
				<StatsTableWeekly stats={stats_requests} weeks={periods} get_keys={get_keys} stat_key={key} objectname={objectname} viewType={viewSpan} date={today} />
				<h5>{t('deno-solving_wh_problems')}</h5>
				<StatsTableWeekly stats={stats_resolves} weeks={periods} get_keys={get_keys} stat_key={key} objectname={objectname} viewType={viewSpan} date={today} />
			</div>
		</ErrorWrap>
	)
}