/**
 * Displaying of items of order puchase in app version
 * @module orders/order-purchase-detail-app
 * @author Lucie Zdeňková <lucie.zdenek@trustica.cz>
 */

import { LinkContainer } from 'react-router-bootstrap';
import { Table, Row, Col } from 'react-bootstrap';
import { NO_PIN_generate } from '../comp/pin';
import { make_product_check_tag } from '../products/product-checks';
import { DensityAmountUnitWeightView, ProductContainerView, ProductADRView } from '../products/product-view';
import { useTranslation } from 'react-i18next';

//seen all - OK

/**
 * Table of items for mobile.
 * 
 * @param {any} items - items in order purchase
 * @param {boolean} authorizedUser - if true, PIN is also displayed
 * @param {any} ErrorBadge - component showing error numbers at right places
 * @returns {component}
 */
export function ItemsofNOPurchaseApp({ item, authorizedUser, ErrorBadge }) {
    const { t } = useTranslation();

    return (
        <Table borderless className='m-0'>
            <tbody>
                <tr>
                    <td>
                        <Row className='mb-1'>
                            <Col className={item.bg_item_green_red}>&nbsp;</Col>
                        </Row>
                        <Row>
                            <Col>
                                <span className='text-muted'>{t('not-prod_id')} </span> {item.p_k_IDProduktu}
                                <ErrorBadge tag={make_product_check_tag(item.nop_IDPolozky, "duplicity")} /></Col>
                            {authorizedUser ?
                                <>
                                    <Col className='text-center'><span className='text-muted'>{t('ord-item_id')} </span>{item.nop_IDPolozky}</Col>
                                    <Col className='text-end'><span className='text-muted'>PIN </span>{NO_PIN_generate(item.nop_IDPolozky, 1)}</Col>
                                </>
                                : <></>}
                        </Row>
                        <span className='text-muted'>{t('code')} </span>
                        <LinkContainer to={{ pathname: "/products/view/" + encodeURIComponent(item.nop_KodProduktu) }}>
                            <a href="!#">{item.nop_KodProduktu}</a>
                        </LinkContainer><br />
                        <Row className="g-0">
                            <Col xs="auto" className='text-end text-muted'>
                                {t('ord-amount_measure_unit')} <br />
                                {t('ord-accepted_by_wh')} <br />
                                {t('ord-remaining')} <br />
                            </Col>
                            <Col className='text-end ms-2 me-1' xs="auto">
                                {item.nop_Mnozstvi} <br />
                                {item.nop_Prijato} <br />
                                {item.nop_ZbyvaPrijmout}
                            </Col>
                            <Col xs="auto">
                                {item.nop_KodMj}<ErrorBadge tag={make_product_check_tag(item.nop_IDPolozky, "NO-unit-uniformity")} /><br />
                                {item.nop_KodMj}<br />
                                {item.nop_KodMj}
                            </Col>
                        </Row>
                        <Table className='mt-2'>
                            <tbody>
                                <tr>
                                    <td className='align-middle border text-center'>
                                        H<br />S<br />H<br />
                                        <ErrorBadge tag={make_product_check_tag(item.p_k_IDProduktu, "twistApproval")} />
                                        <ErrorBadge tag={make_product_check_tag(item.p_k_IDProduktu, "hisApproval")} />
                                        <ErrorBadge tag={make_product_check_tag(item.p_k_IDProduktu, "configured")} />
                                        <ErrorBadge tag={make_product_check_tag(item.p_k_IDProduktu, "monthExpiration")} />
                                    </td>
                                    <td className='border'>
                                        {t('name')}: {item.nop_NazevProduktu ? item.nop_NazevProduktu : <>&mdash;</>}
                                        <Table size="sm" className='p-0 mb-1'>
                                            <tbody>
                                                <ProductContainerView id={"hsh-" + item.p_k_IDProduktu}
                                                    container={item.co_k_HSHObal5}
                                                    ErrorBadge={ErrorBadge} mobile />
                                                <DensityAmountUnitWeightView
                                                    id={"hsh-" + item.p_k_IDProduktu} density={item.pj_HmotnostMj}
                                                    densityUnit={item.pj_MjHmotnostiMj} amount={item.pjo_MnozstviSklMjVObalu}
                                                    unit={item.p_KodMjSkl} packageWeight={item.package_weight_kg_hsh}
                                                    ErrorBadge={ErrorBadge} mobile
                                                />
                                            </tbody>
                                        </Table>
                                        <ProductADRView id={"hsh-" + item.p_k_IDProduktu}
                                            TridaADR={item.kcunc_TridaADR}
                                            k_ADRObalovaSkupina={item.p_k_ADRObalovaSkupina}
                                            k_ADRUNCislo={item.p_k_ADRUNCislo}
                                            ErrorBadge={ErrorBadge} mobile
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className='align-middle border'>
                                        {item.nop_KodKnihySkladu ? <>{item.nop_KodKnihySkladu}</> : ""}<br />
                                        <ErrorBadge tag={make_product_check_tag(item.nop_KodKnihySkladu + "-" + item.p_k_IDProduktu, "missing-card")} />
                                        <ErrorBadge tag={make_product_check_tag(item.nop_KodKnihySkladu + "-" + item.p_k_IDProduktu, "missing-values")} />
                                        <ErrorBadge tag={make_product_check_tag(item.nop_KodKnihySkladu + "-" + item.p_k_IDProduktu, "wh-existance")} />
                                    </td>
                                    <td className='border'>
                                        {t('name')}: <span className={item.redish}>{item.pcr_NazevProduktu ? item.pcr_NazevProduktu : <>&mdash;</>}</span>
                                        <Table borderless size="sm" className='p-0 mb-0'>
                                            <tbody>
                                                <ProductContainerView id={"wh-" + item.nop_KodKnihySkladu + "-" + item.p_k_IDProduktu}
                                                    wid={item.nop_KodKnihySkladu}
                                                    container={item.pcr_k_HSHObal5}
                                                    remap border
                                                    ErrorBadge={ErrorBadge}
                                                    bg={!item.is_card} mobile />
                                                <DensityAmountUnitWeightView
                                                    id={"wh-" + item.nop_KodKnihySkladu + "-" + item.p_k_IDProduktu} density={item.pcr_HmotnostMjVKg}
                                                    densityUnit="kg" amount={item.pcr_MnozstviSklMjVObalu}
                                                    unit={item.pcr_KodMjSkl} packageWeight={item.package_weight_kg_wh}
                                                    ErrorBadge={ErrorBadge} bg={!item.is_card} mobile
                                                />
                                            </tbody>
                                        </Table>
                                        <hr className='m-1' />
                                        <ProductADRView id={"wh-" + item.nop_KodKnihySkladu + "-" + item.p_k_IDProduktu}
                                            TridaADR={item.pcr_TridaADR}
                                            k_ADRObalovaSkupina={item.pcr_k_ADRObalovaSkupina}
                                            k_ADRUNCislo={item.pcr_k_ADRUNCislo}
                                            romanized={true}
                                            ErrorBadge={ErrorBadge}
                                            bg={!item.is_card} mobile />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </td>
                </tr>
            </tbody>
        </Table>
    );
}