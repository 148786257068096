
import { LinkContainer } from 'react-router-bootstrap';
import { Routes, Route } from "react-router-dom";
import { ChooseCategory } from './comp/choose-category';
import { Nav } from 'react-bootstrap';
import { ErrorWrap } from './comp/errorwrap';
import { FTPSent, FTPReceived2 } from './ftp';
import { useTranslation } from 'react-i18next';

//seen all - OK

/** 
 * Switch and navigation for FTP menu. 
 *  
 * @param {dictionary} userlogin - information about logged user
 * @return - COMPONENT navigation with options: Přijaté | Odeslané
 */
export function FTPArchivComp({ userlogin }) {
    const { t } = useTranslation();

    return (
        <ErrorWrap>
            <Nav variant="tabs" className="mb-3">
                <LinkContainer to="/system/ftp/received">
                    <Nav.Link eventKey="/system/ftp/received">{t('menu3-sys-ftp-rec')}</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/system/ftp/sent">
                    <Nav.Link eventKey="/system/ftp/sent">{t('menu3-sys-ftp-sent')}</Nav.Link>
                </LinkContainer>
            </Nav>
            <Routes>
                <Route path="" element={<ChooseCategory />} />
                <Route path="received" element={<FTPReceived2 userlogin={userlogin} />} />
                <Route path="sent" element={<FTPSent userlogin={userlogin} />} />
            </Routes>
        </ErrorWrap>
    );
}