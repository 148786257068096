/**
 * List of all notes made in HIS for all objects
 * @module notes/notes-list
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React, { useState, useCallback } from 'react';
import Table from 'react-bootstrap/Table';
import { date_time_format } from '../lib/date-utils';
import { LinkContainer } from 'react-router-bootstrap';
import Form from 'react-bootstrap/Form';
import { Pager } from '../comp/pager';
import notes_comms from './notes-comms.json';
import { his_fetch } from '../comp/FetchLoader';
import { filter_rule } from '../lib/utils';
import { MultipleSelect } from '../comp/multiple-select';
import { ErrorWrap } from '../comp/errorwrap';
import { DynamicLoad, LoadingDataInfo } from '../comp/dynamic-load';
import { useTranslation } from 'react-i18next';

//seen all - OK

const object_types = {
	1: "partner",
	2: "not-invo",
	3: "product",
	4: "ord-batch"
};

export function NotesList({ userlogin, diff_object_types = false }) {
	const { t } = useTranslation();

	const dictionaryOfNotesValues = Object.keys(notes_comms).reduce((acc, v) => ({ ...acc, [v]: true }), {});
	const dictionaryOfNotesValuesFalse = Object.keys(notes_comms).reduce((acc, v) => ({ ...acc, [v]: false }), {});

	const dictionaryOfNoteTypeValues = Object.keys(object_types).reduce((acc, v) => ({ ...acc, [v]: true }), {});
	const dictionaryOfNoteTypeValuesFalse = Object.keys(object_types).reduce((acc, v) => ({ ...acc, [v]: false }), {});

	const final_dict_types = diff_object_types ? diff_object_types : dictionaryOfNoteTypeValues;

	const [notes, setNotes] = useState([]);
	const [totalCount, setTotalCount] = useState(null);
	const [oldest, setOldest] = useState(null);
	const [filterCode, setFilterCode] = useState("");
	const [filterCode2, setFilterCode2] = useState("");
	const [filterCode3, setFilterCode3] = useState("");
	const [filterUser, setFilterUser] = useState("");
	const [filterText, setFilterText] = useState("");
	const [filterName, setFilterName] = useState("");
	const [filterID, setFilterID] = useState("");
	const [offset, setOffset] = useState(0);
	const [loadedStatus, setLoadedStatus] = useState(0);
	const [checkedType, setCheckedType] = useState(final_dict_types);
	const [checkedNotes, setCheckedNotes] = useState(dictionaryOfNotesValues);
	const [archived, setArchived] = useState(1);

	const loadData = useCallback(function (startDate, endDate, result_received, reload = false) {
		return (
			his_fetch(
				userlogin,
				[
					{
						uri: "/api/notes/range/" + startDate + "/" + endDate,
						json: true,
						status: setLoadedStatus,
						ok: function (resource, result) {
							//console.log(result);
							if (result.error) {
								result_received(false);
							} else {
								setNotes(notesCat => (reload ? [] : notesCat).concat(result.result));
								setTotalCount(result.total_count);
								setOldest(result.oldest.created);
								result_received(true);
							}
						},
						error: function (resource, reason) {
							console.log('err: ' + reason);
							result_received(false);
						}
					}
				]
			))
	}, [userlogin])

	const cleanFilters = () => {
		setFilterCode("");
		setFilterCode2("");
		setFilterCode3("");
		setFilterUser("");
		setFilterText("");
		setFilterName("");
		setFilterID("");
		setCheckedType(dictionaryOfNoteTypeValues);
		setCheckedNotes(dictionaryOfNotesValues);
		setArchived(1);
	}

	const handleChangeCode = (e) => {
		setFilterCode(e.target.value);
		setOffset(0);
	}
	const handleChangeCode2 = (e) => {
		setFilterCode2(e.target.value);
		setOffset(0);
	}
	const handleChangeCode3 = (e) => {
		setFilterCode3(e.target.value);
		setOffset(0);
	}
	const handleChangeUser = (e) => {
		setFilterUser(e.target.value);
		setOffset(0);
	}
	const handleChangeText = (e) => {
		setFilterText(e.target.value);
		setOffset(0);
	}
	const handleChangeName = (e) => {
		setFilterName(e.target.value);
		setOffset(0);
	}
	const handleChangeID = (e) => {
		setFilterID(e.target.value);
		setOffset(0);
	}
	const handleChangeArchived = function (val) {
		setArchived(val);
	}

	const filteredNotes = notes.filter(
		function (note) {
			const archivedNotes = note.h_username !== null ? 3 : 2;
			return (
				// KodStrediska
				(filter_rule(filterCode, note.KodStrediska, true)) &&
				(filter_rule(filterUser, note.username, true)) &&
				(filter_rule(filterCode2, note.object_code, true)) &&
				(filter_rule(filterCode3, note.KodPartnera, true) || filter_rule(filterCode3, note.object_code2, true) || filter_rule(filterCode3, note.KodProduktu, true)) &&
				(filter_rule(filterName, note.NazevPartnera, true) || filter_rule(filterName, note.NazevProduktu, true)) &&
				(filter_rule(filterText, note.note, true)) &&
				(filter_rule(filterID, note.object_code, true)) &&
				(checkedNotes[note.commId]) &&
				(checkedType[note.object_type]) &&
				(archived === 1 || archived === archivedNotes)
			);
		});
	const notesList = filteredNotes.slice(offset, offset + 20);
	const notes_labels = Object.keys(notes_comms).reduce((acc, v) => ({ ...acc, [v]: notes_comms[v]['name'] }), {});

	return (
		<ErrorWrap>
			<DynamicLoad period='1y' loadData={loadData}
				filtered_data={filteredNotes} data={notes}
				offset={offset} setOffset={setOffset}
				totalCount={totalCount} oldest={oldest}
				cleanFilters={cleanFilters}
			/>
			<Form.Group controlId="display" className='mb-0 mt-1'>
				<Form.Check inline name="viewSpan" type="radio" value={1} id='1' label={t('all')} onClick={() => handleChangeArchived(1)} defaultChecked={archived === 1} />
				<Form.Check inline name="viewSpan" type="radio" value={2} id='2' label={t('not-recent')} onClick={() => handleChangeArchived(2)} defaultChecked={archived === 2} />
				<Form.Check inline name="viewSpan" type="radio" value={3} id='3' label={t('not-archived')} onClick={() => handleChangeArchived(3)} defaultChecked={archived === 3} />
			</Form.Group>

			<Table size="sm" striped hover>
				<thead className="beGray">
					<tr>
						<th style={{ maxWidth: "90px", minWidth: "90px" }} scope="col" className='pb-3'>
							<p className="mb-1">{t('not-topic')}</p>
							<MultipleSelect checked={checkedNotes} setChecked={setCheckedNotes}
								dictionaryTrue={dictionaryOfNotesValues} dictionaryFalse={dictionaryOfNotesValuesFalse}
								itemsNames={notes_labels} setOffset={setOffset} id="filterType" withoutNumber />
						</th>
						<th width="9%" scope="col" className="text-center align-middle">{t('date_time')}</th>
						<th width="5%" scope="col" className="text-center pb-3">
							<Form.Label>{t('biz-centre')}</Form.Label>
							<Form.Group controlId="filterCode" >
								<Form.Control type="text" placeholder="&#128269; " value={filterCode} onChange={handleChangeCode} />
							</Form.Group>
						</th>
						<th width="10%" scope="col" className="text-center pb-3">
							<Form.Label>{t('not-user')}</Form.Label>
							<Form.Group controlId="filterUser">
								<Form.Control type="text" placeholder="&#128269; " value={filterUser} onChange={handleChangeUser} />
							</Form.Group>
						</th>
						<th width="5%" scope="col" className=" align-middle pb-2">
							<p className="mb-1 pb-1">{t('not-type')}</p>
							<MultipleSelect checked={checkedType} setChecked={setCheckedType}
								dictionaryTrue={dictionaryOfNoteTypeValues} dictionaryFalse={dictionaryOfNoteTypeValuesFalse}
								itemsNames={object_types} setOffset={setOffset} id="filterType2" withoutNumber />
						</th>
						<th className='pb-3' scope="col">
							<Form.Label>{t('code')}</Form.Label>
							<Form.Group controlId="filterCode3">
								<Form.Control type="text" placeholder="&#128269;" value={filterCode3} onChange={handleChangeCode3} />
							</Form.Group>
						</th>
						<th scope="col" className="text-center pb-3">
							<Form.Label>{t('name')}</Form.Label>
							<Form.Group controlId="filterName">
								<Form.Control type="text" placeholder="&#128269; " value={filterName} onChange={handleChangeName} />
							</Form.Group>
						</th>
						<th scope="col" className="text-center pb-3">
							<Form.Label>{t('not-invo')}</Form.Label>
							<Form.Group controlId="filterCode2">
								<Form.Control type="text" placeholder="&#128269; " value={filterCode2} onChange={handleChangeCode2} />
							</Form.Group>
						</th>
						<th className='pb-3'>
							<Form.Label>{t('not-prod_id')}</Form.Label>
							<Form.Group controlId="filterID">
								<Form.Control type="text" placeholder="&#128269; " value={filterID} onChange={handleChangeID} />
							</Form.Group>
						</th>
						<th className="text-center pb-3">
							<Form.Label>{t('note')}</Form.Label>
							<Form.Group controlId="filterText">
								<Form.Control type="text" placeholder="&#128269; " value={filterText} onChange={handleChangeText} />
							</Form.Group>
						</th>
					</tr>
				</thead>
				<tbody>
					{
						notesList.map(function (note) {
							const commId = note.commId;
							const commdef = notes_comms[commId];
							const variant = commdef.variant;
							const cname = commdef.text;
							const emoji = commdef.emoji;
							var textclass = "align-middle";
							if (note.h_username !== null) {
								textclass = "cross-out align-middle";
							}
							var linkto = {};
							if (note.object_type === 1) {
								// Partner
								linkto = { pathname: "/partners/list/" + note.object_code + "/detail" }
							} else if (note.object_type === 2) {
								// Faktura
								linkto = { pathname: "/partners/invoices-issued/" + note.object_code }
							} else if (note.object_type === 3) {
								//Product
								linkto = { pathname: "/products/view/" + encodeURIComponent(note.KodProduktu) }
							}
							return (
								<LinkContainer to={linkto} key={note.id}>
									<tr key={note.id}>
										<td className={"text-center ps-2 pe-2 align-middle bg-" + variant + ' ' + cname}><img src={emoji} alt="" width="20" /></td>
										<td className={textclass}>{date_time_format(note.created)}</td>
										<td className={textclass}>{note.KodStrediska}</td>
										<td className={textclass}>{note.Jmeno} ({note.username})</td>
										<td className={textclass}>{t(object_types[note.object_type])}</td>
										<td className="align-middle">{note.object_type === 3 || note.object_type === 4 ? note.KodProduktu : note.KodPartnera}
											{note.object_type === 4 ?
												<>
													<br />
													<span className='text-muted'>{t('ord-batch')}: </span>{note.object_code2}
												</>
												: <></>
											}
										</td>
										<td className="align-middle">{note.object_type === 3 || note.object_type === 4 ? note.NazevProduktu : note.NazevPartnera}</td>
										<td className={textclass}>{note.object_type === 2 ? note.object_code : ""}</td>
										<td className={textclass}>{note.object_type === 3 || note.object_type === 4 ? note.object_code : ""}</td>
										<td className={textclass}>{note.note}</td>
									</tr>
								</LinkContainer>
							);
						})
					}
				</tbody>
			</Table>
			<LoadingDataInfo loadedStatus={loadedStatus} data={notes} />
			<Pager offset={offset} pagesize={20} total={filteredNotes.length} callback={setOffset} />
		</ErrorWrap>
	);
}