/**
 * Navigation for delivery notes section
 * @module delivery-notes/delivery-notes
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React from 'react';
import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';
import { Routes, Route } from "react-router-dom";
import { DeliveryNotesList } from './delivery-notes-sales';
import { DeliveryNoteSales } from './delivery-note-sales-detail';
import { DeliveryNotesSalesStatistics } from './delivery-notes-sales-statistics';
import { DeliveryNotesSalesTrackingNew } from './delivery-notes-sales-tracking-new';
import { Messenger } from '../comp/messenger.js';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';
import { DeliveryNotePurchaseDetail } from './delivery-notes-purchase-detail';
import { ChooseCategory } from '../comp/choose-category';

//seen all - OK

export function DeliveryNotes({ userlogin }) {
	const { t } = useTranslation();

	return (
		<>
			<ErrorWrap>
				<Nav variant="tabs" className="mb-3">
					<Nav.Item>
						<LinkContainer to="/delivery-notes/sales">
							<Nav.Link eventKey="/delivery-notes/sales">{t('menu2-deno-sale')}</Nav.Link>
						</LinkContainer>
					</Nav.Item>
					<Nav.Item>
						<LinkContainer to="/delivery-notes/tracking-new">
							<Nav.Link eventKey="/delivery-notes/tracking-new">{t('menu2-deno-track')}</Nav.Link>
						</LinkContainer>
					</Nav.Item>
					<Nav.Item>
						<LinkContainer to="/delivery-notes/purchase">
							<Nav.Link eventKey="/delivery-notes/purchase">{t('menu2-ord-pur')}</Nav.Link>
						</LinkContainer>
					</Nav.Item>
					<Nav.Item>
						<LinkContainer to="/delivery-notes/statistics">
							<Nav.Link eventKey="/delivery-notes/statistics">{t('menu2-deno-stat')}</Nav.Link>
						</LinkContainer>
					</Nav.Item>
					<Nav.Item className="ms-auto">
						<Routes>
							<Route path="sales/:id" element={<Messenger userlogin={userlogin} type={'pdd'} />} />
						</Routes>
					</Nav.Item>
				</Nav>
			</ErrorWrap>
			<ErrorWrap>
				<Routes>
					<Route path="sales/:kodDokladu" element={<DeliveryNoteSales userlogin={userlogin} />} />
					<Route path="sales" element={<DeliveryNotesList userlogin={userlogin} type="PD" />} />
					<Route path="tracking-new" element={<DeliveryNotesSalesTrackingNew userlogin={userlogin} />} />
					<Route path="purchase/:kodDokladu" element={<DeliveryNotePurchaseDetail userlogin={userlogin} />} />
					<Route path="purchase" element={<DeliveryNotesList userlogin={userlogin} type="ND" />} />
					<Route path="statistics" element={<DeliveryNotesSalesStatistics userlogin={userlogin} />} />
					<Route path="" element={<ChooseCategory />} />
				</Routes >
			</ErrorWrap >
		</>
	);
}
