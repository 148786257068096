/**
 * Reporting landing page allowing to create new or edit existing report.
 * @file
 * @author Lucie Zdeňková <lucie.zdenek@trustica.cz>
 */

import React, { useState } from "react";
import {
    Card,
    //Table,  Row, Col,
    Form, Button
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { mesice } from "../lists/months";
import { date_formatISO, date_parseCzech } from "../lib/date-utils";
import { arrayRange } from "../lib/utils";
import { DatePickerCustom } from '../comp/date-picker';
import { useTranslation } from 'react-i18next';

const reportTypes = {
    "profit-loss": "Profit loss",
    "profit-loss-old": "Profit loss (old mapping)",
    "fg-profit-loss": "Profit loss - focus groups",
    "fg-profit-loss-old": "Profit loss - focus groups (old mapping)",
    "management-report": "Management report",
    "balance-sheet": "Balance sheet",
    "stock-positions": "Stock positions"
}

export function ReportingStart({ userlogin, minMaxDate, costCenters }) {
    const { t } = useTranslation();

    const defaultCentre = userlogin.userinfo.KodStrediska;

    const [showPeriodMenu, setShowPeriodMenu] = useState(false);
    const [reportType, setReportType] = useState("nic");
    const [year, setYear] = useState("nic");
    const [month, setMonth] = useState(null); //full means whole year
    const [centre, setCentre] = useState(defaultCentre);
    const [bookedOnly, setBookedOnly] = useState(false);

    const minDate = date_parseCzech(minMaxDate.min);
    const maxDate = date_parseCzech(minMaxDate.max);
    const minYear = minDate.getFullYear();
    const maxYear = maxDate.getFullYear();
    const years = arrayRange(minYear, maxYear, 1).reverse();
    const monthFrom = minDate.getMonth();
    const monthTo = maxDate.getMonth() + 1;
    const monthsForMinYear = mesice.slice(monthFrom);
    const monthsForMaxYear = mesice.slice(0, monthTo);
    const correctMonths = String(year) === String(minYear) ? monthsForMinYear : String(year) === String(maxYear) ? monthsForMaxYear : mesice;

    const multipleMonthsAllowed = (reportType === "profit-loss") || (reportType === "profit-loss-old"); //in praxis this shows the option "whole year"
    const defaultMonth = multipleMonthsAllowed ? "full" : correctMonths[0][0];

    const costCentersCodes = costCenters.map((line) => line.KodStrediska).filter((code) => code.length > 1);

    return (
        <>

            <Card body className="m-auto" style={{ width: "700px" }}>
                <h4>{t('rep-new_rep')}</h4>
                <br />
                <Form.Group controlId="type">
                    <Form.Label>{t('not-type')}</Form.Label>
                    <Form.Control as="select" defaultValue={reportType}
                        onChange={(ev) => setReportType(ev.target.value)} disabled={showPeriodMenu}>
                        <option disabled value={"nic"}>---</option>
                        {Object.keys(reportTypes).map((type, idx) => <option key={idx} value={type}>{reportTypes[type]}</option>)}
                    </Form.Control>
                </Form.Group>

                <br />

                {!showPeriodMenu ?
                    <Button variant="info" size="sm" className="float-end"
                        onClick={() => setShowPeriodMenu(true)} disabled={reportType === "nic" || showPeriodMenu}>{t('rep-choose_period')}</Button>
                    :
                    <Button size="sm" variant="info" className="float-end"
                        onClick={() => { setShowPeriodMenu(false); setYear("nic"); setMonth("full") }}>{t('rep-choose_rep')}</Button>
                }

            </Card>
            <br />

            {showPeriodMenu ?
                <Card body className="m-auto" style={{ width: "700px" }}>
                    {reportType !== "stock-positions" ?
                        <ChooseMonthYear multipleMonthsAllowed={multipleMonthsAllowed} defaultMonth={defaultMonth}
                            reportType={reportType} centre={centre} setCentre={setCentre} year={year} setYear={setYear}
                            years={years} month={month} setMonth={setMonth} correctMonths={correctMonths} costCentersCodes={costCentersCodes}
                            bookedOnly={bookedOnly} setBookedOnly={setBookedOnly} />
                        :
                        <ChooseDate minDate={minDate} maxDate={maxDate} reportType={reportType} />
                    }
                </Card>
                : ""}

        </>
    );
}

function ChooseDate({ minDate, maxDate, reportType }) {
    const { t } = useTranslation();

    const [date, setDate] = useState(new Date());

    return (
        <>
            <h5 className="mb-3">{t('rep-choose_date')}</h5>
            <DatePickerCustom
                onChange={(value) => setDate(value)}
                minDate={new Date(minDate)}
                maxDate={new Date(maxDate)}
                value={date}
                holidays={[]} />

            <LinkContainer style={{ cursor: "pointer" }} to={{ pathname: "/reporting/" + reportType + "/" + date_formatISO(date) }} >
                <Button className="float-end mt-3">{t('rep-crete_rep')}</Button>
            </LinkContainer>
        </>
    );
}

function ChooseMonthYear({ multipleMonthsAllowed, defaultMonth, reportType, centre, setCentre, year, setYear, years, month, setMonth,
    correctMonths, costCentersCodes, bookedOnly, setBookedOnly
}) {
    const { t } = useTranslation();

    const bookedOnlyOrAllURL = bookedOnly ? "acc" : "any"
    return (
        <>
            <h5 className="mb-3">{t('rep-choose_period')}</h5>
            <Form.Group controlId="periodY" className="mb-3">
                <Form.Label>{t('rep-year')}</Form.Label>
                <Form.Control as="select" defaultValue={"nic"} onChange={(ev) => setYear(ev.target.value)}>
                    <option disabled value={"nic"}>---</option>
                    {years.map((period, idx) => <option value={period} key={idx}>{period}</option>)}
                </Form.Control>
            </Form.Group>

            <Form.Group controlId="periodM" className="mb-3">
                <Form.Label>{t('rep-months')}</Form.Label>
                <Form.Control as="select" defaultValue={defaultMonth} onChange={(ev) => setMonth(ev.target.value)}>
                    {multipleMonthsAllowed ?
                        <option value={"full"}>{t('whole_year')}</option>
                        :
                        <></>}
                    {correctMonths.map((month, idx) => <option value={month[0]} key={idx}>{t(month[1])}</option>)}
                </Form.Control>
            </Form.Group>
            {reportType === "management-report" ?
                <Form.Group controlId="centre">
                    <Form.Label>{t('biz-centre')}</Form.Label>
                    <Form.Control as="select" defaultValue={centre} onChange={(ev) => setCentre(ev.target.value)}>
                        {costCentersCodes.map((centreCode, idx) => <option value={centreCode} key={idx}>{centreCode}</option>)}
                    </Form.Control>
                </Form.Group>
                : ""}

            <Form.Check type="checkbox" className="mt-4 ms-1"
                value={bookedOnly}
                checked={bookedOnly}
                onChange={() => setBookedOnly(bookedOnly ? false : true)}
                id={"1"}
                label={t('rep-booked_only')} />

            <LinkContainer style={{ cursor: "pointer" }} to={{ pathname: "/reporting/" + reportType + "/" + year + "/" + (month || defaultMonth) + "/" + bookedOnlyOrAllURL + (reportType === "management-report" ? "/" + centre : "") }} >
                <Button className="float-end mt-3" disabled={year === "nic"}>{t('rep-crete_rep')}</Button>
            </LinkContainer>
        </>
    )
}
