import Fraction from "fraction.js";

//seen all - OK

/**
 * Returns true if given data comes from specific kind of source.
 *
 * @param{any} data - array of dictionaries of products' data
 * @param{string} kind - type to check for:
 *   * sr_k_IDProduktu => Warehouse
 *   * sth_k_IDProduktu => Twist
 * @return {boolean} - true if the data is of given kind.
 */
function is_preprocessed_data_kind(data, kind) {
	return (data.length > 0) &&
		(data[0].batches.length > 0) &&
		(!!data[0].batches[0][kind]);
}

const zero_values = {
	alf_batch_sum: 0,
	alf_batch_weight_kg: 0,
};

/**
 * Merges warehouse and Twist preprocessed stock records data on batch
 * level.
 *
 * @param{any} one - Twist or warehouse data
 * @param{any} two - warehouse or Twist data
 * @return{any} - merged data
 */
export function merge_warehouse_with_twist_stocks(...one_two) {
	const warehouse = one_two.filter((data) => is_preprocessed_data_kind(data, "sr_k_IDProduktu"))[0];
	const twist = one_two.filter((data) => is_preprocessed_data_kind(data, "sth_k_IDProduktu"))[0];
	if ((!twist) || (!warehouse)) {
		throw new Error("Must get one from warehouse and one from Twist for merging!");
	}
	return [...twist, ...warehouse]
		.map((pr) => pr.IDProduktu)
		.filter((v, i, a) => a.indexOf(v) === i)
		.map((id) => [twist.find((pr) => id === pr.IDProduktu) || { batches: [] },
		warehouse.find((pr) => id === pr.IDProduktu) || { batches: [] }])
		.map(([tw, wa]) => ({
			...wa,
			...tw,
			batches: [...tw.batches.map((br) => br.sth_KodSerie.trim()),
			...wa.batches.map((br) => br.sr_KodSerie.trim())]
				.filter((v, i, a) => a.indexOf(v) === i)
				.map((bid) => ({
					twist: tw.batches.find((br) => br.sth_KodSerie.trim() === bid) === undefined ? zero_values : tw.batches.find((br) => br.sth_KodSerie.trim() === bid),
					warehouse: wa.batches.find((br) => br.sr_KodSerie.trim() === bid) === undefined ? zero_values : wa.batches.find((br) => br.sr_KodSerie.trim() === bid),
				}))
				.map((br) => ({
					...br,
					sum_kg_ok: !!br.twist &&
						!!br.warehouse &&
						!!br.twist.alf_batch_weight_kg &&
						!!br.warehouse.alf_batch_weight_kg &&
						br.twist.alf_batch_weight_kg === br.warehouse.alf_batch_weight_kg,
					sum_mj_ok: !!br.twist &&
						!!br.warehouse &&
						!!br.twist.alf_batch_sum &&
						!!br.warehouse.alf_batch_sum &&
						br.twist.alf_batch_sum === br.warehouse.alf_batch_sum
				}))
		}))
		.map((pr) => ({
			...pr,
			super_sum_kg_twist: pr.batches
				.reduce((acc, v) => acc.add(Fraction((v.twist || {}).alf_batch_weight_kg) || Fraction(0)), Fraction(0)),
			super_sum_kg_warehouse: fraction_value_check(pr),
			super_sum_mj_twist: pr.batches
				.reduce((acc, v) => acc.add(Fraction((v.twist || {}).alf_batch_sum) || Fraction(0)), Fraction(0)),
			super_sum_mj_warehouse: pr.batches
				.reduce((acc, v) => acc.add(Fraction((v.warehouse || {}).alf_batch_sum) || Fraction(0)), Fraction(0)),
			all_batches_mj_ok: pr.batches
				.reduce((acc, v) => acc && v.sum_mj_ok, true),
			all_batches_kg_ok: pr.batches
				.reduce((acc, v) => acc && v.sum_kg_ok, true)
		})).map((pr) => ({
			...pr,
			super_sum_kg_ok: parseFloat(pr.super_sum_kg_twist) === parseFloat(pr.super_sum_kg_warehouse),
			super_sum_mj_ok: parseFloat(pr.super_sum_mj_twist) === parseFloat(pr.super_sum_mj_warehouse)
		})).map((pr) => ({
			...pr,
			stocks_check_status: !pr.super_sum_kg_ok ? "danger"
				: !pr.all_batches_kg_ok ? "warning"
					: "", // "" means success - no class color applied
			problem: !pr.super_sum_kg_ok || !pr.all_batches_kg_ok, //for filtering problematic products
		}))
		;
	// alf_batch_weight_kg = tw<>wh => batchLine { sum_kg_ok: true|false }
	// product_sum_kg ??
	// product{ super_sum_kg_ok: true|false}
	// product_sums_status: ok/warning/critical
	// ok = all sum_kg_ok true && super_sum_kg_ok
	// warning = super ok && ! sum_kg_ok
	// critical super_sum not ok
}

function fraction_value_check(pr) { //this needs systematical approach
	try {
		var result = pr.batches
			.reduce((acc, v) => acc.add(
				Fraction((v.warehouse || {}).alf_batch_weight_kg) || Fraction(0)
			), Fraction(0))
		return result;
	} catch (e) {
		return "Chyba";
	}
}