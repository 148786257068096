/**
 * App component handiling loggin.
 * @module App
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React, { useState, useEffect } from 'react';
import { AppComponent } from './appcomponent';
import { ErrorWrap } from './comp/errorwrap';
import { useTranslation } from 'react-i18next';

//seen all - OK

function parseLocalStorageJSON(key) {
	var result = {};
	try {
		result = JSON.parse(localStorage.getItem(key));
	} catch (ex) {
	}
	return result;
}

function App() {

	const { i18n } = useTranslation();
	const { t } = useTranslation();

	const [logged, setLogged] = useState(parseLocalStorageJSON('his:logged'));
	const [username, setUsername] = useState(localStorage.getItem('his:username'));
	const [userinfo, setUserinfo] = useState(parseLocalStorageJSON('his:userinfo'));
	const [logerr, setLogerr] = useState(false);
	const [logerrMessage, setLogerrMessage] = useState('');
	const [title, setTitle] = useState("HIS");
	const [pingLang, setPingLang] = useState("cs"); //default cs, if ping lang is null

	function handleLogin(username, userinfo) {
		localStorage.setItem('his:logged', JSON.stringify(true));
		localStorage.setItem('his:username', username);
		localStorage.setItem('his:userinfo', JSON.stringify(userinfo));
		setLogged(true);
		setUsername(username);
		setUserinfo(userinfo);
	}

	function handleLogout() {
		localStorage.setItem('his:logged', JSON.stringify(false));
		localStorage.setItem('his:username', "");
		localStorage.setItem('his:userinfo', JSON.stringify({}));
		setLogged(false);
		setUsername("");
	}

	function handleLogerr(err, msg = t('login_error')) {
		setLogerr(err);
		handleLogout();
		setLogerrMessage(msg);
	}

	useEffect(() => {
		function changeLanguage(val) {
			i18n.changeLanguage(val);
		}
		changeLanguage(userinfo && userinfo.language ? userinfo.language : pingLang); //language is taken from userlogin or from ping, if userlogin is missing
	}, [userinfo, i18n, pingLang]); //is this legit?

	const userlogin = {
		username: username,
		logged: logged,
		handleLogin: handleLogin,
		handleLogout: handleLogout,
		userinfo: userinfo,
		handleLogerr: handleLogerr,
		logerr: logerr,
		logmessage: logerrMessage,
		title: title,
		setTitle: setTitle
	};

	//console.log(userlogin);
	//console.log(userinfo);

	return (
		<ErrorWrap>
			<AppComponent userlogin={userlogin} setPingLang={setPingLang} />
		</ErrorWrap>
	);
}

export default App;
