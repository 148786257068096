var Fraction = require('fraction.js');

/**
 * Counts weight of package after checking correctness inputs.
 * 
 * @param {number} pack_unit_kg_weight - weight of one storage unit in kg // kolik skladovací jednotka váží
 * @param {string} kg_unit - should be always kg - it belongs to pack_unit_kg // jednotka přepočtu na kg - musí být kg
 * @param {number} pack_units_amount - amount of storage units in package // množství jednotek v balení
 * @returns {fraction} -  weight of package
 */
 export function get_package_weight_kg(pack_unit_kg_weight, kg_unit, pack_units_amount) {
    const [ is_ok, message, fraction_pack_unit_kg_weight, fraction_pack_units_amount ] = check_package_weight_kg_input(pack_unit_kg_weight, kg_unit, pack_units_amount);
    if (!is_ok) {
	throw new Error(message);
    }
    return fraction_pack_unit_kg_weight.mul(fraction_pack_units_amount);
}

/**
 * Checks correctness of inputs for counting package weight in kg
 * 
 * @param {number} pack_unit_kg_weight - weight of one storage unit in kg // kolik skladovací jednotka váží
 * @param {string} kg_unit - should be always kg - it belongs to pack_unit_kg // jednotka přepočtu na kg - musí být kg
 * @param {number} pack_units_amount - amount of storage units in package // množství jednotek v balení
 * @returns {array} - [is_ok, message, fraction1, fraction2]
 */
export function check_package_weight_kg_input(pack_unit_kg_weight, kg_unit, pack_units_amount) {
    if ((kg_unit || "").trim() !== "kg") {
	return [ false, "wh_unit_doesnt_have_kg_weight", null, null ];
    }
    try {
	const fraction_pack_unit_kg_weight = new Fraction(pack_unit_kg_weight); //kolik váží skladovací jednotka v kg
	if (fraction_pack_unit_kg_weight.n === 0) {
	    return [ false, "wh_unit_is_zero", null, null ];
	}
	try {
	    const fraction_pack_units_amount = new Fraction(pack_units_amount); // kolik je skladovacích jednotek v balení
	    if (fraction_pack_units_amount.n === 0) {
		return [ false, "package_amount_is_zero", null, null ];
	    }

	    // All good
	    return [ true, null, fraction_pack_unit_kg_weight, fraction_pack_units_amount ];
	    
	} catch (ex) { // fraction_pack_units_amount
	    return [ false, "package_amount_isnt_number", null, null ];
	}
    } catch (ex) { // fraction_pack_unit_kg_weight
	return [ false, "wh_unit_weight_isnt_number", null, null ];
    }
}