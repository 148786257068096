/**
 * Basic react neccessary call of <App/> function.
 * @module index
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React from 'react';
//import ReactDOM from 'react-dom'; //old React 16 method
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import './i18n/i18n';
import * as serviceWorker from './serviceWorker';

// Upgraded according to guide: https://react.dev/blog/2022/03/08/react-18-upgrade-guide
const root = createRoot(document.getElementById('root'));
root.render(<App />);

//Old React 16 method
//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
    onUpdate: function(registration) {
	const waitingServiceWorker = registration.waiting

	if (waitingServiceWorker) {
	    waitingServiceWorker.addEventListener("statechange", event => {
		if (event.target.state === "activated") {
		    caches.keys().then(function(cacheNames) {
			return Promise.all(
			    cacheNames.filter(function(cacheName) {
				// Return true if you want to remove this cache,
				// but remember that caches are shared across
				// the whole origin
				return true;
			    }).map(function(cacheName) {
				return caches.delete(cacheName);
			    })
			);
		    });
		    window.location.reload(true);
		}
	    });
	    waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
	}}});
