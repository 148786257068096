/**
 * User login components and password change component.
 * @module comp/login
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */

import React, { useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { ResultMessage } from '../comp/resultmessage';
import { his_fetch } from '../comp/FetchLoader';
import { ForgottenPasswd } from './passwd';
import { useTranslation } from 'react-i18next';

//seen all - OK


/**
 * Error message for loggin
 * 
 * @param {boolean} logerr - 
 *  @param {string} logmessage - message 
 * @returns {component}
 */
function LogginErrorMessage({ logerr, logmessage }) {
	const { t } = useTranslation();
	if (logerr) {
		if (logmessage) {
		} else {
			logmessage = t('wrong_username_or_psswd');
		}
		return <ResultMessage variant="danger" message={logmessage} />
	} else {
		return <></>;
	}
}
/**
 * Switch between login form and forgotten password form
 * 
 * @param {any} uselogin - info about current user 
 * @returns {component}
 */
export function LoginComponent({ userlogin }) {
	const { t } = useTranslation();

	const [showLogin, setShowLogin] = useState(true);
	const formType = showLogin ? <LoginComponentForm userlogin={userlogin} /> : <ForgottenPasswd userlogin={userlogin} />;
	const label = showLogin ? t('forgotten_psswd') : t('back_to_login');

	return (
		<>
			{formType}
			<Row>
				<Col md={4}></Col>
				<Col md={4}><h5 className="mb-4"><a href="#!" onClick={() => setShowLogin(showLogin ? false : true)}>
					{label}
				</a></h5></Col>
				<Col md={4}></Col>
			</Row>
		</>
	);
}

/**
 * 
 * Loggin form into HIS.
 * @param {any} userlogin - current user info
 * @returns {component}
 */
export function LoginComponentForm({ userlogin }) {
	const { t } = useTranslation();

	function handleSubmit(event) {
		const form = event.currentTarget;
		const username = form.username.value;
		const password = form.password.value;
		const reqhash = {
			username: username,
			password: password
		};
		const reqhashstr = JSON.stringify(reqhash);
		his_fetch(
			userlogin,
			[
				{
					uri: "/api/auth",
					json: true,
					ok: function (resource, result, response) {
						//console.log(response);
						if (response.status === 200) {
							userlogin.handleLogin(username, result.userinfo);
							//console.log(response);
							//console.log(result);
						
						} else {
							// Show error
							userlogin.handleLogerr(true);
							console.log("Response status is: " + response.status);
						}
					},
					error: function (resource, reason) {
						// Show error
						userlogin.handleLogerr(true);
					},
					args: {
						method: 'POST',
						cache: 'no-cache',
						credentials: 'same-origin',
						body: reqhashstr
					}
				}
			]
		)
		event.preventDefault();
		event.stopPropagation();
	}


	return (
		<>
			<Row>
				<Col md={4}></Col>
				<Col md={4}>
					<Form onSubmit={handleSubmit}>
						<Form.Group controlId="username" className='mb-3 mt-2'>
							<Form.Label>{t('sys-username')}:</Form.Label>
							<Form.Control
								type="text"
								name="username"
								placeholder=""
							/>
						</Form.Group>
						<Form.Group controlId="password" className='mb-3'>
							<Form.Label>{t('password')}:</Form.Label>
							<Form.Control
								type="password"
								name="password"
								placeholder=""
							/>
						</Form.Group>
						<Row>
							<Col></Col>
							<Col className="text-end"><Button type="submit">{t('login')}</Button></Col>
						</Row>
					</Form>
					<LogginErrorMessage logerr={userlogin.logerr} logmessage={userlogin.logmessage} />
				</Col>
				<Col md={4}>
				</Col>
			</Row>
		</>
	);
}

