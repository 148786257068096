/**
 * Component displaying orange universal ADR icon.
 * @module comp/adr
 * @author Lucie Zdeňková <lucie.zdenek@trustica.cz>
 */

import React from 'react';
import { Image } from 'react-bootstrap';

//seen all - OK

/** 
 * Displaying ADR icon. 
 *  
 * @param {boolean} isADR - boolean whether product is ADR
 * @param {number} height - default is 45

 * @return - image of ADR
 */
export function ShowADR({ isADR, height = 45 }) {
    if (isADR) {
        return <Image src={"/img/adr/isADR.svg"} height={height} />;
    } else {
        return "";
    }
}