/**
 * Menu with sections Users, Emails and fpt.
 * @module mainmenu
 * @author Lucie Zdenkova <lucie.zdenek@trustica.cz>
 */
import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';
import { Route, Routes } from "react-router-dom";
import { UserDetail } from './login-users/user-detail';
import { Users } from './login-users/users';
import { Emails } from './emails';
import { EmailDetail } from './email-detail';
import { NewUser } from './login-users/user-new';
import { ErrorWrap } from './comp/errorwrap';
import { Threads } from './threads';
import { NotesList } from './notes/notes-list';
import { useTranslation } from 'react-i18next';
import { ChooseCategory } from './comp/choose-category';
import { FTPArchivComp } from './system-ftp-menu';

//seen all - OK

/** 
 * Switch and navigation for System mainmenu section. 
 *  
 * @param {dictionary} userlogin - information about logged user
 * @return {component} - COMPONENT navigation with options: Emaily Archiv | FTP Archiv | Uživatelé
 */
export function SystemComponent({ userlogin }) {
    const { t } = useTranslation();

    const [users, setUsers] = useState(null);
    const [loadedStatus, setLoadedStatus] = useState(0); // loadedStatus je taky v nadřazené komponentě

    const superuser = userlogin.userinfo && userlogin.userinfo.his_superuser;
    const existingUsers = (users === "error" || !users ? [] : users).map(x => x.username);

    //console.log(users);

    return (
        <ErrorWrap>
            <Nav variant="tabs" className="mb-3">
                <LinkContainer to="/system/emails">
                    <Nav.Link eventKey="/system/emails">{t('menu2-sys-mail')}</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/system/ftp">
                    <Nav.Link eventKey="/system/ftp">{t('menu2-sys-ftp')}</Nav.Link>
                </LinkContainer>
                {!superuser ? "" :
                    <LinkContainer to="/system/users">
                        <Nav.Link eventKey="/system/users">{t('menu2-sys-user')}</Nav.Link>
                    </LinkContainer>
                }
                <LinkContainer to="/system/threads">
                    <Nav.Link eventKey="/system/threads">{t('menu2-sys-thread')}</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/system/notes">
                    <Nav.Link eventKey="/system/notes">{t('menu2-notes')}</Nav.Link>
                </LinkContainer>
            </Nav>
            <Routes>
                <Route path="emails/:code" element={<EmailDetail userlogin={userlogin} />} />
                <Route path="emails" element={<Emails userlogin={userlogin} />} />
                <Route path="ftp/*" element={<FTPArchivComp userlogin={userlogin} />} />
                <Route path="users/new" element={<NewUser userlogin={userlogin} existingUsers={existingUsers} />} />
                <Route path="users/:userID" element={<UserDetail userlogin={userlogin} existingUsers={existingUsers} />} />
                <Route path="users" element={<Users userlogin={userlogin} users={users} setUsers={setUsers} loadedStatus={loadedStatus} setLoadedStatus={setLoadedStatus} />} />
                <Route path="threads/" element={<Threads userlogin={userlogin} />} />
                <Route path="notes" element={<NotesList userlogin={userlogin} />} />
                <Route path="" element={<ChooseCategory />} />
            </Routes>
        </ErrorWrap >
    );
}