/**
 * Old fetching function, which isnt used any more, but fetcher2 is still used for downloaders
 * @module lib/fetcher
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */

export function fetcher2(uri, okfun, errfun, args = {}) {
    // console.log("fetcher uri='" + uri + "'");
    var encodedURI;
    var uriprefix = '';
    if (process.env.NODE_ENV === 'development') {
	uriprefix = 'https://his-dev.hsh-chemie.cz';
    }
    try{
	encodedURI = encodeURI(uriprefix + uri);
    } catch(err) {
	errfun({ error: "Malformed URI." });
    }
    fetch(encodedURI, { ...args, credentials: 'include', cache: 'no-cache' })
	.then(function(res) {
	    okfun(res);
	},
	      function(error) {
		  errfun(error);
	      }
	     );
}