/**
 * Password change component
 * @module comp/Passwd
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { Row, Col, Alert, Card, Form, Button } from 'react-bootstrap';
import FormField from './FormField';
import PasswordField from '../login-users/PasswordField';
import { Loading } from '../comp/loading';
import { ResultMessage } from '../comp/resultmessage';
import { his_fetch } from '../comp/FetchLoader';
import { useTranslation } from 'react-i18next';

//seen all - OK

/** 
 * Component allowing password change with error handling.
 * 
 * @param {any} userlogin info about logged user
 * @returns {component}
 */
export function Passwd({ userlogin }) {
	const { t } = useTranslation();

	const [status, setStatus] = useState(1);
	const [newPassword, setNewPassword] = useState("");
	const [allowSubmit, setAllowSubmit] = useState(false);
	const [message, setMessage] = useState("");
	const [messageVariant, setMessageVariant] = useState("warning");

	const newpasswordChanged = (target) => {
		setNewPassword(target.value);
	}

	const validatePassword2Change = (value) => {
		const newPassword2 = value;
		if ((newPassword2.length > 0) && (newPassword === newPassword2)) {
			setAllowSubmit(true);
			return true;
		}
		setAllowSubmit(false);
		throw new Error(t('passwd_dont_match'));
	}

	const again = () => {
		setStatus(1);
		setMessage("");
		setMessageVariant("warning"); //initial
	}

	const handleSubmit = (event) => {
		const form = event.currentTarget;
		const oldpassword = form.oldpassword.value;
		const newpassword = form.newpassword.value;
		const newpassword2 = form.newpassword2.value;
		setStatus(2);
		his_fetch(
			userlogin,
			[
				{
					uri: "/api/auth",
					json: true,
					ok: function (resource, result) {
						const res = result.result;
						if (res === "ok") {
							setStatus(0);
							setMessage(t('passwd_changed'));
							setMessageVariant("success");
						} else if (res === "new-mismatch") {
							setStatus(0);
							setMessage(t('psswd2_must_be_same'));
							setMessageVariant("danger");
						} else if (res === "old-wrong") {
							setStatus(0);
							setMessage(t('wrong_old_psswd'));
							setMessageVariant("danger");
						}
					},
					error: function (resource, reason) {
						console.log(reason);
						setStatus(1);
					},
					args: {
						method: 'PUT',
						body: JSON.stringify({
							oldpassword: oldpassword,
							newpassword: newpassword,
							newpassword2: newpassword2
						})
					}
				}
			]
		);
		event.preventDefault();
		event.stopPropagation();
	}
	return (
		<Card>
			<Card.Body>
				<h5 className='mb-4'>{t('change_psswd')}</h5>

				{
					messageVariant === "success" ?
						<>
							<ResultMessage message={message} variant={messageVariant} />
							<Button className='float-end me-3' onClick={again}>{t('back_to_form')}</Button>
						</>
						:
						messageVariant === "danger" ?
							<>
								<ChangeForm validatePassword2Change={validatePassword2Change} 
									allowSubmit={allowSubmit}
									handleSubmit={handleSubmit}
									newpasswordChanged={newpasswordChanged}
									status={status}
								/>
								<ResultMessage message={message} variant={messageVariant} />
							</>
							:
							<>
								<ChangeForm validatePassword2Change={validatePassword2Change}
									allowSubmit={allowSubmit}
									handleSubmit={handleSubmit}
									newpasswordChanged={newpasswordChanged}
									status={status}
								/>
							</>
				}

			</Card.Body>
		</Card>
	);
}

/**
 * Form for password update with fields for old password, new password and update button
 * 
 * @param {function} validatePassword2Change - validation function for new password
 * @param {boolean} allowSubmit - conditions result for submiting 
 * @param {function} handleSubmit - submiting new password
 * @param {function} newpasswordChanged - onChange function for new password field
 * @param {number} status - status of the changing process - 1, 2
 * @returns {component}
 */
function ChangeForm({ validatePassword2Change, allowSubmit, handleSubmit, newpasswordChanged, status }) {
	const { t } = useTranslation();

	return (
		<Form onSubmit={handleSubmit} >
			<Form.Group controlId="oldpassword">
				<Form.Label>{t('old_psswd')}:</Form.Label>
				<Form.Control
					type="password"
					name="oldpassword"
					placeholder=""
				//disabled
				/>
			</Form.Group>
			<br />
			<PasswordField fieldId="newpassword" label={t('new_psswd')} placeholder="" onStateChanged={newpasswordChanged} thresholdLength={7} minStrength={3} required />
			<br />
			<FormField fieldId="newpassword2" validator={validatePassword2Change} label={t('new_psswd_again')} placeholder="" type="password" required />
			<Row>
				<Col md={12} className="text-end">
					{status === 2 ?
						<Button className='mt-3'
							variant={"danger"}
							disabled
							type="submit"
						>
							<Loading size="small" margin="0" />
						</Button>
						:
						<Button className='mt-3'
							variant={allowSubmit ? "danger" : "secondary"}
							type="submit"
							disabled={!allowSubmit}>
							{t('update')}
						</Button>
					}
				</Col>
			</Row>
		</Form>
	);
}


/**
 * Form for getting a link to set new password
 * 
 * @param {any} userlogin - info about logged uuser 
 * @returns {component}
 */
export function ForgottenPasswd({ userlogin }) {
	const { t } = useTranslation();

	const [username, setUsername] = useState("");
	const [mail, setMail] = useState("");
	const [saving, setSaving] = useState(false);
	const [editEnabled, setEditEnabled] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [done, setDone] = useState(false);

	const handleUserName = function (ev) {
		setUsername(ev.target.value);
	}

	const handleMail = function (ev) {
		setMail(ev.target.value);
	}

	function getNewPasswd() {
		if (!saving) {
			setSaving(true);
			setEditEnabled(true);

			var user = {}; //vyrábím slovník slovníků
			user['email'] = mail;
			user['username'] = username;
			console.log()
			const jsonData = JSON.stringify(user);
			console.log(jsonData);

			his_fetch(
				userlogin,
				[
					{
						uri: "/api/users/reset-password",
						json: true,
						ok: function (resource, result) {
							console.log(result);
							setDone(true);
							setSaving(false);
							//reloadIt();
						},
						error: function (resource, reason) {
							console.log('err: ' + reason);

							//reloadIt();
							setErrorMessage(t('error'));
							setSaving(false);
						},
						args: {
							method: 'POST',
							body: jsonData
						}
					}
				]
			);
		}
	}


	return (
		<>
			<Row className="mb-4 mt-3">
				<Col md={4}></Col>
				<Col md={4}>
					<p>{t('link_for_psswd_change_will_be_sent_to_provided_email')}.</p>
					<Form.Group className="mb-3" controlId="username">
						<Form.Label>{t('sys-username')} {t('mandatory')}</Form.Label>
						<Form.Control type="text" value={username} onChange={handleUserName} disabled={editEnabled} />
					</Form.Group>
					<Form.Group className="mb-3" controlId="mail">
						<Form.Label>{t('his_mail')} {t('mandatory')}</Form.Label>
						<Form.Control type="text" value={mail} onChange={handleMail} disabled={editEnabled} />
					</Form.Group>
					{saving ? <Button disabled>{t('sending')}</Button> :
						<Button onClick={getNewPasswd} disabled={mail === "" || username === ""}>{t('send')}</Button>}
					<br />
					{errorMessage ? <span className="text-danger">{errorMessage}</span> : ""}
					{done ? <Alert className="mt-2" variant="info">{t('link_for_psswd_reset_sent_to_provided_email_if_you_dont_receive_it_within_hour_contact_hr')}</Alert> : ""}
				</Col>
				<Col md={4}></Col>
			</Row>
		</>
	)
}

/**
 * Form allowing set new HIS password
 * 
 * @param {any} userlogin - info about user
 * @returns {component}
 */
export function PasswdReset({ userlogin }) {
	const { t } = useTranslation();

	const { token } = useParams();
	const [psswd, setPsswd] = useState("");
	const [psswd2, setPsswd2] = useState("");
	const [saving, setSaving] = useState(false);
	const [result, setResult] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [disableSubmit, setDisableSubmit] = useState(true);

	const newpasswordChanged = (target) => {
		setPsswd(target.value);
	}
	const newpassword2Changed = (target) => {
		setPsswd2(target.value);
	}
	const validatePassword2Change = (value) => {
		const psswd2 = value;
		if ((psswd2.length > 0) && (psswd === psswd2)) {
			setDisableSubmit(false);
			return true;
		}
		setDisableSubmit(true);
		throw new Error(t('passwd_dont_match'));
	}

	function resetPasswd() {
		if (!saving) {
			setSaving(true);

			var reset = {};
			reset['token'] = token;
			reset['password'] = psswd;
			reset['password2'] = psswd2;
			const jsonData = JSON.stringify(reset);
			//console.log(jsonData);

			his_fetch(
				userlogin,
				[
					{
						uri: "/api/users/set-forgotten-password",
						json: true,
						ok: function (resource, result) {
							//console.log(result);
							if (result.message === "Password reset!") {
								setResult(t('passwd_changed'));
							} else if (result.error) {
								setErrorMessage(t('error') + ": " + result.error);
								setSaving(false);
							} else {
								setErrorMessage(t('error'));
							}
						},
						error: function (resource, reason) {
							console.log('err: ' + reason);
							setErrorMessage(t('error') + ": " + reason);
							setSaving(false);
						},
						args: {
							method: 'POST',
							body: jsonData
						}
					}
				]
			);
		}
	}

	return (
		<Row>
			<Col md={4}></Col>
			<Col md={4}>
				<h5 className="mt-4 mb-3">{t('set_new_psswd')}</h5>
				<PasswordField fieldId="newpassword" placeholder="" label={t('new_psswd')} onStateChanged={newpasswordChanged} thresholdLength={7} minStrength={3} required />
				<FormField fieldId="newpassword2" validator={validatePassword2Change} onStateChanged={newpassword2Changed} label={t('new_psswd_again')} placeholder="" type="password" required />
				<Button disabled={saving || disableSubmit} onClick={resetPasswd}>{t('setting_new_psswd')}</Button>
				<br />
				<br />
				{result ? <Alert variant="success">{result}  <b><a href="/" >{t('log-in')}.</a></b></Alert> : ""}
				{errorMessage ? <Alert variant="danger">{errorMessage}</Alert> : ""}
			</Col>
			<Col md={4}></Col>
		</Row>
	)
}