
/**
* Switch for main navigation.
* @module appcomponent
* @author Dominik Pantucek <dominik.pantucek@trustica.cz>
*/
import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import { Partners } from './partners/partners';
import { MainMenu } from './mainmenu';
import { LoginComponent } from './login-users/login';
import { Settings } from './login-users/settings';
import { Home } from './home';
import { SystemComponent } from './system';
import { Help } from './help';
import { Insurance } from './insurance/insurance';
import { Products } from './products/products';
import { DeliveryNotes } from './delivery-notes/delivery-notes';
import { OrdersComponent } from './orders/orders';
import { PasswdReset } from './login-users/passwd';
import { Debts2 } from './partners/debts2';
import { ErrorWrap } from './comp/errorwrap';
import { ReportingRoutes } from './reporting/reporting';

export function AppComponent({ userlogin, setPingLang }) {
	return (
		<ErrorWrap>
			<Container fluid className='mt-5 pt-3'>
				<BrowserRouter>
					<MainMenu userlogin={userlogin} setPingLang={setPingLang} />
					{userlogin.logged ?
						<Routes>
							<Route path="/partners/*" element={<Partners userlogin={userlogin} />} />
							<Route path="/orders/*" element={<OrdersComponent userlogin={userlogin} />} />
							<Route path="/products/*" element={<Products user={userlogin.username} userinfo={userlogin.userinfo} userlogin={userlogin} />} />
							<Route path="/delivery-notes/*" element={<DeliveryNotes userlogin={userlogin} />} />
							<Route path="/debts2" element={<Debts2 userinfo={userlogin.userinfo} />} />
							<Route path="/settings" element={<Settings userlogin={userlogin} />} />
							<Route path="/system/*" element={<SystemComponent userlogin={userlogin} />} />
							<Route path="/insurance/*" element={<Insurance userlogin={userlogin} />} />
							<Route path="/help/*" element={<Help userlogin={userlogin} />} />
							{/** 
							<Route path="/reporting/:reportId/:year/:month/:centre/*" element={<ReportingSwitch userlogin={userlogin} />} />
							<Route path="/reporting/:reportId/:year/:month/*" element={<ReportingSwitch userlogin={userlogin} />} />
							<Route path="/reporting/:reportId/*" element={<ReportingSwitch userlogin={userlogin} />} />
							<Route path="/reporting" element={<ReportingStart userlogin={userlogin} />} />
							*/}
							<Route path="/reporting/*" element={<ReportingRoutes userlogin={userlogin} />} />
							<Route path="/" element={<Home userlogin={userlogin} />} />
						</Routes>
						:
						<>
							<Routes>
								<Route path='/password-reset/:token' element={<PasswdReset />} />
								<Route path='/' element={<LoginComponent userlogin={userlogin} />} />
							</Routes>
						</>
					}
				</BrowserRouter>
			</Container>
		</ErrorWrap>
	);
}
