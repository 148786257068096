/**
 * Version of HIS, one function
 * @module lib/version
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */

import pkg from '../../package.json';
export function his_frontend_version() {
    return pkg.version;
}

