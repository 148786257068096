/**
 * List of users.
 * @module user-new
 * @author Lucie Zdenkova <lucie.zdenek@trustica.cz>
 */
import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { his_fetch, his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import { Button } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import { Boolean } from '../comp/boolean';
import { filter_rule } from '../lib/utils';
import { BooleanDropdown } from '../comp/boolean';
import { Col } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';

//seen all - OK

/**
 * List of all users in HIS - for users administration
 * 
 * @param {any} userlogin 
 * @param {any} users - array of dictionaries of users - stored in mother component to prevent reloading too often
 * @param {function} setUsers - setting users - for reloading
 * @param {any} loadedStatus - status of fetching
 * @param {function} setLoadedStatus - setting loadedStatus - for reloading
 * @returns {component}
 */
export function Users({ userlogin, users, setUsers, loadedStatus, setLoadedStatus }) {
	const { t } = useTranslation();
	//console.log(userlogin);

	const [usernameFilter, setUsernameFilter] = useState("");
	const [mailFilter, setMailFilter] = useState("");
	const [codeFilter, setCodeFilter] = useState("");
	const [filterPswd, setFilterPswd] = useState(null);
	const [filterCompliance, setFilterCompliance] = useState(null);
	const [filterHeadCSR, setFilterHeadCSR] = useState(null);
	const [filterAdmin, setFilterAdmin] = useState(null);

	const reloadIt = () => {
		setUsers(null);
		setLoadedStatus(0);
		setUsernameFilter("");
		setMailFilter("");
		setCodeFilter("");
		setFilterPswd(null);
		setFilterCompliance(null);
		setFilterHeadCSR(null);
		setFilterAdmin(null);
	}


	useEffect(() => {
		if (users === null) {
			const running_fetch = his_fetch(
				userlogin,
				[
					{
						uri: "/api/users",
						json: true,
						status: setLoadedStatus,
						ok: function (resource, result) {
							//console.log(result);
							setUsers(result.users);
						},
						error: function (resource, reason) {
							console.log('err: ' + reason);
							setUsers("error");
						}
					}
				]
			)
			return () => {
				running_fetch();
			}
		}
	}, [userlogin, users, setLoadedStatus, setUsers]);

	if (!his_fetch_success(loadedStatus)) {
		return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />;
	}

	const handleChangeUserName = (e) => {
		setUsernameFilter(e.target.value);
	}

	const handleChangeMail = (e) => {
		setMailFilter(e.target.value);
	}

	const handleChangeCode = (e) => {
		setCodeFilter(e.target.value);
	}

	const filtered_users = (users || []).filter(
		function (user) {
			return (
				filter_rule(usernameFilter, user.username, true) &&
				filter_rule(mailFilter, user.email, true) &&
				filter_rule(codeFilter, user.tw_kodpracovnika, true) &&
				((filterPswd === null) ||
					(filterPswd === user.password_set)) &&
				((filterCompliance === null) ||
					(filterCompliance === user.role_compliance)) &&
				((filterHeadCSR === null) ||
					(filterHeadCSR === user.role_csr_head)) &&
				((filterAdmin === null) ||
					(filterAdmin === user.superuser))
			)
		}
	)

	return (
		<ErrorWrap>
			<Row>
				<Col>
					<h5><Button size="sm" className="me-2 d-inline" onClick={reloadIt}><Image src="/img/reload.svg" height="19" /></Button> {t('sys-users_list')}</h5>
				</Col>
				<Col>
					<LinkContainer to={{ pathname: "/system/users/new" }}>
						<Button className="float-end mb-2">{t('sys-add_new_user')}</Button>
					</LinkContainer>
				</Col>
			</Row>

			<Table striped hover size="sm">
				<thead className="beGray">
					<tr>
						<th className="align-middle text-center">ID</th>
						<th>
							<Form.Group controlId="filterUsername" className="mt-2 mb-3">
								<Form.Control type="username" placeholder={"🔍 " + t('sys-username')} onChange={handleChangeUserName} />
							</Form.Group>
						</th>
						<th>
							<Form.Group controlId="filterEmail" className="mt-2 mb-3">
								<Form.Control type="email" placeholder={"🔍 " + t('email')} onChange={handleChangeMail} />
							</Form.Group>
						</th>
						<th className='align-middle'>{t('sys-language')}</th>
						<th className="align-middle text-center" style={{ paddingBottom: "20px" }}>
							<p className="mb-1">{t('active')}</p>
							<BooleanDropdown onChange={setFilterPswd} value={filterPswd} />
						</th>
						<th className="align-middle text-center" style={{ paddingBottom: "20px" }}>
							<p className="mb-1">{t('sys-compliance')}</p>
							<BooleanDropdown onChange={setFilterCompliance} value={filterCompliance} />
						</th>
						<th className="align-middle text-center" style={{ paddingBottom: "20px" }}>
							<p className="mb-1">{t('sys-CSR_manager')}</p>
							<BooleanDropdown onChange={setFilterHeadCSR} value={filterHeadCSR} />
						</th>
						<th className="align-middle text-center" style={{ paddingBottom: "20px" }}>
							<p className="mb-1">{t('sys-admin')}</p>
							<BooleanDropdown onChange={setFilterAdmin} value={filterAdmin} />
						</th>
						<th>
							<Form.Group controlId="filterCode" className="mt-2 mb-3">
								<Form.Control type="text" placeholder={"🔍 " + t('sys-worker_code')} onChange={handleChangeCode} />
							</Form.Group>
						</th>
					</tr>
				</thead>
				<tbody>
					{
						filtered_users.map(function (user) {
							return (
								<LinkContainer to={{ pathname: "/system/users/" + user.id }} key={user.id}>
									<tr>
										<td className="text-center">{user.id}</td>
										<td className="text-center">{user.username}</td>
										<td>{user.email}</td>
										<td>{user.language}</td>
										<td className="text-center"><Boolean value={user.password_set} /></td>
										<td className="text-center"><Boolean value={user.role_compliance} /></td>
										<td className="text-center"><Boolean value={user.role_csr_head} /></td>
										<td className="text-center"><Boolean value={user.superuser} /></td>
										<td>{user.tw_kodpracovnika}</td>
									</tr>
								</LinkContainer>
							);
						})
					}
				</tbody>
			</Table>
		</ErrorWrap>
	);
}
