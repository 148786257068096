/**
 * @file Messenger component for quick communication about HIS objects
 * to other users.
 * @author Dominik Pantůček <dominik.pantucek@trustica.cz>
 */
/**
 * Messenger component for quick communication about HIS objects to other users.
 * @module comp/messenger
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { his_fetch, his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { MdEmail } from 'react-icons/md';
import { IoIosCopy } from "react-icons/io";
import { useParams } from "react-router-dom";
import { Loading } from './loading';
import { date_formatCZ } from '../lib/date-utils';
import { warehouses_names_ids_separe } from '../lists/warehouses-defs';
import { useTranslation } from 'react-i18next';

//seen all - OK

/**
 * Integrated card which allows for copying the link to clipboard or
 * expanding to send a short message to other user via email.
 * 
 * @param {userlogin} - login session object
 * @return {component} - the rendered component
 */
export function Messenger({ userlogin, type }) {
	const { t } = useTranslation();

	const { id } = useParams();
	const { code } = useParams();
	const { fetchDate } = useParams();
	const { wh } = useParams();

	const [statusMessage, setStatusMessage] = useState("");
	const [statusVariant, setStatusVariant] = useState("");
	const [showStatus, setShowStatus] = useState(false);
	const [show, setShow] = useState(false);
	const [productID, setProductID] = useState(null);
	const [loadedStatusProduct, setLoadedStatusProduct] = useState(0);

	useEffect(function () {
		if (type === 'prod' && productID === null) {
			const running_fetch = his_fetch(
				userlogin,
				[
					{
						uri: '/api/products/' + id.replaceAll('/', '%2F'),
						json: true,
						status: setLoadedStatusProduct,
						ok: function (resource, result) {
							//console.log(result);
							setProductID(result.product.k_IDProduktu);
						},
						error: function (resource, reason) {
							console.log('err: ' + reason);
						}
					}
				]
			);
			return () => {
				running_fetch();
			}
		}
	}, [id, code, userlogin, type, productID]);

	//console.log(productID);
	//console.log(code);

	function get_id(type) {
		switch (type) {
			case 'prod':
				return productID; //id produktu
			case 'nod':
				return code; // nod_KodDokladu
			case 'pdd':
				return id; // taky kod dokladu
			case 'inventory':
				return fetchDate + "/" + wh; // taky kod dokladu
			default:
				return "";
		}
	}

	function get_subject(type) {
		switch (type) {
			case 'prod':
				return (t('product_link') + " " + productID);
			case 'nod':
				return (t('order_purch_link') + " " + code);
			case 'pdd':
				return (t('delivery_note_link') + " " + id);
			case 'inventory':
				return (t('inventory_link') + " " + warehouses_names_ids_separe[wh] + t('due_day') + (fetchDate ? date_formatCZ(fetchDate) : "-"));
			default:
				return "";
		}
	}

	const objectID = get_id(type);
	// console.log(objectID);
	const subject = get_subject(type);
	//tady podle typu rozhoduju: , dostanu typ a userlogin , na začátku si dělám kontrolu, jeslti typ je validní 
	// u produktu dostavam kod produktu, ale my pracacujem s idckem - takze si zavolam fetcher, fetchnu si produkt a tam to id mám, 
	//	<Messenger userlogin={userlogin} id={product.k_IDProduktu} type={'prod'} subject={"Odkaz na produkt ID " + product.k_IDProduktu} />


	function copyLink() {
		const realURL = window.location.href;
		const notificationURLposition = realURL.search("notification");
		const finalURL = notificationURLposition > 0 ? realURL.slice(0, -15) : realURL;
		navigator.clipboard.writeText(finalURL);
		setStatusMessage(t('link_copied'));
		setStatusVariant("info");
		setShowStatus(true);
		setShow(false);
	}

	return (
		<Row className="mb-1">
			<Col className="text-end">
				<Alert show={showStatus} variant={statusVariant} className="mb-0 clearfix closerStyle" style={{ paddingTop: "6px", paddingBottom: "0px", paddingLeft: "4px", paddingRight: "4px" }}
				>
					<h5 className="float-end myHover me-2" style={{ cursor: "pointer" }} onClick={() => setShowStatus(false)}>×</h5>
					<span className="float-start me-5 ms-2">{statusMessage}</span>
				</Alert>
			</Col>
			<Col xs="auto">
				<OverlayTrigger onToggle={setShow} show={show} trigger="click" placement="left-start" overlay={
					<Popover style={{ maxWidth: "500px", width: "500px" }}>
						<div className='m-3'>
							<h5 className="float-end myHover" style={{ cursor: "pointer" }} onClick={() => setShow(false)}>×</h5>
							<br />
							{!his_fetch_success(loadedStatusProduct) && type === "prod" ?
								<Alert className="m-3 me-5" variant="danger">{t('mess-cannot_send_mess')}.</Alert>
								:
								<MessageForm userlogin={userlogin} statusMessage={statusMessage}
									setStatusMessage={setStatusMessage} setStatusVariant={setStatusVariant} setShowStatus={setShowStatus}
									subject={subject} id={objectID} type={type} setShow={setShow} />
							}
						</div>
					</Popover>
				}>
					<Button size="sm" className='pb-2' title={t('send_link_by_email')} variant="success"><span className="fs-5"><MdEmail /></span> {t('message')}</Button>
				</OverlayTrigger>
				<Button size="sm" onClick={copyLink} className="ms-2 pb-2" title={t('copy_link')} variant="info"><span className="fs-5 text-white"><IoIosCopy /></span></Button>
			</Col>
		</Row>
	);
}


function MessageForm({
	userlogin, statusMessage,
	setStatusMessage, setStatusVariant, setShowStatus,
	subject, id, type, setShow,
}) {
	const { t } = useTranslation();

	const [recValidated, setRecValidated] = useState(null);
	const [recipients, setRecipients] = useState(null);
	const [loadedStatus, setLoadedStatus] = useState(0);
	const [recipient, setRecipient] = useState("");
	const [message, setMessage] = useState("");
	//const [loadedStatusSending, setLoadedStatusSending] = useState(0);
	const [sendingEmailError, setSendingEmailError] = useState(false);
	const [sending, setSending] = useState(false);

	const reloadIt = () => {
		setRecValidated(null);
		setRecipients(null);
	}

	useEffect(() => {
		if (recipients === null) {
			const running_fetch = his_fetch(userlogin,
				[
					{
						uri: "/api/messaging/recipients",
						json: true,
						status: setLoadedStatus,
						ok: function (resource, result) {
							//	console.log(result);
							setRecipients(result.recipients);
						},
						error: function (resource, message) {
							console.log(message);
							setStatusMessage(t('cant_load_users_list'));
							setStatusVariant("danger");
							setShowStatus(true);
							setShow(false);
						}
					}
				]);
			return () => {
				running_fetch();
			}
		}
	}, [userlogin, recipients, setShow, setShowStatus, setStatusMessage, setStatusVariant, t]);

	function recipientValid() {
		return recipients
			.filter((rec) => rec[0] === recipient)
			.length === 1;
	}

	//console.log(loadedStatusSending);

	function sendMessage() {

		if (recipientValid()) {
			if (!sending) {
				setSending(true);
				//console.log("Sending ... " + recipient + " :: " + message);
				/*
				const recipientName = recipients
					.filter((rec) => rec[0] === recipient)[0][1];
					*/
				const link_url = (process.env.NODE_ENV === 'development') ?
					"https://his-dev.hsh-chemie.cz" + window.location.pathname :
					window.location.href;
				const messageStruct = {
					message: message,
					link_url: link_url,
					subject: "@fullname (@username) " + t('sends_you') + ": " + subject,
					[type]: id
				};
				const jsonData = JSON.stringify(messageStruct);
				//console.log(jsonData);
				his_fetch(userlogin,
					[
						{
							uri: "/api/messaging/to/" + recipient,
							json: true,
							//status: setLoadedStatusSending,
							args: {
								method: 'POST',
								body: jsonData
							},
							ok: function (resource, result) {
								//console.log(result);
								setStatusMessage(t('email_sent'));
								setStatusVariant("success");
								setShowStatus(true);
								setShow(false);
								setRecipient("");
								setMessage("");
								setSending(false);
							},
							error: function (resource, message) {
								console.log(message);
								setStatusMessage(t('sending_fail') + ": " + message);
								setStatusVariant("danger");
								setSendingEmailError(true);
								setSending(false);
							}
						}
					]);
			}
		} else {
			setRecValidated(false);
		}
	}

	if (!his_fetch_success(loadedStatus)) { //troublesome
		return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />;
	}

	function changeRecipient(newRecipient) {
		setRecipient(newRecipient);
		setRecValidated(null);
	}

	const isValid = recValidated === null ? false : recValidated;
	const isInvalid = recValidated === null ? false : !recValidated;

	const currentRecipients = recipients
		.filter((rec) => rec[0] === recipient);
	const recEmail = currentRecipients.length === 1 ? currentRecipients[0][2] : "";

	//console.log(recipients);

	return (
		<Card.Body>
			<Form.Group controlId="recipientSelect">
				<Form.Label>{t('to_whom')}:</Form.Label>
				<Form.Control as="select" value={recipient}
					onChange={(ev) => changeRecipient(ev.target.value)}
					isValid={isValid} isInvalid={isInvalid}>
					<option>&mdash; {t('choose_receiver')} &mdash;</option>
					{
						recipients.map(function (rec) {
							return <option key={rec[0]} value={rec[0]}>{rec[1]} ({rec[0]})</option>;
						})
					}
				</Form.Control>
				<Form.Control.Feedback type="invalid">
					{t('you_have_to_choose_receiver')}.
				</Form.Control.Feedback>
			</Form.Group>
			<br />
			<Form.Group controlId="messageText" onChange={(ev) => setMessage(ev.target.value)} value={message}>
				<Form.Label>{t('message_text_optional')}:</Form.Label>
				<Form.Control as="textarea" rows={3} />
			</Form.Group>
			<Row className="mb-3">
				<Col>
					<span className="text-muted">
						{recEmail}
					</span>
				</Col>
				<Col xs="auto">
					<Button size="sm" className="mt-2" onClick={sendMessage} disabled={sending}>
						{sending ? <Loading message={t('sending')} margin="0" size="small" /> : t('send')}</Button>
				</Col>
			</Row>
			<Alert dismissible onClose={() => setSendingEmailError(false)} className="mb-0" show={sendingEmailError} variant="danger">{statusMessage}</Alert>
		</Card.Body>
	);
}

