/**
 * Form for picking date.
 * @module comp/date-picker 
 * @author Lucie Zdeňková <lucie.zdenek@trustica.cz>
 */

import React from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputGroup from 'react-bootstrap/InputGroup';
import { FaCalendarAlt } from 'react-icons/fa';
import { isFunction } from '../lib/utils';
import { date_formatCZ } from '../lib/date-utils';

//seen all - OK

/** 
 * Form for date. 
 * @param {function} onChange - changing function for DatePicker
 * @param {date} minDate - soonest date to choose from
 * @param {date} maxDate - lastest date to choose from 
 * @param {date} value - value of the input
 * @param {string} size - size of the input, md is default
 * 
 * @return - Form for chooosing date
 */

export function DatePickerCustom({ onChange, minDate, maxDate, value, size = "md" }) {
    //console.log(minDate);
    //console.log(maxDate);
    const MyInput = React.forwardRef(
        ({ value, onClick }, ref) => (
            <InputGroup onClick={onClick} ref={ref} style={{ minWidth: "130px", maxWidth: "200px" }} >
                <Form.Control size={size} className='bg-white' readOnly value={date_formatCZ(value)} />
                <InputGroup.Text><FaCalendarAlt /></InputGroup.Text>
            </InputGroup>
        ),
    );

    const changeDate = function (date) {
        if (isFunction(onChange)) {
            onChange(date);
        }
    }

    return (
        <DatePicker
            id="date-validity"
            locale="cs"
            //dateFormat="dd. MM. yyyy"
            selected={value}
            minDate={minDate}
            maxDate={maxDate}
            onChange={changeDate}
            customInput={<MyInput />}
            showYearDropdown
            yearDropdownItemNumber={30}
            scrollableYearDropdown
        />
    );
}