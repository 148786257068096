/**
 * fetching function used accross HIS, fetching status component.
 * @module comp/FetchLoader
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */

import React from 'react';
import { Loading } from './loading';
import { his_frontend_version } from '../lib/version';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import { useTranslation } from 'react-i18next';

//seen all - OK

async function get_result_json(res) {
	return await res.json();
}

async function get_result_string(res) {
	const data = await res.body.getReader().read();
	return new TextDecoder().decode(data.value);
}

/****************************************************************
 * userlogin: see App.js
 * resources: [] of {}:
 *   * uri: string
 *   * args: {}
 *   * json: boolean
 *   * start: optional function
 *   * error: optional function
 *   * ok: function that accepts result
 * All the functions get the resource as first argument and possible result as the second one.
 *   * block: boolean (default false)
 *   * retry: integer > 0
 *   * status: function that sets the status
 *   * devlocal: true if no NODE_ENV rewrite for localhost (default false)
 */
export function his_fetch(userlogin, resources) {
	const runFetchers = {
		run: true
	};
	const backgroundController = new AbortController();
	const backgroundSignal = backgroundController.signal;

	//
	// Handles one fetch and possibly initiates next in the queue
	function doFetch(index = 0, retry = 0) {
		if ((runFetchers.run) &&
			(index < resources.length)) {

			// Get current queued resource and extract information
			const resource = resources[index];
			const args = resource.args || {};
			const parse_json = resource.json === true;
			const blocker = resource.block === true;

			// Compose real URI and encode it for use with fetch
			const nodevlocal = !(resource.devlocal === true);
			// const uriprefix = (nodevlocal && (process.env.NODE_ENV === 'development')) ? 'https://his-test.sk.hsh-chemie.com' : '';
			// const uriprefix = (nodevlocal && (process.env.NODE_ENV === 'development')) ? 'https://his.sk.hsh-chemie.com' : '';
			// const uriprefix = (nodevlocal && (process.env.NODE_ENV === 'development')) ? 'https://his-test.cz.hsh-chemie.com' : '';
			// const uriprefix = (nodevlocal && (process.env.NODE_ENV === 'development')) ? 'https://his.cz.hsh-chemie.com' : '';
			const uriprefix = (nodevlocal && (process.env.NODE_ENV === 'development')) ? 'https://his-dev.cz.hsh-chemie.com' : '';
			const encodedURI = encodeURI(uriprefix + resource.uri);

			// Called when fetch promise succeeds               
			function onFulfilled(res) {
				// Data, JSON, whatever ...
				//console.log(res);
				const resultPromise = parse_json ?
					get_result_json(res) : get_result_string(res);
				resultPromise.then(
					function (result) {
						if (res.status >= 500) {
							if (resource.status) {
								resource.status(3);
							}
							if (resource.error) {
								resource.error(resource, "Server error " + res.status);
							}
							return;
						}
						//console.log(result);
						if (parse_json) {
							// Check for client upgrades
							if (result["error"] === "unauthenticated") {
								userlogin.handleLogerr(true);
								return;
							}
							const api_frontend_version = result.frontend_version;
							if (!(process.env.NODE_ENV === 'development')) {
								if (api_frontend_version !== his_frontend_version()) {
									// Upgrade pending
									console.log('api_frontend_version: ' + api_frontend_version);
									console.log('frontend_version: ' + his_frontend_version());
									window.location.reload(); // disable for now
								}
							}
						}
						if (resource.ok) {
							resource.ok(resource, result, res);
						}
						if (resource.status) {
							// Success - after processing ok function
							resource.status(2);
						}
						// Go to next, if successfull and heading to next
						doFetch(index + 1);
					},
					function (reason) {
						if (resource.status) {
							// Permanent error (data)
							resource.status(3);
						}
						if (resource.error) {
							// Data parsing error (not network)
							resource.error(resource, "Data failure: " + reason);
						}
						if (!blocker) {
							doFetch(index + 1);
						}
					});
			}

			// Called when fetch fails - Network error (not data parsing)
			function onRejected(reason) {
				// Retry?
				const retries = resource.retry || 1;
				if (runFetchers.run) {
					if (retry < retries) {
						// console.log([retry, retries]);
						if (resource.status) {
							// Retrying after network error
							resource.status(4);
						}
						doFetch(index, retry + 1);
					} else {
						if (resource.status) {
							// Permanent network error
							if (resource.error) {
								resource.error(resource, "Network failure: " + reason);
							}

							resource.status(3);
						}
						// Continue or not?
						if (!blocker) {
							doFetch(index + 1);
						}
					}
				}
			}

			if (resource.status) {
				// Loading
				resource.status(1);
			}

			if (resource.start) {
				resource.start(resource);
			}

			// Run the async fetch
			fetch(encodedURI,
				{
					...args,
					credentials: 'include',
					cache: 'no-cache',
					signal: backgroundSignal
				}).then(onFulfilled, onRejected);
		}
	}

	doFetch();

	//
	// Returns function that can be used to stop the process
	return function () {
		runFetchers.run = false;
		backgroundController.abort();
	}
}

/****************************************************************
 * status:
 *   * 0 - waiting
 *   * 1 - downloading
 *   * 2 - success
 *   * 3 - error
 *   * 4 - retrying (like 1)
 *
 * TODO:
 *   * configurable messages for all statuses (dictionary)
 *   * configurable icons/animations
 *   * configurable with/without message display
 *   * configurable standalone / in-text display
 *   * configurable display of checkmark(?) for status 2?
 */

export function HisFetchStatus({ status, loadingType, errorType, waitingType, reloadButton }) {
	const { t } = useTranslation();

	switch (status) {
		case 0:
			switch (waitingType) {
				case "explained":
					return <div className='my-alert-info' >⏱️ {t('loading_will_start')}.</div>
				default:
					return <span>&nbsp;⏱️</span>;
			}
		case 1:
		case 4:
			switch (loadingType) {
				case "big":
					return <Loading />;
				case "Načítání":
					return <Loading margin="0" size="small" />;
				default:
					return <Loading message='' margin="0" size="small" />;
			}
		case 2:
			return <></>;
		case 3:
			switch (errorType) {
				case "fetcherError":
					return <div className='my-alert-red' >⚠ {t('network_fail_contact')}. &nbsp;&nbsp;
						<Button className="my-1" variant="light" size="sm" onClick={reloadButton}>
							<Image src="/img/reload_black.svg" height="20" /> {t('load_again')}</Button></div>
				default:
					return <span className="text-danger fw-bold">&nbsp;⚠</span>;
			}
		default: // Unknown
			return <span className="text-warning fw-bold">&nbsp;? {t('unknown_status')}</span>;
	}
}

/* Returns true if given status is success. (nahrazuje isLoading 1:1)
 */
export function his_fetch_success(status) {
	return status === 2;
}

export function his_fetch_loading(status) {
	return (status === 1) || (status === 4);
}

export function his_fetch_error(status) {
	return status === 3;
}
