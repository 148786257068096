/**
 * Component for app rendering of list of delivery notes sales
 * @module delivery-notes/delivery-note-sales-app
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */

import React from 'react';
import { Row, Col, Accordion, ListGroup, Card, Form, Table } from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap';
import { date_formatCZ } from '../lib/date-utils';
import { Boolean } from '../comp/boolean';
import { BooleanDropdown } from '../comp/boolean';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';
import { mappings } from './delivery-notes-sales';

//seen all - OK

/**
 * Mobile list of delivery notes with filters
 * 
 * @param {any} dnotes_shown - delivery notes to display on one page 
 * @returns {component}
 */
export function PDListMobile({ dnotes_shown, setFilterExport, setFilterClosed, setFilterApproved, setFilterCancelled,
	handleChangeAppFilter, massFilterApp, filterExport, filterClosed, filterApproved, filterCancelled, type, isLoaded }) {
	const { t } = useTranslation();

	const mapping = mappings[type];

	return (
		<ErrorWrap>
			<div className="d-lg-none mb-2">
				<br />
				<Form.Group className="m-1" controlId="filterWriter1">
					<Form.Label>{t('mass_filter')}</Form.Label>
					<Form.Control type="text" placeholder="&#128269;" value={massFilterApp} onChange={handleChangeAppFilter} />
					<Form.Text className="text-muted">
						{t('deno-search_code_order_partner_note_creator')}
					</Form.Text>
				</Form.Group>
				<Row>
					<Col xs={6}>
						<p className="mb-0 mt-1 text-center">{type === "PD" ? t('deno-exported') : t('ord-imported')}</p>
						<BooleanDropdown variant="onlyTrue" onChange={setFilterExport} value={filterExport} className="beGrayButton" />
					</Col>
					<Col xs={6}>
						<p className="mb-0 mt-1 text-center">{t('biz-closed')}</p>
						<BooleanDropdown variant="onlyTrue" onChange={setFilterClosed} value={filterClosed} className="beGrayButton" />
					</Col>
				</Row>
				<Row>
					<Col xs={6}>
						<p className="mb-0 mt-1 text-center">{t('deno-approved')}</p>
						<BooleanDropdown variant="onlyTrue" onChange={setFilterApproved} value={filterApproved} className="beGrayButton" />
					</Col>
					<Col xs={6}>
						<p className="mb-0 mt-1 text-center">{t('canceled')}</p>
						<BooleanDropdown variant="negative" onChange={setFilterCancelled} value={filterCancelled} className="beGrayButton" />
					</Col>
				</Row>
				<br />
				<Accordion>
					{isLoaded ?
						dnotes_shown.map(function (dn) {
							return (<SinglePDMobile
								key={dn[mapping[0]]}
								keyx={dn[mapping[0]]}
								code={dn[mapping[0]]}
								order={dn[mapping[1]]}
								exported={dn[mapping[2]]}
								partner={dn[mapping[3]]}
								partnerName={dn[mapping[4]]}
								note={dn[mapping[5]]}
								date={dn[mapping[6]]}
								invoiced={dn[mapping[7]]}
								closed={dn[mapping[8]]}
								approved={dn[mapping[9]]}
								cancelled={dn[mapping[10]]}
								writer={dn[mapping[11]]}
								type={type}
							/>);
						})
						:
						<></>
					}
				</Accordion>
			</div>
		</ErrorWrap>
	);
}

/**
 * Return single mobile line of delivery note
 * 
 * @param {*} param0 
 * @returns {component}
 */
function SinglePDMobile({ keyx, code, order, exported, partner, partnerName, note, date, invoiced, closed, approved, cancelled, writer, type }) {
	const { t } = useTranslation();

	const url = type === "PD" ? "/delivery-notes/sales/" : "/delivery-notes/purchase/";

	return (
		<Accordion.Item eventKey={keyx}>
			<Accordion.Header>
				<Table size="sm" borderless className='m-0'>  
					<tbody>
						<tr>
							<td>{code}</td>
							<td className='text-end'>{type === "PD" ? t('deno-exported') : t('ord-imported')}:</td>
							<td><Boolean variant="onlyTrue" value={exported} /></td>
						</tr>
						<tr>
							<td>{order}</td>
							<td className='text-end'>{t('biz-closed')}:</td>
							<td><Boolean variant="onlyTrue" value={closed} /></td>
						</tr>
						<tr>
							<td>{t('biz-invoiced')}: {invoiced} %</td>
							<td className='text-end'>{t('deno-approved')}:</td>
							<td><Boolean variant="onlyTrue" value={approved} /></td>
						</tr>
						<tr>
							<td>{type === "PD" ? t('deno-delivery_date') : t('deno-stockin_date')}: {date ? date_formatCZ(date) : "-"}</td>
							<td className='text-end'>{t('canceled')}:</td>
							<td><Boolean variant="negative" value={cancelled} /></td>
						</tr>
					</tbody>
				</Table>
			</Accordion.Header>
			<Accordion.Body className='p-2'>
				<LinkContainer to={{ pathname: url + code }}>
					<Card.Body className="beSmaller">
						<ListGroup variant="flush" className="smaller">
							<ListGroup.Item>{t('partner')}: {partner}</ListGroup.Item>
							<ListGroup.Item>{t('name')}: {partnerName}</ListGroup.Item>
							<ListGroup.Item>{t('note_short')}: {note}</ListGroup.Item>
							<ListGroup.Item>{t('deno-issuer')}: {writer}</ListGroup.Item>
						</ListGroup>
					</Card.Body>
				</LinkContainer>
			</Accordion.Body>
		</Accordion.Item>
	);
}