/**
 * Single product view.
 * @module products/product-detail
 * @author Lucie Zdenkova <lucie.zdenek@trustica.cz>
 */
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import { Notes } from '../notes/notes';
import { his_fetch, his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import Button from 'react-bootstrap/Button';
import { findLongestPrefixLen } from './products-check-overview-horizontal';
import { date_time_format, number_formatTime } from '../lib/date-utils';
import { make_checker } from '../comp/checker';
import { OkOrProblemsCard } from '../comp/problems-card';
import { ShowADR } from '../comp/adr';
import { warehouses } from '../lists/warehouses-defs';
import {
	check_product_hsh_adr, check_product_warehouse_adr,
	check_product_hsh_container, check_product_warehouse_container,
	check_product_warehouse_density, package_weight_compare,
	check_product_hsh_density, check_product_hsh_MJ, check_product_hsh_amount, check_product_wh_MJ,
	check_product_wh_amount, check_product_expiration, check_product_twist_approval,
	check_product_his_approval, check_suppliers_addresses, check_suppliers_address_type,
	check_product_batches_expirations_monitoring, check_product_adr, check_basic_package,
	check_product_pH
} from '../products/product-checks';
import { get_package_weight_kg, check_package_weight_kg_input } from '../lib/generic-checks';
import { Emails } from '../emails';
import { ErrorWrap } from '../comp/errorwrap';
import { ProductCompareApp, HistoryApp } from './product-detail-app';
import { IsActive, Packagings, AltNames, BasicInfo, Supplier, ProductCompareWeb, HistoryTableWeb } from './product-detail-web';
import Accordion from 'react-bootstrap/Accordion';
import { statuses_sending_card_strs } from '../lists/statuses-sending-card';
import { useTranslation } from 'react-i18next';
import { ProductConfiguration } from './product-config';

//seen all - OK

//new code for přijato (čas odmítnutí nebo přijetí serverem - pokud je 4 tak tam dát 4, pokud 5 tak 5, pokud ani jeden, tak vzít 5, potom 4, potom empty)
function status_4or5(whStatuses, warehouse_status) {
	const status_4_date = whStatuses["4"] ? whStatuses["4"] : false;
	//	console.log(status_4_date);
	//	console.log(warehouse_status);
	const status_5_date = whStatuses["5"] ? whStatuses["5"] : false;
	if (warehouse_status === "4" || warehouse_status === 4) {
		return status_4_date;
	} else if (warehouse_status === "5" || warehouse_status === 5) {
		return status_5_date;
	} else if (status_5_date) {
		return status_5_date;
	} else if (status_4_date) {
		return status_4_date;
	} else {
		return "";
	}
}

// Returns actual product from the warehouse - should be changed after
// merging to warehouse_statuses on the backend
function getProductInWarehouse(productCheck, wid) {
	//console.log(productCheck);
	const productsInWarehouse = productCheck.filter((p) => p.pci_warehouse_id === wid);
	//console.log("xxx");
	//console.log(productsInWarehouse);
	const isProductInWarehouse = productsInWarehouse.length > 0;
	const piw = isProductInWarehouse ? productsInWarehouse[0] : {};
	return piw;
}

function isWarehouseStatusChanging(warehouseStatus, piw) {
	if (warehouseStatus.status === 6) {
		return false;
	}
	var changing = false;
	if (warehouseStatus[1]) {
		changing = true;
	}
	if (warehouseStatus[2]) {
		changing = true;
	}
	if (warehouseStatus.status === 4) {
		if (warehouseStatus[2] < warehouseStatus[4]) {
			changing = false;
		}
	} else if (warehouseStatus.status === 5) {
		if (warehouseStatus[2] < warehouseStatus[5]) {
			changing = false;
		}
	} else { // 1, 2, 3
		if (warehouseStatus[5]) {
			if (warehouseStatus[2] < warehouseStatus[5]) {
				changing = false;
			}
		} else if (warehouseStatus[4]) {
			if (warehouseStatus[2] < warehouseStatus[4]) {
				changing = false;
			}
		}
	}
	if (piw.pci_created) {
		if (warehouseStatus[2]) {
			if (warehouseStatus[2] < piw.pci_created) {
				changing = false;
			}
		}
	}
	//console.log('changing: ' + changing);
	return changing;
}

function areWarehouseStatusesChanging(productCheck, warehouseStatuses) {
	return Object.keys(warehouseStatuses)
		.filter((wid) => isWarehouseStatusChanging(warehouseStatuses[wid],
			getProductInWarehouse(productCheck, wid)))
		.length > 0;
}

export function ProductDetail({ user, userinfo, userlogin }) {
	const { t } = useTranslation();

	const { id } = useParams();

	const [product, setProduct] = useState(null);
	const [loadedStatus, setLoadedStatus] = useState(0);
	const [suppliers, setSuppliers] = useState(null);
	const [altNames, setAltNames] = useState([]);
	const [productCheck, setProductCheck] = useState([]);
	const [newWarehousesStatuses, setNewWarehousesStatuses] = useState([]);
	const [oldWarehousesStatuses, setOldWarehousesStatuses] = useState([]);
	const [emails, setEmails] = useState([]);
	const [packagings, setPackagings] = useState([]);
	const [cardHistory, setCardHistory] = useState([]);
	const [reloadingPeriod, setReloadingPeriod] = useState(0); //0 = no backgroud reloading, or milisecond

	const reloadIt = () => {
		setLoadedStatus(0);
		setProduct(null);
	}

	useEffect(function () {
		if (product === null) {
			const running_fetch = his_fetch(
				userlogin,
				[
					{
						uri: '/api/products/' + id.replaceAll('/', '%2F'),
						json: true,
						status: setLoadedStatus,
						ok: function (resource, result) {
							//console.log(result);
							setProduct(result.product);
							setSuppliers(result.supplier_data);
							//console.log(suppliers);
							//console.log(result);
							// console.log(result.alternative_names);
							setAltNames(result.alternative_names);
							//console.log(result.product);
							setProductCheck(result.product_check);
							setNewWarehousesStatuses(result.warehouses_statuses);
							setOldWarehousesStatuses(result.warehouses_statuses);
							//console.log(result.product_check);
							//console.log(result.warehouses_statuses);
							setEmails(result.emails);
							setPackagings(result.packagings);
							setCardHistory(result.cards_history);
							//toto je důležité, tady budu měnit reloading period
							if (areWarehouseStatusesChanging(result.product_check, result.warehouses_statuses)) {
								//console.log("let's reload!");
								//scheduleTimeout();
								setReloadingPeriod(5 * 1000);
							}
						},
						error: function (resource, reason) {
							console.log('err: ' + reason);
							setProduct("error");
						}
					}
				]
			)
			return () => {
				running_fetch();
			}
		} else {
			if (reloadingPeriod > 0) {
				//console.log("starting timeout");
				const timeout_id = setTimeout(() => {
					//console.log("tick tock");
					his_fetch(
						userlogin,
						[
							{
								uri: '/api/products/' + id.replaceAll('/', '%2F') + '/warehouses-statuses',
								json: true,
								ok: function (resource, result) {
									//console.log("updated!");
									//console.log(result);
									//scheduleTimeout(); //už není potřeba
									setNewWarehousesStatuses(result);
								},
								error: function (resource, reason) {
									console.log('err: ' + reason);
									setNewWarehousesStatuses("error");
								}
							}
						]
					);
				}, reloadingPeriod);
				//console.log("timeout set");
				return () => {
					clearTimeout(timeout_id);
				};
			}
		}
	}, [userlogin, id, product, reloadingPeriod, newWarehousesStatuses]);

	//console.log(reloadingPeriod);

	if (!his_fetch_success(loadedStatus)) {
		return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />;
	}

	const warehousesStatuses = (newWarehousesStatuses !== null && newWarehousesStatuses !== "error") ? newWarehousesStatuses : oldWarehousesStatuses !== null ? oldWarehousesStatuses : [];

	//console.log(product.k_ADRObalovaSkupina ? true : false);
	const expected_package_group = [0, "0", 1, "1", 2, "2"];
	const right_package_group = expected_package_group.includes(product.k_ADRObalovaSkupina);

	const { performCheck, ErrorBadge, TodoList, checksOK, worstCheckResult } = make_checker();

	check_product_hsh_container(performCheck, "hsh", product.k_HSHObal5); //danger
	check_product_hsh_amount(performCheck, "hsh", product.MnozstviSklMjVObalu); //danger
	check_product_hsh_MJ(performCheck, "hsh", product.KodMjSkl); //danger
	check_product_hsh_density(performCheck, "hsh", product.HmotnostMj, product.MjHmotnostiMj);  //danger
	check_product_hsh_adr(performCheck, "hsh", product.k_ADR,
		product.TridaADR, product.k_ADRObalovaSkupina, product.k_ADRUNCislo); //danger

	// 666: "Počítání hmotnosti obalu v kg" - pro zobrazení a vstup pro check
	//console.log(product);
	const [packageWeightInputsOK_HSH] = check_package_weight_kg_input(product.HmotnostMj, product.MjHmotnostiMj, product.MnozstviSklMjVObalu);
	const package_weight_kg_hsh = packageWeightInputsOK_HSH ? get_package_weight_kg(product.HmotnostMj, product.MjHmotnostiMj, product.MnozstviSklMjVObalu) : null;
	//console.log(package_weight_kg_hsh);

	// const worth_display1 = product.MnozstviSklMjVObalu && product.HmotnostMj;
	// const package_weight_kg_hsh_count = Fraction(product.MnozstviSklMjVObalu).mul(Fraction(product.HmotnostMj));
	// const package_weight_kg_hsh = worth_display1 ? String(package_weight_kg_hsh_count) : <>&mdash;</>;

	const soFarSoGood = checksOK();

	const preprocessedWarehouses = warehouses.map(function (wh) {
		const piw = getProductInWarehouse(productCheck, wh[0]);
		const isProductInWarehouse = !!piw["pcr_k_IDProduktu"];
		// 666: "Počítání hmotnosti obalu v kg" - pro zobrazení a vstup pro check
		//console.log(piw);

		const [packageWeightInputsOK_wh] = check_package_weight_kg_input(piw.pcr_HmotnostMjVKg, "kg", piw.pcr_MnozstviSklMjVObalu); //ze skladu to nechodí
		const package_weight_kg_wh = packageWeightInputsOK_wh ? get_package_weight_kg(piw.pcr_HmotnostMjVKg, "kg", piw.pcr_MnozstviSklMjVObalu) : null;

		//	const worth_display2 = piw.pcr_MnozstviSklMjVObalu && piw.pcr_HmotnostMjVKg;
		//const package_weight_kg_wh_count = Fraction(piw.pcr_MnozstviSklMjVObalu).mul(Fraction(piw.pcr_HmotnostMjVKg));
		//	const package_weight_kg_wh = worth_display2 ? String(package_weight_kg_wh_count) : <>&mdash;</>;

		if (isProductInWarehouse && soFarSoGood) {
			//console.log("checking wh " + wh[0]);
			//console.log(piw);
			//console.log('' + piw.pcr_TridaADR);

			/* check_product_warehouse_values(performCheck, "wh-" + wh[0], piw.pcr_NazevProduktu, piw.pcr_HmotnostMjVKg,
				piw.pcr_MnozstviSklMjVObalu, piw.pcr_KodMjSkl, true); - tento check tu nemá smysl - řešíme to konkrétně
				*/


			check_product_warehouse_container(performCheck, "wh-" + wh[0], product.k_HSHObal5, wh[0], piw.pcr_k_HSHObal5, true); //warning
			check_product_wh_amount(performCheck, "wh-" + wh[0], piw.pcr_MnozstviSklMjVObalu, product.MnozstviSklMjVObalu, true); //warning
			check_product_wh_MJ(performCheck, "wh-" + wh[0], piw.pcr_KodMjSkl, product.KodMjSkl, true);  //warning
			check_product_warehouse_density(performCheck, "wh-" + wh[0], piw.pcr_HmotnostMjVKg, product.HmotnostMj, true); //warning
			package_weight_compare(performCheck, "wh-" + wh[0], package_weight_kg_hsh, package_weight_kg_wh, true); //warning
			check_product_warehouse_adr(performCheck, "wh-" + wh[0], product.k_ADR,
				product.TridaADR, product.k_ADRObalovaSkupina, product.k_ADRUNCislo,
				piw.pcr_TridaADR, piw.pcr_k_ADRObalovaSkupina, piw.pcr_k_ADRUNCislo, true);     //warning
		}
		const stavKontroly = (checksOK("package-weight-compare-wh-" + wh[0], "warning") && isProductInWarehouse && soFarSoGood) ? t('yes') : t('no'); //stav kontroly má časem říkat, jeslti se daný produkt se smí avizovat, produkt je v pořádku pro účely skladů

		//shoduje se do jisté míry název produktu?
		const wNazevProduktu = piw.pcr_NazevProduktu || "";
		const prefixLen = isProductInWarehouse ? findLongestPrefixLen([product.NazevProduktu, wNazevProduktu]) : 0;
		const nazevPrefix = wNazevProduktu.substring(0, prefixLen);
		const nazevSuffix = wNazevProduktu.substring(prefixLen);
		const superClass5 = "alert-success";
		//zpětně načtena
		const readback_datetime = piw.pci_created;
		const readback_datetime_str = readback_datetime ? date_time_format(readback_datetime) : "-";
		const readback_datetime_class = readback_datetime ? "text-end align-middle" : "text-center align-middle";
		// stav komunikace
		const whStatuses = warehousesStatuses[wh[0]];
		const isthereWarehouse = (whStatuses && true) || false;
		const warehouse_status = !isthereWarehouse ? "" : whStatuses["status"];
		const warehouse_status_str = t(statuses_sending_card_strs[warehouse_status]) || "-";
		//odeslána
		const sent_status_2 = !isthereWarehouse ? "" : whStatuses["2"] || "";
		const sent_status_str = sent_status_2 ? date_time_format(sent_status_2) : "-";
		//přijata
		const received_status = !isthereWarehouse ? "" : status_4or5(whStatuses, warehouse_status);
		const received_status_str = received_status ? date_time_format(received_status) : "-";
		const canCompareSentReceived = sent_status_2 && received_status;
		const receivedIsSmaller = received_status < sent_status_2;
		const received_class = canCompareSentReceived ? receivedIsSmaller ? " alert-danger" : "" : "";

		const readback_maybe_confirmed = readback_datetime && sent_status_2;
		const readback_confirmed = readback_maybe_confirmed && (sent_status_2 < readback_datetime);
		const readback_class = readback_datetime_class + (readback_maybe_confirmed ? readback_confirmed ? " alert-success" : " alert-danger" : " alert-secondary");

		const ADRBelongsToADRWarehouse = (wh[0] !== 22 && wh[0] !== 30) && product.k_ADR;
		const states_1_2_3 = warehouse_status === 1 || warehouse_status === 2 || warehouse_status === 3;
		const states_2_3 = warehouse_status === 2 || warehouse_status === 3;
		const compliance_can_send_ADR_anywhere = userlogin.userinfo.role_compliance || userlogin.userinfo.his_superuser;

		const title = (wh[0] !== 22 && wh[0] !== 30) && product.k_ADR ? t('the_wh_isnt_ADR_but_product_is') : "";

		const timeDiff = sent_status_2 ? Math.abs(new Date() - new Date(sent_status_2)) / 1000 : false;
		const timeOver = timeDiff > 15 * 60;
		const showCancel = timeOver && states_2_3 && !readback_confirmed;
		const show_timeDiff = states_2_3 && !readback_confirmed ? <>{number_formatTime(timeDiff)}<br /></> : "";
		return {
			...wh,
			piw: piw,
			isProductInWarehouse: isProductInWarehouse,
			package_weight_kg_wh: package_weight_kg_wh,
			stavKontroly: stavKontroly,
			nazevPrefix: nazevPrefix,
			nazevSuffix: nazevSuffix,
			superClass5: superClass5,
			readback_datetime_str: readback_datetime_str,
			warehouse_status_str: warehouse_status_str,
			sent_status_str: sent_status_str,
			received_status_str: received_status_str,
			received_class: received_class,
			readback_class: readback_class,
			states_1_2_3: states_1_2_3,
			readback_confirmed: readback_confirmed,
			ADRBelongsToADRWarehouse: ADRBelongsToADRWarehouse,
			compliance_can_send_ADR_anywhere: compliance_can_send_ADR_anywhere,
			showCancel: showCancel,
			show_timeDiff: show_timeDiff,
			title: title,
		}
	});

	check_suppliers_addresses(performCheck, "hsh", suppliers);  //danger
	check_suppliers_address_type(performCheck, "hsh", suppliers); //danger
	check_product_twist_approval(performCheck, "hsh", product.approved_in_twist); //danger
	check_product_his_approval(performCheck, "hsh", product.needs_approval, product.approved_in_his);  //danger
	check_product_pH(performCheck, "hsh", product.TridaADR, product.ph); //danger

	const configured = parseInt(product.pwc_id || "0") > 0; //používá se i na dalších místech tu
	check_product_batches_expirations_monitoring(performCheck, "hsh", configured);  //danger
	check_product_expiration(performCheck, "hsh", product.pwc_id, product.months); //danger
	check_product_adr(performCheck, "hsh", product.k_ADR, true);  //warning
	check_basic_package(performCheck, "hsh", packagings); //danger

	const approved = product.product_approved;
	const no_fatal_errors = checksOK();

	return (
		<ErrorWrap>
			<h4><Button size="sm" className="me-2 d-inline" onClick={reloadIt}><Image src="/img/reload.svg" height="19" /></Button>
				{t('product')} {product.k_IDProduktu}: {product.NazevProduktu}
				<IsActive active={product.Aktivni} />
				<ShowADR isADR={product.k_ADR} />
			</h4>
			<Row>
				<Col lg={4} className="mb-3">
					<BasicInfo product={product} />

				</Col>
				<Col lg={4}>
					<OkOrProblemsCard ok={no_fatal_errors} headerOk={t('product_card_is_ok')} textOk="" todo={<TodoList />} variant={worstCheckResult()} />
				</Col>
				<Col lg={4}>
					<ProductConfiguration userlogin={userlogin} product={product}
						reloadProduct={reloadIt}
						ErrorBadge={ErrorBadge} />
				</Col>
			</Row>
			<ProductWarehouses
				preprocessedWarehouses={preprocessedWarehouses} package_weight_kg_hsh={package_weight_kg_hsh}
				product={product}
				userlogin={userlogin} approved={approved} configured={configured}
				no_errors={no_fatal_errors} reloadIt={reloadIt} ErrorBadge={ErrorBadge}
				right_package_group={right_package_group} />
			<CardsHistory cardHistory={cardHistory} ErrorBadge={ErrorBadge} />
			<Row>
				<Col lg={5}>
					<Supplier suppliers={suppliers} product={product} ErrorBadge={ErrorBadge} />
					<br />
					<Packagings packagings={packagings} ErrorBadge={ErrorBadge} />
					<br />
				</Col>
				<Col lg={7}>
					<AltNames names={altNames} />

					<br />
					<Notes user={user} userinfo={userinfo} object_type={3} object_code={'' + product.k_IDProduktu} enabled_notes={[1]} />
					<br />
				</Col>
			</Row>
			{emails.length > 0 ?
				<>
					<Card className='myShadow mb-4'>
						<Card.Body className='px-0 pb-0'>
							<Emails userlogin={userlogin} pagesize={5} existingEmails={emails} noFetch optionalHeader={t('prod-emails') + " " + product.k_IDProduktu} />
						</Card.Body>
					</Card>
				</>
				: ""}
		</ErrorWrap>
	);
}

function getHistoryInWarehouse(history, wid) {
	const historyInWarehouse = history.filter((p) => p.wid === wid);
	return historyInWarehouse;
}

function card_history_record_equal(h1, h2) {
	const keys = [
		"pcr_HmotnostMjVKg",
		"pcr_KodMjSkl",
		"pcr_MnozstviSklMjVObalu",
		"pcr_NazevProduktu",
		"pcr_TridaADR",
		"pcr_gw_check_batch",
		"pcr_gw_check_bbd",
		"pcr_k_ADRObalovaSkupina",
		"pcr_k_ADRUNCislo",
		"pcr_k_HSHObal5",
		"pcr_safety_sheet"
	];
	return keys.reduce(
		function (acc, key) {
			return acc && (h1[key] === h2[key]);  //předchozí hodnoty a nová hodnota, 1 a 1 a 0... pokud tam bude aspoń jedna nula, tak smůla
		},
		true);
}

function card_history_record_adjoined(h1, h2) {
	const t1s = h1.pci_invalidated;
	const t2s = h2.pci_created;
	if (t1s === null) {
		throw new Error("Chyba v historii karet: " + String(h1));
	}
	const d1 = new Date(t1s);
	const d2 = new Date(t2s);
	const tdiff_ms = d2 - d1;
	const tdiff = tdiff_ms / 1000;
	const min_diff = -3600;
	if (tdiff < min_diff) {
		console.log("Chybné pořadí záznamů v historii karet: " + JSON.stringify(h1) + " > " + JSON.stringify(h2));
		//throw new Error("Chybné pořadí záznamů v historii karet: " + JSON.stringify(h1) + " > " + JSON.stringify(h2));
	}
	const max_diff = 12 * 3600;
	return tdiff < max_diff;
}

function compactify_cards_history(history) {
	// console.log("Compactify");
	// console.log(history.length);
	if (history.length < 2) {
		return history;
	}
	const first_record = history[0];
	const rest_records = history.slice(1);
	const result = rest_records.reduce( //procházím páry za sebou a něco generuju
		function (acc, rec) { //rec = aktuální záznam, který zpracovávámm z původní obsáhlé historie, v akumulátoru vzniká kompaktifikovaná historie - bud se přidávají záznamy, nebo je mění platnost
			const current_head_record = acc[0]; //vytáhnu z acc, co je tam jako poslední, pak když jsou datově stejný a navazujou na sebe tak prodloužím platnost toho, co tam byl a vrazím ho zpět
			if (card_history_record_equal(current_head_record, rec) &&
				card_history_record_adjoined(current_head_record, rec)) {
				// Merge
				const acc_rest = acc.slice(1);
				const new_head = {
					...current_head_record,
					pci_invalidated: rec.pci_invalidated //tímto přepisuju tento klíč, který byl původně v tom ...current_head_record
				};
				return [new_head].concat(acc_rest);
			} else {  //nebo ten záznam přidám jako nový do kompaktifikované historie
				// Add
				return [rec].concat(acc);
			}
		},
		[first_record]).reverse(); //do akumulátoru přijde pole, které obsahuje first record
	// console.log("/Compactify");
	return result;
}

function CardsHistory({ cardHistory, ErrorBadge }) {
	const { t } = useTranslation();

	//console.log(cardHistory);
	const preprocessedHistory = warehouses.map(function (wh) {
		const history = getHistoryInWarehouse(cardHistory, wh[0]);
		const historyLength = history.length;
		//const reducedHistory = history.reduce((acc, k) => ({...acc, [k]:k}), []);
		const reducedHistory = compactify_cards_history(history);

		return {
			...wh,
			history: reducedHistory,
			historyLength: historyLength,
			reducedHistory: history,
		}
	})
	//console.log(preprocessedHistory);
	return (
		<ErrorWrap>
			<Card className='mb-4 mt-4 myShadow'>
				<Accordion>
					<Accordion.Item eventKey="1">
						<Accordion.Header>
							<h4 className=" ms-2 mt-1">{t('prod-card_wh_history')}</h4>
						</Accordion.Header>
						<Accordion.Body>
							<Card.Body className='p-0 m-0'>
								<HistoryTableWeb preprocessedHistory={preprocessedHistory} />
								<HistoryApp preprocessedHistory={preprocessedHistory} ErrorBadge={ErrorBadge} />
							</Card.Body>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			</Card>
		</ErrorWrap>
	)
}


function ProductWarehouses({ preprocessedWarehouses, package_weight_kg_hsh, product, userlogin, approved, configured, no_errors, reloadIt, ErrorBadge }) {
	const { t } = useTranslation();

	const [sendingStatus, setSendingStatus] = useState({});
	const [sending, setSending] = useState(false);
	const [errorMess, setErrorMess] = useState({});

	function sendTheCard(wid) {
		if (!sending) {
			setSending(true);

			const k_IDProduktu = product.k_IDProduktu;
			//console.log('sending ' + k_IDProduktu + ' to ' + wid);
			var newSendingStatus = {};
			newSendingStatus[wid] = true;
			setSendingStatus({ ...sendingStatus, ...newSendingStatus });
			his_fetch(
				userlogin,
				[
					{
						uri: '/api/products/' + k_IDProduktu + '/send-card/' + wid,
						json: true,
						ok: function (resource, result) {
							console.log('finished');
							var newSendingStatus = {};
							newSendingStatus[wid] = false;
							setSendingStatus({ ...sendingStatus, ...newSendingStatus });
							reloadIt();
						},
						error: function (resource, result) {
							console.log('error');
							var newErrorMess = {};
							newErrorMess[wid] = t('sending_fail');
							setErrorMess({ ...errorMess, ...newErrorMess });
							var newSendingStatus = {};
							newSendingStatus[wid] = false;
							setSendingStatus({ ...sendingStatus, ...newSendingStatus });
							setSending(false);
						},
						args: {
							method: 'PUT'
						}
					}
				]
			);
		}
	}

	function cancelSending(wid) {
		if (!sending) {
			setSending(true);
			const k_IDProduktu = product.k_IDProduktu;
			his_fetch(
				userlogin,
				[
					{
						uri: '/api/products/' + k_IDProduktu + '/cancel-card-send/' + wid,
						json: true,
						ok: function (resource, result) {
							//console.log("stornoooo");
							reloadIt();
						},
						error: function (resource, result) {
							console.log('error');
							reloadIt();
						},
						args: {
							method: 'GET'
						}
					}
				]
			);
		}
	}
	//console.log(preprocessedWarehouses);
	return (
		<ErrorWrap>
			<Card className='mb-4 mt-4 myShadow'>
				<Card.Body className='p-0 m-0'>
					<h4 className=" ms-4 mt-3 mb-3">{t('prod-wh_cards')}</h4>
					<ProductCompareWeb product={product} ErrorBadge={ErrorBadge} package_weight_kg_hsh={package_weight_kg_hsh}
						preprocessedWarehouses={preprocessedWarehouses} sendingStatus={sendingStatus}
						approved={approved} configured={configured} no_errors={no_errors}
						sendTheCard={sendTheCard} cancelSending={cancelSending} errorMess={errorMess} />
					<ProductCompareApp product={product} ErrorBadge={ErrorBadge} package_weight_kg_hsh={package_weight_kg_hsh}
						preprocessedWarehouses={preprocessedWarehouses} sendingStatus={sendingStatus}
						approved={approved} configured={configured} no_errors={no_errors}
						sendTheCard={sendTheCard} cancelSending={cancelSending} errorMess={errorMess} />
				</Card.Body>
			</Card>
		</ErrorWrap>
	);
}





