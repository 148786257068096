/**
 * functions used for creating statistics graph and table
 * @module lib/statistics-utils
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import { date_formatISO } from './date-utils';

export function getDaysOfMonth(startDate) {
	var d = new Date(startDate);
	var result = [];
	const startMonth = d.getMonth();                  // počáteční měsíc je aktuální měsíc
	d.setDate(1);									//začítám od prvního v tomto měsíci
	while (d.getMonth() === startMonth) {			// iteruji, dokud se nezmění měsíc
		const startDay = date_formatISO(d); 			// 1. v měsíci si dám do formátu ISO date
		const num = d.getDate();					// číslo dne v měsíci
		const wday = d.getDay(); 							// číslo dne v týdnu
		const weekend = (wday === 0) || (wday === 6)		//vrať víkendy jako true
		d.setDate(d.getDate() + 1);							//d je zítra
		const endDay = date_formatISO(d);   					// formátuj endDay(zítra)
		result.push([num, startDay, endDay, weekend]); 		// do pole dej den v měsíci, dneska, zítra, weekend (true/false)
	}
	return result;
}

/* Finds start of the ISO first week of given year (determined by date object).
 */
export function getFirstWeekStart(startDate) {
	var d = new Date(startDate);

	// Start Jan 1
	d.setDate(1);
	d.setMonth(0);

	// Find first Thursday
	while (d.getDay() !== 4) {
		d.setDate(d.getDate() + 1);
	}

	// Go back to Monday
	while (d.getDay() !== 1) {
		d.setDate(d.getDate() - 1);
	}

	// That's it
	return d;
}

export function getWeeksOfYear(startDate) {
	const year = startDate.getFullYear();
	var d = getFirstWeekStart(startDate);
	var mondays = [];
	var weekIdx = 1;
	while (d.getFullYear() <= year) {
		const startMonday = date_formatISO(d);
		d.setDate(d.getDate() + 7);
		const endMonday = date_formatISO(d);
		mondays.push([weekIdx, startMonday, endMonday, false]);
		weekIdx += 1;
	}
	return mondays;
}

// Not just weeks, weekdef contains:
// [ number, start date, day just after end date ]
export function getUserWeekStat(stats, username, weekdef, key) {
	return stats
		.filter((stat) =>
			((username === 'ALL') ||
				(stat[key] === username)) &&
			(stat.cday >= weekdef[1]) &&
			(stat.cday < weekdef[2]))
		.reduce((acc, stat) => acc + stat.count, 0);
}

export function getMonthsOfYear(startDate) {
	var d = new Date(startDate);
	return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(function (mi) {
		d.setMonth(mi - 1);
		d.setDate(1);
		const start = date_formatISO(d);
		d.setMonth(mi);
		const end = date_formatISO(d);
		return [mi, start, end, false];
	});
}

export function get_users(stats) {
	if (stats) {
		return stats.map((stat) => stat.username).filter((v, i, a) => a.indexOf(v) === i);
	} else {
		return [];
	}
}

export function get_warehouses(stats) {
	//console.log(stats);
	//console.log(stats.map((stat) => stat.warehouse_id).filter((v, i, a) => a.indexOf(v) === i));
	if (stats) {
		return stats.map((stat) => stat.warehouse_id).filter((v, i, a) => a.indexOf(v) === i);
	} else {
		return [];
	}
}