/**
 * Product detail displaying components web version or inherently responsive one.
 * @module products/product-detail-web
 * @author Lucie Zdenkova <lucie.zdenek@trustica.cz>
 */
import React from 'react';
import { Downloader } from '../comp/downloader';
import Card from 'react-bootstrap/Card';
import { Badge } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Boolean } from '../comp/boolean';
import { ShowADR } from '../comp/adr';
import { partnerLogoShort, wid2partner, whColor, whID2whNumber } from '../lists/warehouses-defs';
import { DensityAmountUnitWeightView, ProductContainerView, ProductADRView } from './product-view';
import { make_product_check_tag } from './product-checks';
import { ErrorWrap } from '../comp/errorwrap';
import { MandatoryAddressPart } from '../partners/partner-detail';
import { LinkContainer } from 'react-router-bootstrap';
import { mandatory_format } from './product-utils';
import { date_formatCZ } from '../lib/date-utils';
import { useTranslation } from 'react-i18next';
var Fraction = require('fraction.js');

//seen all - OK

export function HistoryTableWeb({ preprocessedHistory }) {
	const { t } = useTranslation();
	return (
		<Table bordered size="sm" className='mb-0 d-none d-lg-table'>
			<thead className='beGray'>
				<tr>
					<th className='ps-4'>{t('warehouse')}</th>
					<th className='text-end'>{t('prod-created')}</th>
					<th className='text-end'>{t('prod-invalidated')}</th>
					<th className='text-center'>{t('name')}</th>
					<th className='text-center'>{t('ord-pack')}</th>
					<th className='text-center'>{t('prod-amount_MJ')}</th>
					<th className='text-center'>{t('ord-density')}</th>
					<th className='text-center'>{t('ord-1_pack_weight')}</th>
					<th className='text-center'>{t('prod-class')}</th>
					<th className='text-center'>{t('prod-pack-group')}</th>
					<th className='text-center'>{t('ord-un_num')}</th>
					<th>{t('prod-safe_list')}</th>
					<th>{t('ord-batch')}</th>
					<th>{t('prod-bbd')}</th>
				</tr>
			</thead>
			<tbody>
				{preprocessedHistory.map(function (wh) {
					return (
						wh.history.map(function (h, idx) {
							//console.log(wh.history.length);
							const package_weight_kg_wh_count = Fraction(h.pcr_MnozstviSklMjVObalu).mul(Fraction(h.pcr_HmotnostMjVKg));
							return (
								<tr key={idx}>
									{idx === 0 ?
										<td rowSpan={wh.history.length} className="align-middle text-center">{wh[0]}</td> : <></>}
									<td className='text-end'>{date_formatCZ(h.pci_created)}</td>
									<td className='text-end'>{date_formatCZ(h.pci_invalidated)}</td>
									<td className='text-center'>{h.pcr_NazevProduktu}</td>
									<td className='text-center'>{h.pcr_k_HSHObal5}</td>
									<td className='text-center'>{h.pcr_MnozstviSklMjVObalu} {h.pcr_KodMjSkl}</td>
									<td className='text-center'>{h.pcr_HmotnostMjVKg} {t('prod-kg')}</td>
									<td className='text-center'>{String(package_weight_kg_wh_count)} {t('prod-kg')}</td>

									<td className='text-center'>{h.pcr_TridaADR}</td>
									<td className='text-center'>{h.pcr_k_ADRObalovaSkupina}</td>
									<td className='text-center'>{h.pcr_k_ADRUNCislo}</td>
									<td className='text-center'>{h.pcr_safety_sheet === null ? "?" : <Boolean value={h.pcr_safety_sheet} variant="true_false" />}</td>
									<td>{mandatory_format(h.pcr_gw_check_batch)}</td>
									<td>{mandatory_format(h.pcr_gw_check_bbd)}</td>
								</tr>
							)
						}));
				})}
			</tbody>
		</Table>
	);
}

export function ProductCompareWeb({ product, ErrorBadge, package_weight_kg_hsh, preprocessedWarehouses, sendingStatus,
	approved, configured, no_errors, sendTheCard, cancelSending, errorMess }) {
	return (
		<Table striped size="sm" bordered className="bg-white m-0 d-none d-lg-table">
			<ProductTableHeaderWeb product={product} />
			<tbody>
				<ProductLineHSHWeb product={product} ErrorBadge={ErrorBadge} package_weight_kg_hsh={package_weight_kg_hsh} />
				{preprocessedWarehouses.map(function (wh, idx) {
					const disableSend = ((wh.states_1_2_3 && !wh.readback_confirmed) ||
						(wh.ADRBelongsToADRWarehouse && !wh.compliance_can_send_ADR_anywhere) ||
						!!sendingStatus[wh[0]]) ||
						(!approved) || (!configured) || (!no_errors);
					return (
						<ProductLineWarehouseWeb key={idx} wh={wh} ErrorBadge={ErrorBadge}
							product={product} sendingStatus={sendingStatus} sendTheCard={sendTheCard}
							disableSend={disableSend} cancelSending={cancelSending} errorMess={errorMess} />
					);
				})
				}
			</tbody>
		</Table>
	);
}


function ProductLineWarehouseWeb({ wh, ErrorBadge, product, sendingStatus, sendTheCard, disableSend, cancelSending, errorMess }) {
	const { t } = useTranslation();

	return (
		<tr key={wh[0]}>
			{wh[1] > 0 ? <td rowSpan={wh[1]} className="align-middle ps-4">{partnerLogoShort[wid2partner[wh[0]]]}</td> : <></>}
			<td className="text-center align-middle">
				{whID2whNumber[wh[0]]}
			</td>
			{wh.isProductInWarehouse ?
				<>
					<td className="align-middle"><span className={wh.superClass5}>{wh.nazevPrefix}</span>{wh.nazevSuffix}</td>
					<ProductContainerView id={'wh-' + wh[0]}
						wid={wh[0]}
						container={wh.piw.pcr_k_HSHObal5}
						remap
						ErrorBadge={ErrorBadge} />
					<DensityAmountUnitWeightView
						id={"wh-" + wh[0]} density={wh.piw.pcr_HmotnostMjVKg}
						densityUnit="kg" amount={wh.piw.pcr_MnozstviSklMjVObalu}
						unit={wh.piw.pcr_KodMjSkl} packageWeight={wh.package_weight_kg_wh}
						ErrorBadge={ErrorBadge}
					/>
					<ProductADRView id={'wh-' + wh[0]}
						TridaADR={wh.piw.pcr_TridaADR}
						k_ADRObalovaSkupina={wh.piw.pcr_k_ADRObalovaSkupina}
						k_ADRUNCislo={wh.piw.pcr_k_ADRUNCislo}
						romanized
						ErrorBadge={ErrorBadge} />
				</>
				:
				<td colSpan="8" className="text-center align-middle">&mdash;</td>
			}
			{wh[1] > 0 ?
				<td rowSpan={wh[1]} className="text-center align-middle">
					<Downloader img="/application-excel.svg" alt="xlsx" height="25"
						label={wid2partner[wh[0]].toLowerCase()}
						path={"/api/products/" + product.KodProduktu + "/card/" + wid2partner[wh[0]]}
						filename={"karta-" + product.k_IDProduktu + "-" + wid2partner[wh[0]].toLowerCase() + ".xlsx"} />
				</td>
				: <></>}
			<td className="text-center align-middle">{wh.warehouse_status_str}</td>
			<td className="text-center align-middle">{wh.sent_status_str}</td>
			<td className={"text-center align-middle" + wh.received_class}>{wh.received_status_str}</td>
			<td className={wh.readback_class}>{wh.readback_datetime_str}</td>
			<td className="text-center align-middle">{wh.stavKontroly}</td>
			<td className="text-start align-middle">
				{wh.states_1_2_3 && !wh.readback_confirmed ? "" :
					<>
						{sendingStatus[wh[0]] ?
							<Button style={{ backgroundColor: whColor[wid2partner[wh[0]]], border: "none" }} disabled>{wh.isProductInWarehouse ? t('updating') : t('sending')}</Button>
							:
							<>
								<Button title={wh.title} style={{ backgroundColor: whColor[wid2partner[wh[0]]], border: "none" }} onClick={() => sendTheCard(wh[0])} disabled={disableSend}>
									{wh.isProductInWarehouse ? t('update') + " " + whID2whNumber[wh[0]] : t('prod-sent_to') + " " + whID2whNumber[wh[0]]}
									{wh[0] === 22 || wh[0] === 30 ? "" : <ErrorBadge tag={make_product_check_tag("hsh", "isADR")} />}
								</Button>
							</>
						}
					</>
				}
				{wh.show_timeDiff}
				{wh.showCancel ? <Button onClick={() => cancelSending(wh[0])} className="mt-1" size="sm" variant="danger">{t('storno')}</Button> : ""}
				<br /><small className="text-danger">{errorMess[wh[0]]}</small>
			</td>
		</tr>
	);
}

function ProductTableHeaderWeb({ product }) {
	const { t } = useTranslation();
	return (
		<thead className="beGray">
			<tr>
				<th colSpan={10} className='text-center'>{t('prod-prod_card')} <small>({t('prod-hsh_wh_comparison')})</small></th>
				<th rowSpan="3">{t('prod-xlsx_card')}</th>
				<th rowSpan="3">{t('prod-communication_status')}</th>
				<th rowSpan="3">{t('prod-sent(f)')}</th>
				<th rowSpan="3">{t('prod-accepted')}</th>
				<th rowSpan="3">{t('prod-readback')}</th>
				<th rowSpan="3">{t('prod-check_state')}</th>
				<th rowSpan="3">{t('action')}</th>
			</tr>
			<tr>
				<th rowSpan="2" className='ps-4'>{t('partner')}</th>
				<th rowSpan="2">{t('warehouse')}</th>
				<th rowSpan="2">{t('name')}</th>
				<th colSpan="4" className='text-center'>{t('prod-package')}</th>
				<th colSpan="3" className="text-center">
					{t('prod-ADR')} – <Boolean value={product.k_ADR} />
					<ShowADR isADR={product.k_ADR} height={25} />
				</th>
			</tr>
			<tr>
				<th className='text-center'>{t('ord-pack')}</th>
				<th className='text-center'>{t('ord-content_w_unit')}</th>
				<th className='text-center'>{t('ord-density')}</th>
				<th className='text-center'>{t('ord-1_pack_weight')}</th>
				<th className='text-center'>{t('prod-class')}</th>
				<th className='text-center'>{t('prod-pack-group')}</th>
				<th className='text-center'>{t('ord-un_num')}</th>
			</tr>
		</thead>
	);
}

function ProductLineHSHWeb({ product, ErrorBadge, package_weight_kg_hsh }) {
	return (
		<tr>
			<td className="text-center align-middle" colSpan="2"><Image src="/hsh.png" style={{ width: "50px", padding: "2px" }} /></td>
			<td className="align-middle">{product.NazevProduktu}</td>
			<ProductContainerView id="hsh"
				container={product.k_HSHObal5}
				ErrorBadge={ErrorBadge} />
			<DensityAmountUnitWeightView
				id={"hsh"}
				density={product.HmotnostMj}
				densityUnit={product.MjHmotnostiMj}
				amount={product.MnozstviSklMjVObalu}
				unit={product.KodMjSkl}
				packageWeight={package_weight_kg_hsh}
				ErrorBadge={ErrorBadge}
			/>
			<ProductADRView id="hsh"
				TridaADR={product.TridaADR}
				k_ADRObalovaSkupina={product.k_ADRObalovaSkupina}
				k_ADRUNCislo={product.k_ADRUNCislo}
				ErrorBadge={ErrorBadge}
			/>
			<td colSpan="7" className="text-center align-middle">&mdash;</td>
		</tr>
	);
}

export function IsActive({ active }) {
	const { t } = useTranslation();
	const variant = active ? "success" : "danger";
	const text = active ? t('active') : t('inactive');
	return (
		<ErrorWrap>
			<Badge className='mx-2' pill bg={variant}>{text}</Badge>
		</ErrorWrap>
	);
}

export function Packagings({ packagings, ErrorBadge }) {
	const { t } = useTranslation();
	return (
		<ErrorWrap>
			<Card className='myShadow'>
				<Card.Body className='p-0'>
					<h5 className='mb-3 mt-3 ms-4'>{t('prod-packages')}</h5>
					<Table size="sm" className='mb-4'>
						<thead className='beGray'>
							<tr>
								<th className='ps-4'>{t('prod-basic_pack')}
									<ErrorBadge tag={make_product_check_tag("hsh", "packagings")} />
								</th>
								<th>{t('prod-pack_code')}</th>
								<th>{t('prod-pack_type')}</th>
								<th className='text-end pe-3'>{t('ord-amount_measure_unit')}</th>
							</tr>
						</thead>
						<tbody>
							{packagings.map(function (pa, idx) {
								return (
									<tr key={idx}>
										<td className='ps-4'><Boolean value={pa.pjo_Zakladni} variant="onlyTrue" /></td>
										<td>{pa.pjo_KodObalu}</td>
										<td>{pa.pjo_DruhObalu}</td>
										<td className='text-end pe-3'>{pa.pjo_MnozstviSklMjVObalu}&nbsp;{pa.pjo_KodMj} </td>
									</tr>);
							})}
						</tbody>
					</Table>
				</Card.Body>
			</Card>
		</ErrorWrap>
	);
}


export function AltNames({ names }) {
	const { t } = useTranslation();
	return (
		<ErrorWrap>
			<Card className='myShadow'>
				<Card.Body className='p-0'>
					<h5 className='mb-3 mt-3 ms-4'>{t('prod-alt_names')}</h5>
					{names.length === 0 ? <p className='text-muted m-4'>{t('prod-not_registered')}</p> :
						<Table size="sm" className='mb-4'>
							<thead className='beGray'>
								<tr>
									<th className='ps-4'>{t('biz-partner_code')}</th>
									<th>{t('biz-partner_name')}</th>
									<th>{t('prod-is_supplier')}?</th>
									<th>{t('prod-code')}</th>
									<th>{t('prod-name')}</th>
								</tr>
							</thead>
							<tbody>
								{names.map(function (an, idx) {
									return (
										<tr key={idx}>
											<td className='ps-4'>{an.pp_KodPartnera}</td>
											<td>{an.p_NazevPartnera}</td>
											<td><Boolean value={an.pp_dodavatel} variant="onlyTrue" /></td>
											<td>{an.pp_ExtKodProduktu}</td>
											<td>{an.pp_NazevProduktu}</td>
										</tr>);
								})}
							</tbody>
						</Table>
					}
				</Card.Body>
			</Card>
		</ErrorWrap>
	);
}

export function BasicInfo({ product }) {
	const { t } = useTranslation();
	return (
		<ErrorWrap>
			<Card body className='myShadow'>
				<Table borderless size="sm" className="p-0 m-0">
					<tbody>
						<tr>
							<td className="text-end text-muted p-0">{t('code')} &nbsp;</td>
							<td className="p-0">{product.KodProduktu}</td>
						</tr>
						<tr>
							<td className="text-end text-muted p-0">{product.cppcnt === 1 ? t('prod-parameter') : t('prod-parameters')} &nbsp;</td>
							<td className="p-0">({product.cppcnt}) {product.cpp}</td>
						</tr>
					</tbody>
				</Table>
			</Card>
		</ErrorWrap>
	);
}

export function Supplier({ suppliers, product, ErrorBadge }) {
	const { t } = useTranslation();
	return (
		<ErrorWrap>
			<Card body className="myShadow">
				<h5>{t('prod-supplier')}</h5>
				<Table size="sm" borderless className="p-0 m-0">
					<tbody>
						<tr>
							<td className="text-end text-muted p-0">{t('prod-supplier_code')}  &nbsp;</td>
							<td className="p-0">
								{product.k_KodPartneraDodavatel}
								<ErrorBadge tag={make_product_check_tag("hsh", "addresses")} />
							</td>
						</tr>
						<tr>
							<td className="text-end text-muted p-0">{t('biz-partner_code')}  &nbsp;</td>
							<td className="p-0">
								{suppliers.length > 0 ?
									<LinkContainer to={{ pathname: "/partners/list/" + suppliers[0].KodPartnera + "/detail" }}>
										<a href="!#">{suppliers[0].KodPartnera}</a>
									</LinkContainer>
									: ""}
							</td>
						</tr>
						<tr>
							<td className="text-end text-muted p-0 pb-2 border-bottom">{t('biz-partner_name')}  &nbsp;</td>
							<td className="p-0 pb-2 border-bottom">{suppliers.length > 0 ? suppliers[0].NazevPartnera : ""}</td>
						</tr>

						{suppliers.length < 1 ?
							<tr>
								<td className="text-end text-muted p-0 pt-2 border-top">{t('biz-adrs_name')} &nbsp;</td>
								<td className="p-0 pt-2 border-top">
									<ErrorBadge tag={make_product_check_tag("hsh", "addresses")} />
									<ErrorBadge tag={make_product_check_tag("hsh", "address")} />
								</td>
							</tr>
							:
							suppliers.map(function (su, idx) {
								return (
									<React.Fragment key={idx}>
										<tr>
											<td colSpan={2} className='text-center'>{t('biz-adrs')} 🗺️ <small className='text-muted'>(*{t('biz-mandatory')})</small></td>
										</tr>
										<MandatoryAddressPart nazev={su.NazevAdresy} adresa1={su.Adresa} adresa2={su.Adresa2}
											misto={su.Misto} psc={su.PSC} kodStatu={su.KodStatu} withAsterisks ErrorBadge={ErrorBadge} kodAdresy={""} />
										<tr>
											<td className="text-end text-muted p-0">{t('biz-adrs_type')} &nbsp;</td>
											<td className="p-0">{su.typadresy}</td>
										</tr>
									</React.Fragment>
								);
							})}

					</tbody>
				</Table >
			</Card>
		</ErrorWrap>
	);
}
